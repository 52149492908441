import React from "react"
import PropTypes from "prop-types"
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table,
} from "reactstrap"
import img7 from "../../../assets/images/product/img-7.png"
import img4 from "../../../assets/images/product/img-4.png"

const EcommerceDealerListModal = props => {
    const { isOpen, toggle, adminId } = props

    let adminIdLen = adminId.length
    let assignedManager, assignedSales, assignedDealer
    if (adminIdLen > 0) {
        let assignedManagerLen = adminId[0].assignedManager.length
        let assignedSalesLen = adminId[0].assignedSales.length
        let assignedDealerLen = adminId[0].assignedDealer.length


        if (assignedManagerLen > 0) {
            let managerNames = adminId[0].assignedManager.map(function (item) {
                return " " + item['managerName'];
            });
            assignedManager = managerNames.toString()
        }
        if (assignedSalesLen > 0) {
            let salesNames = adminId[0].assignedSales.map(function (item) {
                return " " + item['salesName'];
            });
            assignedSales = salesNames.toString()
        }
        if (assignedDealerLen > 0) {
            let dealerNames = adminId[0].assignedDealer.map(function (item) {
                return " " + item['dealerName'];
            });
            assignedDealer = dealerNames.toString()
        }

    }
    return (
        <React.Fragment>
            {
                adminId ? (
                    <Modal
                        isOpen={isOpen}
                        role="dialog"
                        autoFocus={true}
                        centered={true}
                        className="exampleModal"
                        tabIndex="-1"
                        toggle={toggle}
                    >
                        <div className="modal-content">
                            <ModalHeader toggle={toggle}>Admin Details</ModalHeader>
                            <ModalBody>
                                <p className="mb-2">
                                    User Id: <span className="text-primary">{adminId[0].id}</span>
                                </p>
                                <p className="mb-2">
                                    Full Name: <span className="text-primary">{adminId[0].name}</span>
                                </p>
                                <p className="mb-2">
                                    Mobile: <span className="text-primary">{adminId[0].mobile}</span>
                                </p>
                                <p className="mb-2">
                                    Email: <span className="text-primary">{adminId[0].email}</span>
                                </p>
                                <p className="mb-2">
                                    Role: <span className="text-primary">{adminId[0].role}</span>
                                </p>
                                <p className="mb-2">
                                    State: <span className="text-primary">{adminId[0].state}</span>
                                </p>
                                {/* {
                                    assignedManager ? (<p className="mb-2">
                                        Assigned Manager: <span className="text-primary">{assignedManager}</span>
                                    </p>) : ""
                                } */}
                                {
                                    assignedSales ? (<p className="mb-2">
                                        Assigned Sales: <span className="text-primary">{assignedSales}</span>
                                    </p>) : ""
                                }
                                {assignedDealer ? (<p className="mb-2">
                                    Assigned Dealer: <span className="text-primary">{assignedDealer}</span>
                                </p>) : ""}



                            </ModalBody>
                            <ModalFooter>
                                <Button type="button" color="secondary" onClick={toggle}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </div>
                    </Modal >) : (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )

            }
        </React.Fragment>
    )
}

EcommerceDealerListModal.propTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
    adminId: PropTypes.string,
}

export default EcommerceDealerListModal
