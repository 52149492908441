import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import img7 from "../../../assets/images/product/img-7.png"
import img4 from "../../../assets/images/product/img-4.png"
import moment from "moment"

const EcommerceOrdersModal = props => {
  const { isOpen, toggle, orderId } = props

  return (
    <React.Fragment>
      {
        orderId ? (
          <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={toggle}
          >
            <div className="modal-content">
              <ModalHeader toggle={toggle}>Order Details</ModalHeader>
              <ModalBody>
                <p className="mb-2">
                  Order Id: <span className="text-primary">{orderId[0].orderId}</span>
                </p>
                <p className="mb-2">
                  Status: <span className="text-primary">{orderId[0].status}</span>
                </p>
                <p className="mb-2">
                  User Name: <span className="text-primary">{orderId[0].userName}</span>
                </p>
                <p className="mb-2">
                  Email: <span className="text-primary">{orderId[0].email}</span>
                </p>
                <p className="mb-2">
                  Mobile Number: <span className="text-primary">{orderId[0].mobile}</span>
                </p>
                <p className="mb-2">
                  Brand Name: <span className="text-primary">{orderId[0].brandName}</span>
                </p>
                <p className="mb-2">
                  Model Name: <span className="text-primary">{orderId[0].modelName}</span>
                </p>
                <p className="mb-2">
                  Paid Price: <span className="text-primary">{orderId[0].price}</span>
                </p>
                {orderId[0].promocodeApplied !== null ?
                  <>
                    <p className="mb-2">
                      Plan Price: <span className="text-primary">{orderId[0].planPrice}</span>
                    </p>
                    <p className="mb-2">
                      Discount: <span className="text-primary">{orderId[0].discount}</span>
                    </p>
                  </> : ""}
                <p className="mb-2">
                  Dealer Name: <span className="text-primary">{orderId[0].dealerName}</span>
                </p>
                <p className="mb-2">
                  Dealer Code: <span className="text-primary">{orderId[0].dealerCode}</span>
                </p>
                <p className="mb-2">
                  Voucher Code: <span className="text-primary">{orderId[0].voucherCode}</span>
                </p>

                <p className="mb-2">
                  Date: <span className="text-primary">{moment(new Date(orderId[0].year)).format('YYYY-MM-DD')}</span>
                </p>
                <p className="mb-2">
                  NRIC: <span className="text-primary">{orderId[0].nric}</span>
                </p>
                <p className="mb-2">
                  State: <span className="text-primary">{orderId[0].state}</span>
                </p>
                <p className="mb-2">
                  Mileage: <span className="text-primary">{orderId[0].mileage}</span>
                </p>

                <p className="mb-2">
                  Order Date: <span className="text-primary">{orderId[0].orderDate}</span>
                </p>
                <p className="mb-2">
                  Payment Gateway: <span className="text-primary">{orderId[0].provider}</span>
                </p>
                {orderId[0].promocodeApplied !== null ? <p className="mb-2">
                  Promocode Applied: <span className="text-primary">{orderId[0].promocodeApplied}</span>
                </p> : ""}

                {(orderId[0].provider == "iPay88" || orderId[0].provider == "revPAY") ?
                  <>
                    <p className="mb-2">
                      Transaction Date: <span className="text-primary">{orderId[0].transactionDate}</span>
                    </p>
                    <p className="mb-2">
                      Transaction Id: <span className="text-primary">{orderId[0].transactionId}</span>
                    </p>
                    <p className="mb-2">
                      Credit Card Name: <span className="text-primary">{orderId[0].creditCardName}</span>
                    </p>
                    <p className="mb-2">
                      Credit Card Number: <span className="text-primary">{orderId[0].creditCardNumber}</span>
                    </p>
                    <p className="mb-2">
                      Currency: <span className="text-primary">{orderId[0].currency}</span>
                    </p>
                    <p className="mb-2">
                      Bank Name: <span className="text-primary">{orderId[0].bankName}</span>
                    </p>
                    <p className="mb-2">
                      Bank Country: <span className="text-primary">{orderId[0].bankCountry}</span>
                    </p>
                    <p className="mb-2">
                      Error Description: <span className="text-primary">{orderId[0].errDesc}</span>
                    </p>
                  </>
                  : ""}



              </ModalBody>
              <ModalFooter>
                <Button type="button" color="secondary" onClick={toggle}>
                  Close
                </Button>
              </ModalFooter>
            </div>
          </Modal >) : (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )

      }
    </React.Fragment>
  )
}

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  orderId: PropTypes.string,
}

export default EcommerceOrdersModal
