import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as moment from "moment"
import * as Yup from "yup"
import MetaTags from "react-meta-tags"
import { CardTitle, CardSubtitle, UncontrolledTooltip } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { withRouter, Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form,
  FormGroup
} from "reactstrap"
import { InputGroup } from "react-bootstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

import { useSelector, useDispatch } from "react-redux"

import { GETCREDITHISTORYBYDATES, GETCREDITHISTORY } from "../../../mutations/useMutations"

import { useQuery, useMutation } from "@apollo/react-hooks"
import DeleteModal from "../../../components/Common/DeleteModal"
import { useForm } from "../../../utils/hooks";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker"

const TransactionHistory = props => {
  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 7);
  let defaultStartDateRes = defaultStartDate

  const [modal, setModal] = useState(false);
  const [creditHistory, setCreditHistory] = React.useState([]);
  const [modal1, setModal1] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const [startDate, setStartDate] = useState(defaultStartDateRes)
  const [endDate, setEndDate] = useState(new Date())

  const [userList, setUserList] = useState([]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (userToEdit && userToEdit?.name) || '',
      email: (userToEdit && userToEdit?.email) || '',
      mobile: (userToEdit && userToEdit?.mobile) || '',
      nric: (userToEdit && userToEdit?.nric) || '',
      state: (userToEdit && userToEdit?.state) || '',
      status: (userToEdit && userToEdit?.status) || 'active',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter User Name"),
      email: Yup.string().required("Please Enter User Email"),
      mobile: Yup.string().required("Please Enter User Mobile Number"),
      status: Yup.string().required("Please Enter User Status"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          id: userToEdit?.id,
          name: values?.name,
          email: values?.email,
          mobile: values?.mobile,
          nric: values?.nric,
          state: values?.state,
          status: values?.status,
        };
        // update order
        // dispatch(onUpdateOrder(updateOrder));
        handleupdateUser(updateOrder);
        validation.resetForm();
      }
      else {
        const addUser = {
          name: values["name"],
          email: values["email"],
          mobile: values["mobile"],
          nric: values["nric"],
          state: values["state"],
          status: values["status"],
        };
        // save new order
        handleAddUser(addUser)
        validation.resetForm();
      }
      toggle();
    },
  });

  const [GetCreditHistoryByDates] = useMutation(GETCREDITHISTORYBYDATES, {
    update(_, { data: { getCreditHistoryByDates: getCreditHistoryByDatesData } }) {

      if (getCreditHistoryByDatesData) {

        const creditHistoryRes = getCreditHistoryByDatesData?.creditHistoryResults

        console.log("creditHistoryRes", creditHistoryRes)
        setTimeout(() => {
          setCreditHistory(creditHistoryRes)
        }, 1000);

      }

    },
    onError(err) {
      console.log(err.message);
    },
  });

  const [GetCreditHistory] = useMutation(GETCREDITHISTORY, {
    update(_, { data: { getCreditHistory: getCreditHistoryData } }) {

      if (getCreditHistoryData) {

        const creditHistoryRes = getCreditHistoryData?.creditHistoryResults

        setTimeout(() => {
          setCreditHistory(creditHistoryRes)
        }, 1000);

      }

    },
    onError(err) {
      console.log(err.message);
    },
  });


  const handleChangeStartDate = date => {
    console.log("date", date.toISOString())
    setStartDate(date)
  }

  const handleChangeEndDate = date => {
    setEndDate(date)
  }

  function handleSubmitCreditHistory() {

    GetCreditHistoryByDates({
      variables:
      {
        fromDate: startDate,
        toDate: endDate,
      }
    }
    )

  }

  useEffect(() => {

    GetCreditHistory()
  }, [])


  // useEffect(() => {
  //   if (data) {
  //     const creditHistoryRes = data?.getCreditHistory?.creditHistoryResults
  //     setCreditHistory(creditHistoryRes)
  //   }
  // })

  // const { loading, data } = useQuery(GETCREDITHISTORY)
  // if (loading) {
  //   return "loading..."
  // }

  const creditHistories = creditHistory

  const selectRow = {
    mode: "checkbox",
  };

  const pageOptions = {
    sizePerPage: 10,
    totalSize: creditHistories.length, // replace later with size(orders),
    custom: true,
  };
  const { SearchBar } = Search;

  const toggleViewModal = () => setModal1(!modal1)

  const sendUserId = (userId) => {
    let userToView = creditHistories?.filter(item => {
      if (item.id === userId) {
        return item
      }
    })
    setUserId(userToView)
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      // setUser(null);
    } else {
      setModal(true);
    }
  };

  const EcommerceUserColumns = [{
    dataField: 'id',
    text: 'User Id',
    sort: true,
  }, {
    dataField: 'name',
    text: 'User Name',
    sort: true
  }, {
    dataField: 'date',
    text: 'Date & Time',
    sort: true
  }, {
    dataField: 'transactionId',
    text: 'Transection ID',
    sort: true
  },
  {
    dataField: 'type',
    text: 'Type',
    sort: true
  },
  {
    dataField: 'amount',
    text: 'Amount',
    sort: true,
    formatter: (cellContent, row) => (
      row.type == "Credit" ?
        (<p style={{ color: "green" ,fontWeight:"bold"}}>+RM{row.amount}</p>) :
        (<p style={{ color: "red" ,fontWeight:"bold"}}>-RM{row.amount}</p>)


    ),
  },

  ];


  //add User
  const handleCustomerClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];
  return (
    <React.Fragment>
      <ToastContainer />
      {
        // loading ?
        //   <div className="d-flex justify-content-center">
        //     <div className="spinner-border text-primary" role="status">
        //       <span className="sr-only">Loading...</span>
        //     </div>
        //   </div> :
        <div className="page-content">

          <Container fluid>
            {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
            <h2>Transaction History</h2>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={EcommerceUserColumns}
                      data={creditHistories}
                    >
                      {

                        ({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={creditHistories}
                            columns={EcommerceUserColumns}
                            bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col sm="3">
                                    <div >
                                      <FormGroup className="mt-0 mb-0">
                                        <Label >From :</Label>
                                        <DatePicker
                                          selected={startDate}
                                          onChange={handleChangeStartDate}
                                          className="form-control"
                                          placeholderText="Select date"
                                        />
                                      </FormGroup>
                                    </div>
                                  </Col>
                                  <Col sm="3">
                                    <div >
                                      <FormGroup className="mt-0 mb-0">
                                        <Label >To :</Label>
                                        <DatePicker
                                          selected={endDate}
                                          onChange={handleChangeEndDate}
                                          className="form-control"
                                          placeholderText="Select date"
                                        />
                                      </FormGroup>
                                    </div>
                                  </Col>
                                  <Col sm="2"> <Button
                                    type="button"
                                    color="primary"
                                    className="w-md"
                                    style={{ width: "100%", marginTop: "30px" }}
                                    onClick={handleSubmitCreditHistory}

                                  >
                                    Get Data
                                  </Button></Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap table-check"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      // ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      }
    </React.Fragment>
  )
}

TransactionHistory.propTypes = {}

export default TransactionHistory