import React from "react"
import PropTypes from "prop-types"
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table,
} from "reactstrap"
import img7 from "../../../assets/images/product/img-7.png"
import img4 from "../../../assets/images/product/img-4.png"

const EcommercePromoListModal = props => {
    const { isOpen, toggle, promoId } = props

    return (
        <React.Fragment>
            {
                promoId ? (
                    <Modal
                        isOpen={isOpen}
                        role="dialog"
                        autoFocus={true}
                        centered={true}
                        className="exampleModal"
                        tabIndex="-1"
                        toggle={toggle}
                    >
                        <div className="modal-content">
                            <ModalHeader toggle={toggle}>Promo Details</ModalHeader>
                            <ModalBody>
                                <p className="mb-2">
                                    Promo Id: <span className="text-primary">{promoId[0].id}</span>
                                </p>
                                <p className="mb-2">
                                    Name: <span className="text-primary">{promoId[0].name}</span>
                                </p>
                                <p className="mb-2">
                                    Promo Code: <span className="text-primary">{promoId[0].promoCode}</span>
                                </p>
                                <p className="mb-2">
                                    Discount: <span className="text-primary">{promoId[0].discount}</span>
                                </p>
                                <p className="mb-2">
                                    Start Date: <span className="text-primary">{promoId[0].startDate}</span>
                                </p>
                                <p className="mb-2">
                                    End Date: <span className="text-primary">{promoId[0].expirationDate}</span>
                                </p>
                                <p className="mb-2">
                                    Percentage: <span className="text-primary">{promoId[0].percentage}</span>
                                </p>
                                <p className="mb-2">
                                    Type: <span className="text-primary">{promoId[0].type}</span>
                                </p>
                                <p className="mb-2">
                                    Status: <span className="text-primary">{promoId[0].status}</span>
                                </p>
                                <p className="mb-2">
                                    Remarks: <span className="text-primary">{promoId[0].remarks}</span>
                                </p>
                                <p className="mb-2">
                                    Financial Institute: <span className="text-primary">{promoId[0].financialInstitute}</span>
                                </p>

                            </ModalBody>
                            <ModalFooter>
                                <Button type="button" color="secondary" onClick={toggle}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </div>
                    </Modal >) : (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )

            }
        </React.Fragment>
    )
}

EcommercePromoListModal.propTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
    promoId: PropTypes.string,
}

export default EcommercePromoListModal
