import { gql } from "@apollo/client";

const DELETEUSER = gql`
  mutation deleteUser(
    $userId: ID
  ) {
    deleteUser(
        userId: $userId
    ) {
        message
        error
    }
  }
`;

const DELETEINSPECTION = gql`
  mutation deleteInspection(
    $inspectionId: ID
  ) {
    deleteInspection(
        inspectionId: $inspectionId
    ) {
        message
        error
    }
  }
`;

const DELETEADMIN = gql`
  mutation deleteAdmin(
    $adminId: ID
  ) {
    deleteAdmin(
        adminId: $adminId
    ) {
        message
        error
    }
  }
`;

const DELETEWORKSHOP = gql`
  mutation deleteWorkshop(
    $workshopId: ID
  ) {
    deleteWorkshop(
        workshopId: $workshopId
    ) {
        message
        error
    }
  }
`;

const DELETEDEALER = gql`
  mutation deleteDealer(
    $dealerId: ID
  ) {
    deleteDealer(
        dealerId: $dealerId
    ) {
        message
        error
    }
  }
`;

const DELETEBOOKING = gql`
  mutation deleteBooking(
    $bookingId: ID
  ) {
    deleteBooking(
        bookingId: $bookingId
    ) {
        message
        error
    }
  }
`;

const UPDATEUSER = gql`
mutation updateUser(
  $userId: ID
  $name: String
  $email: String
  $mobile: String
  $role: String
  $status: String
  $state: String
  $nric: String
  $updatedByuserId: ID
)
{
  updateUser(
    userId: $userId
    name: $name
    email: $email
    mobile: $mobile
    role:$role
    status: $status
    state: $state
    nric: $nric
    updatedByuserId: $updatedByuserId
  ) {
    
    message
    error
  }
}
`;

const UPDATEADMIN = gql`
mutation updateAdmin(
  $adminId: ID
  $name: String
  $email: String
  $mobile: String
  $role: String
  $status: String
  $state: String
  $password: String
  $updatedByadminId: ID
  $assignedDealer: [AssignedDealer]
  $assignedManager: [AssignedManager]
  $assignedSales: [AssignedSales]
)
{
  updateAdmin(
    adminId: $adminId
    name: $name
    email: $email
    mobile: $mobile
    role:$role
    status: $status
    state: $state
    password: $password
    updatedByadminId: $updatedByadminId
    assignedDealer: $assignedDealer
    assignedManager: $assignedManager
    assignedSales: $assignedSales
  ) {
    
    message
    error
  }
}
`;

const UPDATEINSPECTION = gql`
mutation updateInspection(
  $id: ID
  $stateId: ID
  $name: String
  $address: String
  $telephone: String
  $fax: String
  $email: String
  $area: String
  $dealerType: String
  $dealcode: String
  $dealerNumber: String
  $channel: String
  $state: String
  $stateCode: String
  $mobile: String
  $personInCharge: String
  $category: String
  $workshopType: String
)
{
  updateInspection(
    updateInspection: {
    id: $id
    stateId: $stateId
    name: $name
    address: $address
    telephone: $telephone
    fax: $fax
    email: $email
    area: $area
    dealerType: $dealerType
    dealcode: $dealcode
    dealerNumber: $dealerNumber
    channel: $channel
    state: $state
    stateCode: $stateCode
    mobile: $mobile
    personInCharge: $personInCharge
    category: $category
    workshopType: $workshopType
    } 
    ) {
    message
    error
  }
}
`;

const UPDATEWORKSHOP = gql`
mutation updateWorkshop(
  $id: ID
  $stateId: ID
  $name: String
  $state: String
  $stateCode: String
  $address: String
  $telephone: String
  $mobile: String
  $fax: String
  $email: String
  $area: String
  $personInCharge: String
  $dealerType: String
  $dealcode: String
  $dealerNumber: String
  $channel: String
  $category: String
  $workshopType: String
  $distribution: String
)
{
  updateWorkshop(
    updateWorkshop: {
      id: $id
      stateId: $stateId
      name: $name
      state: $state
      stateCode: $stateCode
      address: $address
      telephone: $telephone
      mobile: $mobile
      fax: $fax
      email: $email
      area: $area
      personInCharge: $personInCharge
      dealerType: $dealerType
      dealcode: $dealcode
      dealerNumber: $dealerNumber
      channel: $channel
      category: $category
      workshopType: $workshopType
      distribution: $distribution
    } 
    ) {
    message
    error
  }
}
`;

const UPDATEDEALER = gql`
mutation updateDealer(
  $id: ID
  $name: String
  $no: String
  $mobile: String
  $email: String
  $postcode: String
  $state: String
  $ezPartner: String
  $fmcMember: String
  $dealerCode: String
  $stateCode: String
  $dealerType: String
  $dealerNumber: String
)
{
  updateDealer(
    updateDealer: {
      id: $id
      name: $name
      no: $no
      mobile: $mobile
      email: $email
      postcode: $postcode
      state: $state
      ezPartner: $ezPartner
      fmcMember: $fmcMember
      dealerCode: $dealerCode
      stateCode: $stateCode
      dealerType: $dealerType
      dealerNumber: $dealerNumber
    } 
    ) {
    message
    error
  }
}
`;
const UPDATEBOOKING = gql`
mutation updateBooking(
  $orderId: String
  $status: String
  $userName: String
  $email: String
  $mobile: String
  $brandId: ID
  $modelId: ID
  $price: String
  $dealerName: String
  $dealerCode: String
  $voucherCode: String
  $year: String
  $nric: String
  $stateId: ID
  $mileage: String
  $isVoucher: Boolean
  $userId: ID
  $promoCode: String
  $planId: String
){
  updateBooking(updateBooking: {
    orderId: $orderId
    status: $status
    userName: $userName
    email: $email
    mobile: $mobile
    brandId: $brandId
    modelId: $modelId
    price: $price
    dealerName: $dealerName
    dealerCode: $dealerCode
    voucherCode: $voucherCode
    year: $year
    nric: $nric
    stateId: $stateId
    mileage: $mileage
    isVoucher: $isVoucher
    userId: $userId
    promoCode: $promoCode
    planId: $planId
  }) {
    message
    error
  }
}

`;

const CREATEDEALER = gql`
mutation createDealer(
  $name: String
  $mobile: String
  $email: String
  $postcode: String
  $state: String
  $ezPartner: String
  $fmcMember: String
  $dealerCode: String
  $stateCode: String
  $dealerType: String
  $dealerNumber: String
)
{
  createDealer(
    createDealer: {
      name: $name
      mobile: $mobile
      email: $email
      postcode: $postcode
      state: $state
      ezPartner: $ezPartner
      fmcMember: $fmcMember
      dealerCode: $dealerCode
      stateCode: $stateCode
      dealerType: $dealerType
      dealerNumber: $dealerNumber
    } 
    ) {
    message
    error
  }
}
`;

const CREATEWORKSHOP = gql`
mutation createWorkshop(
  $stateId: ID
  $name: String
  $address: String
  $telephone: String
  $fax: String
  $email: String
  $area: String
  $dealerType: String
  $dealcode: String
  $dealerNumber: String
  $channel: String
  $state: String
  $stateCode: String
  $mobile: String
  $personInCharge: String
  $category: String
  $workshopType: String
  $distribution: String
){
  createWorkshop(inputWorkshop: {
    stateId: $stateId
    name: $name
    address: $address
    telephone: $telephone
    fax: $fax
    email: $email
    area: $area
    dealerType: $dealerType
    dealcode: $dealcode
    dealerNumber: $dealerNumber
    channel:  $channel
    state: $state
    stateCode: $stateCode
    mobile: $mobile
    personInCharge: $personInCharge
    category: $category
    workshopType: $workshopType
    distribution: $distribution
  }) {
    id
    stateId
    name
    state
    stateCode
    address
    telephone
    mobile
    fax
    email
    area
    personInCharge
    dealerType
    dealcode
    dealerNumber
    channel
    category
    workshopType
    distribution
  }
}

`;

const CREATEINSPECTION = gql`
mutation createInspection(
  $stateId: ID
  $name: String
  $address: String
  $telephone: String
  $fax: String
  $email: String
  $dealerType: String
  $dealcode: String
  $dealerNumber: String
  $channel: String
){
  createInspection(inputInspection: {
    stateId: $stateId
    name: $name
    address: $address
    telephone: $telephone
    fax: $fax
    email: $email
    dealerType: $dealerType
    dealcode: $dealcode
    dealerNumber: $dealerNumber
    channel: $channel
  }) {
    id
    stateId
    name
    address
    telephone
    mobile
    fax
    email
    area
    dealerType
    dealcode
    dealerNumber
    channel
    stateName
  }
}
`;

// const CREATEADMIN = gql`
// mutation adminCreateByAdmin(
//   $name: String
//   $mobile: String
//   $email: String
//   $password: String
//   $status: String
//   $createdBy: ID
//   $state: String
// )
// {
//   adminCreateByAdmin(
//       name: $name
//       mobile: $mobile
//       email: $email
//       password: $password
//       status: $status
//       createdBy: $createdBy
//       state: $state
//     ) {
//       adminId
//       message 
//       token
//       error
//       name
//       email
//       mobile
//       role
//   }
// }
// `;

const CREATEADMIN = gql`
mutation userCreateByAdminOnAdminDashboard(
  $name: String
  $mobile: String
  $email: String
  $password: String
  $status: String
  $createdBy: ID
  $state: String
  $nric: String
  $role: String
  $assignedDealer: [AssignedDealer]
  $assignedManager: [AssignedManager]
  $assignedSales: [AssignedSales]
)
{
  userCreateByAdminOnAdminDashboard(userInput:{
    name: $name
    mobile: $mobile
    email: $email
    password: $password
    status: $status
    createdBy: $createdBy
    state: $state
    nric: $nric
    role: $role
    assignedDealer: $assignedDealer
    assignedManager: $assignedManager
    assignedSales: $assignedSales
  }
    ) {
      userId
      message
      token
      error
      name
      email
      mobile
      role
      permission
  }
}
`;


const CREATEUSER = gql`
mutation userCreateByAdmin(
  $name: String
  $mobile: String
  $email: String
  $nric: String
  $status: String
  $createdBy: ID
  $state: String
)
{
  userCreateByAdmin(
      name: $name
      mobile: $mobile
      email: $email
      nric: $nric
      status: $status
      createdBy: $createdBy
      state: $state
    ) {
      userId
      message
      token
      error
      name
      email
      mobile
      role
  }
}
`;

const CREATBOOKINGBYADMIN = gql`
mutation createBookingByAdmin(
  $userName: String
  $nric: String
  $stateId: ID
  $voucherCode: String
  $year: String
  $email: String
  $mobile: String
  $mileage: String
  $userId: ID
  $brandId: ID
  $modelId: ID
  $dealerId: ID
  $isVoucher: Boolean
  $planId: String
  $planName: String
){
  createBookingByAdmin(bookingInput: {
    userName: $userName
    nric:  $nric
    stateId:  $stateId
    voucherCode: $voucherCode
    year: $year
    email: $email
    mobile: $mobile
    mileage: $mileage
    userId: $userId
    brandId: $brandId
    modelId: $modelId
    dealerId: $dealerId
    isVoucher: $isVoucher
    planId: $planId
    planName: $planName
  }) {
    id
  }
}
`;

// $CoveredComponentsDetails: [{
//   $name:String
//   $components:[{$type:String}]
// }]
const CREATPLANBYADMIN = gql`
mutation createQuotePlan(
  $CoveredComponentsDetails: [CoveredComponentsDetailsInput]
  $EWPCategory: String
  $CoveredComponents: String
  $NoOfCoveredComponents: String
  $CarType: String
  $CarAge: String
  $OdometerReading: String
  $CarBrandByCountry: String
  $MaxPerVisit: String
  $MaxPerYear: String
  $FinancialInstituitionsRate: String
  $DealersRate: String
  $RetailRate: String
){
  createQuotePlan(inputQuotePlan: {
    CoveredComponentsDetails: $CoveredComponentsDetails
    EWPCategory: $EWPCategory
    CoveredComponents: $CoveredComponents
    NoOfCoveredComponents: $NoOfCoveredComponents
    CarType: $CarType
    CarAge: $CarAge
    OdometerReading: $OdometerReading
    CarBrandByCountry: $CarBrandByCountry
    MaxPerVisit: $MaxPerVisit
    MaxPerYear: $MaxPerYear
    FinancialInstituitionsRate: $FinancialInstituitionsRate
    DealersRate: $DealersRate
    RetailRate: $RetailRate
  }) {
    id
  }
}
`;


const UPDATEPLAN = gql`
mutation updatePlan(
  $id: String
  $CoveredComponentsDetails: [CoveredComponentsDetailsInput]
  $EWPCategory: String
  $CoveredComponents: String
  $NoOfCoveredComponents: String
  $CarType: String
  $CarAge: String
  $OdometerReading: String
  $CarBrandByCountry: String
  $MaxPerVisit: String
  $MaxPerYear: String
  $FinancialInstituitionsRate: String
  $DealersRate: String
  $RetailRate: String
){
  updatePlan(updatePlan: {
    id: $id
    CoveredComponentsDetails: $CoveredComponentsDetails
    EWPCategory: $EWPCategory
    CoveredComponents: $CoveredComponents
    NoOfCoveredComponents: $NoOfCoveredComponents
    CarType: $CarType
    CarAge: $CarAge
    OdometerReading: $OdometerReading
    CarBrandByCountry: $CarBrandByCountry
    MaxPerVisit: $MaxPerVisit
    MaxPerYear: $MaxPerYear
    FinancialInstituitionsRate: $FinancialInstituitionsRate
    DealersRate: $DealersRate
    RetailRate: $RetailRate
  }) {
    message
    error
  }
}
`;

const DELETEPLAN = gql`
  mutation deletePlan(
    $planId: ID
  ) {
    deletePlan(
        planId: $planId
    ) {
        message
        error
    }
  }
`;

const ADDCREDIT = gql`
mutation addCredit(
  $dealerId: ID
  $remark: String
  $amount: Float
  $createdBy: ID
)
{
  addCredit(
    dealerId: $dealerId
    remark: $remark
    amount: $amount
    createdBy: $createdBy
    ) {
      message
      error
  }
}
`;

const DEDUCTCREDIT = gql`
mutation deductCredit(
  $dealerId: ID
  $remark: String
  $amount: Float
  $createdBy: ID
)
{
  deductCredit(
    dealerId: $dealerId
    remark: $remark
    amount: $amount
    createdBy: $createdBy
    ) {
      message
      error
  }
}
`;

const GETCURRENTDEALERCREDIT = gql`
mutation getDealerCurrentCredit(
  $dealerId: ID
)
{
  getDealerCurrentCredit(
    dealerId: $dealerId
    ) {
      amount
  }
}
`;

const GETCREDITHISTORYBYDATES = gql`
mutation getCreditHistoryByDates(
  $toDate: Date
  $fromDate: Date
)
{
  getCreditHistoryByDates(
    toDate: $toDate
    fromDate: $fromDate
    ) {
      creditHistoryResults {
        id
        name
        amount
        type
        date
        dealerId
        transactionId
      }
  }
}
`;

const GETCREDITHISTORY = gql`
mutation {
  getCreditHistory {
    creditHistoryResults {
      id
      name
      amount
      type
      date
      dealerId
      transactionId
    }
  }
}
`

const DELETEPROMO = gql`
  mutation deletePromocode(
    $id: ID
  ) {
    deletePromocode(
        id: $id
    ) {
        message
        error
    }
  }
`;

const CREATEPROMOCODE = gql`
mutation createPromocode(
  $status: String
  $name: String
  $promoCode: String
  $discount: Int
  $expirationDate: Date
  $percentage: Int
  $financialInstitute: Int
  $startDate: Date
  $remarks: String
  $type: String
){
  createPromocode(promocodeInput: {
    status: $status
    name: $name
    promoCode: $promoCode
    discount: $discount
    expirationDate: $expirationDate
    percentage: $percentage
    financialInstitute: $financialInstitute
    startDate: $startDate
    remarks: $remarks
    type: $type
  }) {
    id
   
  }
}
`;


const UPDATEPROMOCODE = gql`
mutation updatePromocode(
  $id: ID
  $name: String
  $status: String
  $promoCode: String
  $remarks: String
  $discount: Int
  $percentage: Int
  $financialInstitute: Int
  $expirationDate: Date
  $startDate: Date
  $type: String
)
{
  updatePromocode(
    updatePromo: {
      id: $id
      name: $name
      status: $status
      promoCode: $promoCode
      remarks: $remarks
      discount: $discount
      percentage: $percentage
      financialInstitute: $financialInstitute
      expirationDate: $expirationDate
      startDate: $startDate
      type: $type
    } 
    ) {
    message
    error
  }
}
`;

const DELETE_ROLE_AND_PERMISSION = gql`
  mutation deleteRoleAndPermission(
    $id: ID
  ) {
    deleteRoleAndPermission(
        id: $id
    ) {
        message
        error
    }
  }
`;

const CREATE_ROLE_AND_PERMISSION = gql`
mutation createRoleAndPermission(
  $role: String
  $permission: [String]
  $permissionType: String
){
  createRoleAndPermission(inputRoleAndPermission: {
    role: $role
    permission: $permission
    permissionType: $permissionType
  }) {
    id
   
  }
}
`;

const UPDATE_ROLE_AND_PERMISSION = gql`
mutation updateRoleAndPermission(
  $id: ID
  $role: String
  $permission: [String]
  $permissionType: String
)
{
  updateRoleAndPermission(
    updateRoleAndPermission: {
      id: $id
      role: $role
      permission: $permission
      permissionType: $permissionType
    } 
    ) {
    message
    error
  }
}
`;

const FORGOTPASSWORD = gql`
mutation forgotPassword(   
  $email: String!
){
  forgotPassword(
    email: $email
  ) {
    message
    error
  }
}
`;

const RESETPASSWORD = gql`
mutation resetPassword(   
  $userId: ID
  $token: String
  $password: String
){
  resetPassword(
    userId: $userId
    token: $token
    password: $password
  ) {
    message
  }
}
`;

const FETCHORDERLISTBYPAGINATION = gql`
  mutation getAllBookingPagination(
      $page: Int
      $limit: Int
      $userId: ID
    ) {getAllBookingPagination( 
      page : $page
      limit: $limit
      userId: $userId ){
      allBookingRes {
        id
        brandName
        modelName
        userName
        email
        mobile
        price
        status
        orderId
        voucherCode
        year
        nric
        state
        transactionId
        mileage
        creditCardName
        creditCardNumber
        currency
        bankName
        bankCountry
        transactionDate
        provider
        errDesc
        dealerName
        dealerCode
        orderDate
        promocodeApplied
        planPrice
        discount
        planName
      }
      orderLen
    }
  }
`

const FETCHQUOTEPLANPAGINATION = gql`
  mutation getAllQuotePlanPagination(
      $page: Int
      $limit: Int
    ) {getAllQuotePlanPagination( 
      page : $page
      limit: $limit ){
        AllQuotePlanList {
          id
          EWPCategory
          CoveredComponents
          NoOfCoveredComponents
          CarType
          CarAge
          OdometerReading
          CarBrandByCountry
          MaxPerVisit
          MaxPerYear
          FinancialInstituitionsRate
          DealersRate
          RetailRate
        }
        QuotePlanLen
      }
    }
`

const FETCHALLADMINSCREATEDBYADMINREFETCH = gql`
mutation getAllAdminCreatedByAdminRefetch(
    $createdByadminId: ID
    $roles: [String])
  {
    getAllAdminCreatedByAdminRefetch(
      createdByadminId: $createdByadminId
      roles: $roles
      )
      {
        allAdminRes {
          id
          name
          email
          mobile
          password
          state
          role
          status
          assignedManager{
            managerId
            managerName
          }
          assignedSales{
            salesId
            salesName
          }
          assignedDealer{
            dealerId
            dealerName
          }
        }
      }
  }
`

export {
  DELETEUSER,
  DELETEADMIN,
  DELETEINSPECTION,
  DELETEWORKSHOP,
  DELETEDEALER,
  DELETEBOOKING,
  UPDATEUSER,
  UPDATEINSPECTION,
  UPDATEWORKSHOP,
  UPDATEDEALER,
  UPDATEADMIN,
  CREATEDEALER,
  CREATEWORKSHOP,
  CREATEINSPECTION,
  CREATEUSER,
  CREATEADMIN,
  CREATBOOKINGBYADMIN,
  UPDATEBOOKING,
  ADDCREDIT,
  GETCURRENTDEALERCREDIT,
  GETCREDITHISTORYBYDATES,
  GETCREDITHISTORY,
  DELETEPROMO,
  CREATEPROMOCODE,
  UPDATEPROMOCODE,
  DELETE_ROLE_AND_PERMISSION,
  CREATE_ROLE_AND_PERMISSION,
  UPDATE_ROLE_AND_PERMISSION,
  RESETPASSWORD,
  FORGOTPASSWORD,
  FETCHORDERLISTBYPAGINATION,
  DEDUCTCREDIT,
  FETCHALLADMINSCREATEDBYADMINREFETCH,
  FETCHQUOTEPLANPAGINATION,
  CREATPLANBYADMIN,
  UPDATEPLAN,
  DELETEPLAN
}