import React, { useEffect } from "react"
import { Container, Row, Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory  } from 'react-router-dom'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import CardUser from "./card-user"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import Earning from "./earning"
import SalesAnalytics from "./sales-analytics"
import TotalSellingProduct from "./total-selling-product"
import Tasks from "./tasks"
import ChatBox from "./chat-box"
import {
  ALLUSERSCOUNT, ALLDEALERSCOUNT, GETBOOKINGCOUNT, GETCONFIRMBOOKINGCOUNT, GETFAILUREMBOOKINGCOUNT, ALLSALESCOUNTFORMANAGER, ALLDEALERCOUNTFORMANAGER, ALLBOOKINGCOUNTFORMANAGERANDSALES, ALLCONFIRMBOOKINGCOUNTFORMANAGERANDSALES,
  ALLFAILUREBOOKINGCOUNTFORMANAGERANDSALES
} from "../../../src/queries/userQueries"
import { useQuery } from "@apollo/react-hooks"
import {isExpired} from "react-jwt";

const DashboardSaas = (props) => {
  const history = useHistory();

  const userToken = localStorage.getItem("token");
  useEffect(() => {
    if (!isExpired(userToken)) {
      history.push('/dashboard')
      
    } else {
      localStorage.clear(); // for localStorage
      sessionStorage.clear(); // for sessionStorage
      history.push('/login')
      
    }
  },[]);

  let role = localStorage.getItem("role")
  let userId = localStorage.getItem("userId")
  let allUserCount, allDealerCount, allBookingCount, allConfirmBookingCount, allFailureBookingCount, allSalesCountForManager, allDealerCountForManager, allBookingCountForManagerAndSales, allConfirmBookingCountForManagerAndSales, allFailureBookingCountForManagerAndSales
  {
    const { data } = useQuery(ALLUSERSCOUNT)
    allUserCount = data?.getAllUserNumber?.userCount
  }


  {
    const { data } = useQuery(ALLDEALERSCOUNT)
    allDealerCount = data?.getAllDealerNumber?.dealerCount
  }

  {
    const { data } = useQuery(GETBOOKINGCOUNT)
    allBookingCount = data?.getBookingCount?.bookingCount
  }

  {
    const { data } = useQuery(GETCONFIRMBOOKINGCOUNT)
    allConfirmBookingCount = data?.getConfirmBookingCount?.bookingCount
  }
  {
    const { data } = useQuery(GETFAILUREMBOOKINGCOUNT)
    allFailureBookingCount = data?.getFailureBookingCount?.bookingCount
  }

  {
    const { data } = useQuery(ALLSALESCOUNTFORMANAGER, {
      variables: { managerId: userId }
    })
    allSalesCountForManager = data?.getAllSalesNumber?.salesCount
  }

  {
    const { data } = useQuery(ALLDEALERCOUNTFORMANAGER, {
      variables: { managerId: userId }
    })
    allDealerCountForManager = data?.getAllDealerNumberForManager?.dealerCount
  }

  {
    const { data } = useQuery(ALLBOOKINGCOUNTFORMANAGERANDSALES, {
      variables: { userId: userId }
    })
    allBookingCountForManagerAndSales = data?.getBookingCountForManagerAndSales?.bookingCount
  }

  {
    const { data } = useQuery(ALLCONFIRMBOOKINGCOUNTFORMANAGERANDSALES, {
      variables: { userId: userId }
    })
    allConfirmBookingCountForManagerAndSales = data?.getConfirmBookingCountForManagerAndSales?.bookingCount
  }

  {
    const { data } = useQuery(ALLFAILUREBOOKINGCOUNTFORMANAGERANDSALES, {
      variables: { userId: userId }
    })
    allFailureBookingCountForManagerAndSales = data?.getFailureBookingCountForManagerAndSales?.bookingCount
  }

  let reports

  if (role == "manager") {
    reports = [
      {
        icon: "bx bxs-user",
        title: "Total Sales",
        value: allSalesCountForManager ? allSalesCountForManager : "0",
        // badgeValue: "+ 0.2%",
        // color: "success",
        // desc: "From previous period",
      }
      ,
      {
        icon: "bx bxs-user-circle",
        title: "Total Dealers",
        value: allDealerCountForManager ? allDealerCountForManager : 0,
        // badgeValue: "+ 0.2%",
        // color: "success",
        // desc: "From previous period",
      },
      {
        icon: "bx bx-file",
        title: "Total Orders",
        value: allBookingCountForManagerAndSales ? allBookingCountForManagerAndSales : 0,
        // badgeValue: "+ 0.2%",
        // color: "success",
        // desc: "From previous period",
      },
      {
        icon: "bx bx-file",
        title: "Total Confirm Orders",
        value: allConfirmBookingCountForManagerAndSales ? allConfirmBookingCountForManagerAndSales : 0,
        // badgeValue: "+ 0.2%",
        // color: "success",
        // desc: "From previous period",
      },
      {
        icon: "bx bx-file",
        title: "Total Failed Orders",
        value: allFailureBookingCountForManagerAndSales ? allFailureBookingCountForManagerAndSales : 0,
        // badgeValue: "+ 0.2%",
        // color: "success",
        // desc: "From previous period",
      },
    ];
  } else if (role == "sales") {
    reports = [
      {
        icon: "bx bxs-user-circle",
        title: "Total Dealers",
        value: allDealerCountForManager ? allDealerCountForManager : 0,
        // badgeValue: "+ 0.2%",
        // color: "success",
        // desc: "From previous period",
      },
      {
        icon: "bx bx-file",
        title: "Total Orders",
        value: allBookingCountForManagerAndSales ? allBookingCountForManagerAndSales : 0,
        // badgeValue: "+ 0.2%",
        // color: "success",
        // desc: "From previous period",
      },
      {
        icon: "bx bx-file",
        title: "Total Confirm Orders",
        value: allConfirmBookingCountForManagerAndSales ? allConfirmBookingCountForManagerAndSales : 0,
        // badgeValue: "+ 0.2%",
        // color: "success",
        // desc: "From previous period",
      },
      {
        icon: "bx bx-file",
        title: "Total Failed Orders",
        value: allFailureBookingCountForManagerAndSales ? allFailureBookingCountForManagerAndSales : 0,
        // badgeValue: "+ 0.2%",
        // color: "success",
        // desc: "From previous period",
      },
    ];
  } else {
    reports = [
      {
        icon: "bx bxs-user",
        title: "Total Users",
        value: allUserCount ? allUserCount : "0",
        // badgeValue: "+ 0.2%",
        // color: "success",
        // desc: "From previous period",
      }
      ,
      {
        icon: "bx bxs-user-circle",
        title: "Total Dealers",
        value: allDealerCount ? allDealerCount : 0,
        // badgeValue: "+ 0.2%",
        // color: "success",
        // desc: "From previous period",
      },
      {
        icon: "bx bx-file",
        title: "Total Orders",
        value: allBookingCount ? allBookingCount : 0,
        // badgeValue: "+ 0.2%",
        // color: "success",
        // desc: "From previous period",
      },
      {
        icon: "bx bx-file",
        title: "Total Confirm Orders",
        value: allConfirmBookingCount ? allConfirmBookingCount : 0,
        // badgeValue: "+ 0.2%",
        // color: "success",
        // desc: "From previous period",
      },
      {
        icon: "bx bx-file",
        title: "Total Failed Orders",
        value: allFailureBookingCount ? allFailureBookingCount : 0,
        // badgeValue: "+ 0.2%",
        // color: "success",
        // desc: "From previous period",
      },
    ];
  }

  return (
    <React.Fragment>

      <div className="page-content">

        <MetaTags>
          <title>
            EAutoGen Admin Dashboard
          </title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Dashboards" breadcrumbItem="" /> */}

          {/* Card User */}
          <CardUser />
          <MiniWidget reports={reports} />
          <Row>
            {/* welcome card */}
            {/* <CardWelcome /> */}

            <Col xl="8">
              <Row>
                {/*mimi widgets */}

              </Row>
            </Col>
          </Row>

          <Row>
            {/* earning */}
            {/* <Earning /> */}

            {/* sales anytics */}
            {/* <SalesAnalytics /> */}
          </Row>

          <Row>
            {/* total selling product */}
            {/* <TotalSellingProduct /> */}

            {/* tasks */}
            {/* <Tasks /> */}

            {/* chat box */}
            {/* <ChatBox /> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardSaas
