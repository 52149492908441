import React, { useEffect, useState } from "react";
import "./Reset.scss";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/eautogen_white.svg";
import Logo_mobile from "../../assets/images/header_bg.png";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "../../utils/hooks";
import { useMutation } from "@apollo/client";

import { RESETPASSWORD } from "../../mutations/useMutations"

function Login() {

    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');
    const token = queryParams.get('token');

    const [tokenUpdated, setToken] = useState("");
    const [userIdUpdated, setUserId] = useState("");


    useEffect(() => {
        setUserId(id)
        setToken(token)
    })



    // const [newPwd, setNewPwd] = useState("");
    // const [confirmPwd, setConfirmPwd] = useState("");


    const { onChange, onSubmit, values } = useForm(handleSubmit, {
        password: "",
        confirmpassword: "",
    });


    const [resetPassword] = useMutation(RESETPASSWORD, {
        update(_, { data: { resetPassword: userData } }) {
            if (userData) {
                toast(userData.message);

                setTimeout(() => {
                    window.location.reload();
                }, 0);
            }
        },
        onError(err) {
            toast(err.message);
        },
    });

    function handleSubmit() {

        if (values.password !== values.confirmpassword) {
            toast("Password And Confirm Password Should Match");
            return;
        }



        resetPassword(
            {
                variables: {
                    userId: userIdUpdated,
                    token: tokenUpdated,
                    password: values.confirmpassword
                }

            }
        )


    }

    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] =
        useState("password");

    const togglePassword = (event) => {
        event.preventDefault();
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };

    const toggleConfirmPassword = (event) => {
        event.preventDefault();
        if (confirmPasswordType === "password") {
            setConfirmPasswordType("text");
            return;
        }
        setConfirmPasswordType("password");
    };

    return (
        <section className="authentication">
            <ToastContainer />

            <Container fluid className="ps-lg-0">
                <Row className="">
                    <Col lg={6} className="d-none d-xl-block">
                        <div className="authentication-content">
                            <div className="logo">
                                <Image src={Logo} />
                            </div>
                            <Row className="align-items-center">
                                <Col md={12}>
                                    <h1>
                                        <span>Get Covered </span>
                                        By AUTOGEN PLUS
                                    </h1>
                                    <p>
                                        AutoGen+ is an extended warranty plan that covers two major
                                        car component: Engine & Tramission (a.k.a. Gearbox)
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={6}  >
                        <div className="authentication-box mt-5">
                            <div className="authentication-inputs">
                                <Image src={Logo_mobile} className="d-block d-xl-none mobile-logo" fluid />
                                <h1 className="text-center mb-3">Reset Password</h1>
                                <Form onSubmit={onSubmit}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>
                                            New Password <span className="text-danger">*</span>{" "}
                                        </Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <Form.Control
                                                type={passwordType}
                                                name="password"
                                                required="required"
                                                // value={values.email}
                                                onChange={onChange}
                                                placeholder="New Password"
                                            />
                                            <button
                                                onClick={togglePassword}
                                                id="toggle_btn"
                                                className="password_toggle_btn"
                                            >
                                                {passwordType === "password" ? (
                                                    <BsEyeSlash className="cross_eye_icon_log" />
                                                ) : (
                                                    <BsEye className="cross_eye_icon_log" />
                                                )}
                                            </button>
                                        </div>
                                    </Form.Group>{" "}
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>
                                            Confirm Password <span className="text-danger">*</span>{" "}
                                        </Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <Form.Control
                                                type={confirmPasswordType}
                                                name="confirmpassword"
                                                required="required"
                                                // value={values.email}
                                                onChange={onChange}
                                                placeholder="Confirm Password"

                                            />
                                            <button
                                                onClick={toggleConfirmPassword}
                                                id="toggle_btn"
                                                className="password_toggle_btn"
                                            >
                                                {confirmPasswordType === "password" ? (
                                                    <BsEyeSlash className="cross_eye_icon_log" />
                                                ) : (
                                                    <BsEye className="cross_eye_icon_log" />
                                                )}
                                            </button>
                                        </div>
                                    </Form.Group>
                                    <div className="d-grid gap-2 mb-3">
                                        <Button variant="secondary" size="lg" type="submit" className="sign-up-btn" style={{ backgroundColor: "#32bec0" }}>
                                            Reset Password
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Login;
