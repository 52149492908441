import React, { useEffect, useState, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import * as moment from "moment";
import * as Yup from "yup";
import MetaTags from 'react-meta-tags';

import { CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form,
  UncontrolledTooltip
} from "reactstrap";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import { useSelector, useDispatch } from "react-redux"

import { GETALLDEALERS, GETALLSTATES, FETCHALLDEALERSFORMANAGER } from "../../../queries/userQueries"
import { DELETEDEALER, UPDATEDEALER, CREATEDEALER } from "../../../mutations/useMutations"
import { useQuery, useMutation } from "@apollo/react-hooks"
import DeleteModal from "../../../components/Common/DeleteModal";
import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "store/e-commerce/actions";
import EcommerceDealerListModal from "./EcommerceDealerListModal";




const DealerList = props => {
  const myArray = localStorage.getItem("permission");
  const permitList = myArray?.split(",")

  const [modal, setModal] = useState(false);
  const [dealer, setDealer] = React.useState([]);
  const [modal1, setModal1] = useState(false);
  const [dealerId, setDealerId] = useState("");
  const [dealerToDelete, setDealerToDelete] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dealerToEdit, setDealerToEdit] = useState(null);
  const [states, setStates] = React.useState([]);

  const [dealerList, setDealerList] = useState([]);

  // Edit Dealer
  const [editDealer] = useMutation(UPDATEDEALER, {
    update(_, { data: { updateDealer: DealerData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);

    },
    onError(err) {
      console.log(err.message);
    },
  });

  // Add Dealer
  const [addDealer] = useMutation(CREATEDEALER, {
    update(_, { data: { createDealer: DealerData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);

    },
    onError(err) {
      console.log(err.message);
    },
  });


  function handleUpdateDealer(updateDealer) {

    editDealer(
      {
        variables: {

          id: updateDealer?.id,
          name: updateDealer?.name,
          mobile: updateDealer?.mobile,
          email: updateDealer?.email,
          dealerType: updateDealer?.dealertype,
          dealerCode: updateDealer?.dealercode,
          dealerNumber: updateDealer?.dealerNumber,
          stateCode: updateDealer?.stateCode,
          state: updateDealer?.state,
          postcode: updateDealer?.postcode,
          fmcMember: updateDealer?.fmcmember,
          ezPartner: updateDealer?.ezpartner,

        }

      }
    )
  }

  function handleAddDealer(addDealerData) {

    addDealer(
      {
        variables: {
          name: addDealerData?.name,
          email: addDealerData?.email,
          mobile: addDealerData?.mobile,
          postcode: addDealerData?.postcode,
          state: addDealerData?.state,
          ezPartner: addDealerData?.ezpartner,
          fmcMember: addDealerData?.fmcmember,
          dealerCode: addDealerData?.dealercode,
          stateCode: addDealerData?.statecode,
          dealerType: addDealerData?.dealertype,
          dealerNumber: addDealerData?.dealerNumber

        }

      }
    )
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (dealerToEdit && dealerToEdit?.name) || '',
      email: (dealerToEdit && dealerToEdit?.email) || '',
      dealercode: (dealerToEdit && dealerToEdit?.dealercode) || '',
      state: (dealerToEdit && dealerToEdit?.state) || '',
      dealernumber: (dealerToEdit && dealerToEdit?.dealernumber) || '',
      dealertype: (dealerToEdit && dealerToEdit?.dealertype) || '',
      mobile: (dealerToEdit && dealerToEdit?.mobile) || '',
      statecode: (dealerToEdit && dealerToEdit?.statecode) || '',
      postcode: (dealerToEdit && dealerToEdit?.postcode) || '',
      fmcmember: (dealerToEdit && dealerToEdit?.fmcmember) || '',
      ezpartner: (dealerToEdit && dealerToEdit?.ezpartner) || '',

    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      email: Yup.string().required("Please Enter Email"),
      mobile: Yup.string().required("Please Enter Mobile Number"),
      state: Yup.string().required("Please Select State"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        let statesName = states?.filter((stateItem) => {
          if (stateItem.id === values?.state) {
            return stateItem;
          }
        });

        const updateDealer = {
          id: dealerToEdit?.id,
          name: values?.name,
          email: values?.email,
          dealercode: values?.dealercode,
          state: statesName[0]?.name,
          dealerNumber: values?.dealernumber,
          dealertype: values?.dealertype,
          mobile: values?.mobile,
          stateCode: values?.statecode,
          postcode: values?.postcode,
          fmcmember: values?.fmcmember,
          ezpartner: values?.ezpartner
        };

        // update order
        // dispatch(onUpdateOrder(updateOrder));
        handleUpdateDealer(updateDealer);
        validation.resetForm();
      }
      else {

        let statesName = states?.filter((stateItem) => {
          if (stateItem.id === values["state"]) {
            return stateItem;
          }
        });

        const newDealer = {
          name: values["name"],
          email: values["email"],
          dealercode: values["dealercode"],
          state: statesName[0]?.name,
          dealerNumber: values["dealerNumber"],
          dealertype: values["dealertype"],
          mobile: values["mobile"],
          statecode: values["statecode"],
          postcode: values["postcode"],
          fmcmember: values["fmcmember"],
          ezpartner: values["ezpartner"],
        };
        // save new order
        handleAddDealer(newDealer)
        validation.resetForm();
      }
      toggle();
    },
  });


  //dealer delete
  const [deleteDealer] = useMutation(DELETEDEALER, {
    update(_, { data: { deleteDealer: dealerData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);
    },
    onError(err) {
      console.log(err.message);
    },
  });


  useEffect(() => {
    if (data && type == "manager") {
      const dealerRes = data?.getAllDealerForManager?.allAdminRes
      setDealer(dealerRes)
    }
    if (data && type == "admin") {
      const dealerRes = data?.getAllDealer?.allDealerRes
      setDealer(dealerRes)
    }
    if (dataState) {
      const stateRes = dataState?.getAllState?.allStateRes
      setStates(stateRes)
    }
  })

  let role = localStorage.getItem("role")
  let userId = localStorage.getItem("userId")

  let data, loading, type
  if (role == "manager" || role == "sales") {
    const { loading: managerDealerLoading, data: managerDataLoading } = useQuery(FETCHALLDEALERSFORMANAGER, {
      variables: { managerId: userId }
    })
    data = managerDataLoading
    loading = managerDealerLoading
    type = "manager"
  } else {
    const { loading: loadingDealerAdmin, data: dataDealerAdmin } = useQuery(GETALLDEALERS)
    data = dataDealerAdmin
    loading = loadingDealerAdmin
    type = "admin"
  }


  const { loading: loadingState, data: dataState } = useQuery(GETALLSTATES)
  if (loading) {
    return "loading..."
  }

  if (loadingState) {
    return "loading..."
  }

  const dealers = dealer
  const selectRow = {
    mode: "checkbox",
  };

  const pageOptions = {
    sizePerPage: 10,
    totalSize: dealers.length, // replace later with size(orders),
    custom: true,
  };
  const { SearchBar } = Search;

  const toggleViewModal = () => setModal1(!modal1)

  const sendDealerId = (dealerId) => {
    let dealerToView = dealers?.filter(item => {
      if (item.id === dealerId) {
        return item
      }
    })
    setDealerId(dealerToView)
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      // setUser(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {

    const dealerData = arg;

    let statesName = states?.filter((stateItem) => {
      if (stateItem.name === dealerData?.state) {
        return stateItem;
      }
    });

    setDealerToEdit({
      id: dealerData?.id,
      name: dealerData?.name,
      email: dealerData?.email,
      dealercode: dealerData?.dealerCode,
      state: statesName[0].id,
      dealernumber: dealerData?.dealerNumber,
      dealertype: dealerData?.dealerType,
      statecode: dealerData?.stateCode,
      mobile: dealerData?.mobile,
      postcode: dealerData?.postcode,
      ezpartner: dealerData?.ezPartner,
      fmcmember: dealerData?.fmcMember,
    });

    setIsEdit(true);

    toggle();
  };


  //delete dealer

  const onClickDelete = (dealer) => {

    setDealerToDelete(dealer)
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (dealerToDelete) {
      deleteDealer({
        variables: {
          dealerId: dealerToDelete.id,
        },
      });
      // onPaginationPageChange(1);
      setDeleteModal(false);
    }
  };


  const EcommerceDealerColumns = [{
    dataField: 'id',
    text: 'Dealer Id',
    sort: true,
  }, {
    dataField: 'name',
    text: 'Full Name',
    sort: true
  }, {
    dataField: 'mobile',
    text: 'Mobile',
    sort: true
  }, {
    dataField: 'email',
    text: 'Email',
    sort: true
  }, {
    dataField: 'postcode',
    text: 'Postcode',
    sort: true
  }, {
    dataField: 'state',
    text: 'State',
    sort: true
  }, {
    dataField: 'ezPartner',
    text: 'Ez-Partners',
    sort: true

  }, {
    dataField: 'fmcMember',
    text: 'Fmc-Member',
    sort: true
  }, {
    dataField: 'dealerCode',
    text: 'Dealer Code',
    sort: true
  },
  {
    dataField: 'stateCode',
    text: 'State Code',
    sort: true
  },
  {
    dataField: 'dealerType',
    text: 'Dealer Type',
    sort: true
  },
  {
    dataField: 'dealerNumber',
    text: 'Dealer Number',
    sort: true
  },
  {
    dataField: "view",
    isDummyField: true,
    text: "View Details",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (
      <>
        {
          permitList?.find((task) => task == "View") == "View" ? (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                sendDealerId(row.id);
                toggleViewModal()
              }

              }
            >
              View Details
            </Button >
          ) : " -"
        }
      </>
    ),
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, dealer) => (
      <>
        <div className="d-flex gap-3">
          {
            permitList?.find((task) => task === "Edit") == "Edit" && (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(dealer)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            )}


          {
            permitList?.find((task) => task === "Delete") == "Delete" && (
              <>
                {role == "manager" || role == "sales" ? "" : (
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => onClickDelete(dealer)}
                  >
                    <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                )
                }
              </>
            )}
        </div>
      </>
    ),
  },
  ];

  //add Dealer
  const handleCustomerClicks = () => {
    setDealerToEdit("")
    setDealerList("");
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];


  return (
    <React.Fragment>
      <EcommerceDealerListModal isOpen={modal1} toggle={toggleViewModal} dealerId={dealerId} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      {
        loading ?
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div> :
          <div className="page-content">

            <Container fluid>
              {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
              <h2>Dealer List</h2>
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={EcommerceDealerColumns}
                        data={dealers}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={dealers}
                            columns={EcommerceDealerColumns}
                            bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  {
                                    permitList?.find((task) => task == "Create") == "Create" && (
                                      <Col sm="8">
                                        <div className="text-sm-end">
                                          <Button
                                            type="button"
                                            color="success"
                                            className="btn-rounded  mb-2 me-2"
                                            onClick={handleCustomerClicks}
                                          >
                                            <i className="mdi mdi-plus me-1" />
                                            Add New Dealer
                                          </Button>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap table-check"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      // ref={node}
                                      />
                                      <Modal isOpen={modal} toggle={toggle}>
                                        <ModalHeader toggle={toggle} tag="h4">
                                          {!!isEdit ? "Edit Dealer" : "Add Dealer"}
                                        </ModalHeader>
                                        <ModalBody>
                                          <Form
                                            onSubmit={(e) => {
                                              e.preventDefault();
                                              validation.handleSubmit();
                                              return false;
                                            }}
                                          >
                                            <Row form>
                                              <Col className="col-12">
                                                <div className="mb-3">
                                                  <Label className="form-label">Full Name</Label>
                                                  <Input
                                                    name="name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.name || ""}
                                                    invalid={
                                                      validation.touched.name && validation.errors.name ? true : false
                                                    }
                                                  />
                                                  {validation.touched.name && validation.errors.name ? (
                                                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">Email</Label>
                                                  <Input
                                                    name="email"
                                                    type="text"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.email || ""}
                                                    invalid={
                                                      validation.touched.email && validation.errors.email ? true : false
                                                    }
                                                  />
                                                  {validation.touched.email && validation.errors.email ? (
                                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">Dealer Type</Label>
                                                  <Input
                                                    name="dealertype"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.dealertype || ""}
                                                    invalid={
                                                      validation.touched.dealertype && validation.errors.dealertype ? true : false
                                                    }
                                                  />
                                                  {validation.touched.dealertype && validation.errors.dealertype ? (
                                                    <FormFeedback type="invalid">{validation.errors.dealertype}</FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">State</Label>
                                                  <Input
                                                    name="state"
                                                    type="select"
                                                    className="form-select"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={
                                                      validation.values.state || ""
                                                    }
                                                    invalid={
                                                      validation.touched.state && validation.errors.state ? true : false
                                                    }
                                                  >
                                                    <option value="" disabled>Select State</option>
                                                    {
                                                      states?.map((statesItem, index) => (

                                                        <option key={index} value={statesItem.id}>{statesItem?.name}</option>
                                                      ))

                                                    }
                                                  </Input>
                                                  {validation.touched.state && validation.errors.state ? (
                                                    <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">Dealer Code</Label>
                                                  <Input
                                                    name="dealercode"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.dealercode || ""}
                                                    invalid={
                                                      validation.touched.dealercode && validation.errors.dealercode ? true : false
                                                    }
                                                  />
                                                  {validation.touched.dealercode && validation.errors.dealercode ? (
                                                    <FormFeedback type="invalid">{validation.errors.dealercode}</FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">Dealer Number</Label>
                                                  <Input
                                                    name="dealernumber"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.dealernumber || ""}
                                                    invalid={
                                                      validation.touched.dealernumber && validation.errors.dealernumber ? true : false
                                                    }
                                                  />
                                                  {validation.touched.dealernumber && validation.errors.dealernumber ? (
                                                    <FormFeedback type="invalid">{validation.errors.dealernumber}</FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">Mobile</Label>
                                                  <Input
                                                    name="mobile"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.mobile || ""}
                                                    invalid={
                                                      validation.touched.mobile && validation.errors.mobile ? true : false
                                                    }
                                                  />
                                                  {validation.touched.mobile && validation.errors.mobile ? (
                                                    <FormFeedback type="invalid">{validation.errors.mobile}</FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">State Code</Label>
                                                  <Input
                                                    name="statecode"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.statecode || ""}
                                                    invalid={
                                                      validation.touched.statecode && validation.errors.statecode ? true : false
                                                    }
                                                  />
                                                  {validation.touched.statecode && validation.errors.statecode ? (
                                                    <FormFeedback type="invalid">{validation.errors.statecode}</FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">Postcode</Label>
                                                  <Input
                                                    name="postcode"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.postcode || ""}
                                                    invalid={
                                                      validation.touched.postcode && validation.errors.postcode ? true : false
                                                    }
                                                  />
                                                  {validation.touched.postcode && validation.errors.postcode ? (
                                                    <FormFeedback type="invalid">{validation.errors.postcode}</FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">EZ-Partner</Label>
                                                  <Input
                                                    name="ezpartner"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.ezpartner || ""}
                                                    invalid={
                                                      validation.touched.ezpartner && validation.errors.ezpartner ? true : false
                                                    }
                                                  />
                                                  {validation.touched.ezpartner && validation.errors.ezpartner ? (
                                                    <FormFeedback type="invalid">{validation.errors.ezpartner}</FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">FMC-Member</Label>
                                                  <Input
                                                    name="fmcmember"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.fmcmember || ""}
                                                    invalid={
                                                      validation.touched.fmcmember && validation.errors.fmcmember ? true : false
                                                    }
                                                  />
                                                  {validation.touched.fmcmember && validation.errors.fmcmember ? (
                                                    <FormFeedback type="invalid">{validation.errors.fmcmember}</FormFeedback>
                                                  ) : null}
                                                </div>

                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <div className="text-end">
                                                  <button
                                                    type="submit"
                                                    className="btn btn-success save-user"
                                                  >
                                                    Save
                                                  </button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </Form>
                                        </ModalBody>
                                      </Modal>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
      }
    </React.Fragment>

  );
};


export default DealerList;
