import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as moment from "moment"
import * as Yup from "yup"
import MetaTags from "react-meta-tags"
import { CardTitle, CardSubtitle, UncontrolledTooltip } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { withRouter, Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,

  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

import { GETCREDITLIST } from "../../../queries/userQueries"
import { DELETEUSER, UPDATEUSER, CREATEUSER } from "../../../mutations/useMutations"

import { useQuery, useMutation } from "@apollo/react-hooks"
import DeleteModal from "../../../components/Common/DeleteModal"
import { useForm } from "../../../utils/hooks";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EcommerceCreditListModal from "./EcommerceCreditListModal";
import { useHistory } from "react-router-dom";

export const CreditList = () => {

  const history = useHistory();

  const [modal, setModal] = useState(false);
  const [creditList, setCreditList] = React.useState([]);
  const [modal1, setModal1] = useState(false);
  const [dealerId, setDealerId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const [userList, setUserList] = useState([]);


  const [updateUser] = useMutation(UPDATEUSER, {
    update(_, { data: { updateUser: userData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);

    },
    onError(err) {
      console.log(err.message);
    },
  });


  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (userToEdit && userToEdit?.name) || '',
      dealerId: (userToEdit && userToEdit?.dealerId) || '',
      email: (userToEdit && userToEdit?.email) || '',
      mobile: (userToEdit && userToEdit?.mobile) || '',
      amount: (userToEdit && userToEdit?.amount) || '',
      type: (userToEdit && userToEdit?.type) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter User Name"),
      email: Yup.string().required("Please Enter User Email"),
      mobile: Yup.string().required("Please Enter User Mobile Number"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          id: userToEdit?.id,
          name: values?.name,
          email: values?.email,
          mobile: values?.mobile,
          amount: values?.amount,
          type: values?.type,
        };
        // update order
        // dispatch(onUpdateOrder(updateOrder));
        handleupdateUser(updateOrder);
        validation.resetForm();
      }
      else {
        const addUser = {
          name: values["name"],
          email: values["email"],
          mobile: values["mobile"],
          nric: values["nric"],
          state: values["state"],
          status: values["status"],
        };
        // save new order
        handleAddUser(addUser)
        validation.resetForm();
      }
      toggle();
    },
  });



  useEffect(() => {
    if (data) {
      const creditListRes = data?.getCreditList?.dealerResults
      setCreditList(creditListRes)
    }
  })

  const { loading, data } = useQuery(GETCREDITLIST)

  if (loading) {
    return "loading..."
  }

  const creditLists = creditList

  const selectRow = {
    mode: "checkbox",
  };

  const pageOptions = {
    sizePerPage: 10,
    totalSize: creditLists.length, // replace later with size(orders),
    custom: true,
  };
  const { SearchBar } = Search;

  const toggleViewModal = () => setModal1(!modal1)

  const sendCreditId = (dealerId) => {
    let userToView = creditLists?.filter(item => {
      if (item.id === dealerId) {
        return item
      }
    })
    setDealerId(userToView)
  }

  const sendCreditIdToTopUp = (dealerId) => {
    let userToView = creditLists?.filter(item => {
      if (item.id === dealerId) {
        return item
      }
    })
    console.log("userToView", userToView)

    history.push({
      pathname: "/create-credit",
      state: userToView
    }
    );

  }

  const sendCreditIdToDeduct = (dealerId) => {
    let userToView = creditLists?.filter(item => {
      if (item.id === dealerId) {
        return item
      }
    })
    console.log("userToView deduct", userToView)

    history.push({
      pathname: "/deduct-credit",
      state: userToView
    }
    );

  }


  const toggle = () => {
    if (modal) {
      setModal(false);
      // setUser(null);
    } else {
      setModal(true);
    }
  };


  const handleOrderClick = arg => {

    const userData = arg;
    setUserToEdit({
      id: userData?.id,
      name: userData?.name,
      email: userData?.email,
      mobile: userData?.mobile,
      nric: userData?.nric,
      state: userData?.state,
      status: userData?.status,
    });

    setIsEdit(true);

    toggle();
  };

  const EcommerceUserColumns = [
    {
      dataField: 'id',
      text: 'Dealer Id',
      sort: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    }, {
      dataField: 'email',
      text: 'Email',
      sort: true
    },
    {
      dataField: 'mobile',
      text: 'Mobile',
      sort: true
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true
    },
    {
      dataField: 'topup',
      text: 'Top Up',
      sort: true,
      formatter: (cellContent, row) => (
        <Button
          type="button"
          color="success"
          className="btn-sm btn-rounded"
          onClick={() => {
            sendCreditIdToTopUp(row.id);

          }

          }
        >
          Top Up
        </Button >
      ),
    },
    {
      dataField: 'deduct',
      text: 'Deduct',
      sort: true,
      formatter: (cellContent, row) => (
        <Button
          type="button"
          color="danger"
          className="btn-sm btn-rounded"
          onClick={() => {
            sendCreditIdToDeduct(row.id);

          }

          }
        >
          Deduct
        </Button >
      ),
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => {
            sendCreditId(row.id);
            toggleViewModal()
          }

          }
        >
          View Details
        </Button >
      ),
    },

  ];


  //add User
  const handleCustomerClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];



 
  

  return (
    <React.Fragment>
      <ToastContainer />
      <EcommerceCreditListModal isOpen={modal1} toggle={toggleViewModal} dealerId={dealerId} />

      {
        loading ?
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div> :
          <div className="page-content">

            <Container fluid>

              <h2>Credit List</h2>
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={EcommerceUserColumns}
                        data={creditLists}
                      >
                        {

                          ({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              data={creditLists}
                              columns={EcommerceUserColumns}
                              bootstrap4
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col sm="8">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar {...toolkitProps.searchProps} />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm="4" className="d-flex justify-content-end">
                                      <div className="text-sm-end">
                                        <Link to="/create-credit"><Button
                                          type="button"
                                          color="success"
                                          className="btn-rounded  mb-2 me-2"
                                          onClick={handleCustomerClicks}
                                        >
                                          <i className="mdi mdi-plus me-1" />
                                          Top Up Credit
                                        </Button></Link>
                                      </div>
                                      <div className="text-sm-end">
                                        <Link to="/deduct-credit"><Button
                                          type="button"
                                          color="danger"
                                          className="btn-rounded  mb-2 me-2"
                                          onClick={handleCustomerClicks}
                                        >
                                          <i className="mdi mdi-minus me-1" />
                                          Deduct Credit
                                        </Button></Link>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField="id"
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          // selectRow={selectRow}
                                          classes={
                                            "table align-middle table-nowrap table-check"
                                          }
                                          headerWrapperClasses={"table-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        // ref={node}
                                        />
                                      </div>

                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
      }
    </React.Fragment>
  )
}
