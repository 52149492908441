import React from "react"
import PropTypes from "prop-types"
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table,
} from "reactstrap"
import img7 from "../../../assets/images/product/img-7.png"
import img4 from "../../../assets/images/product/img-4.png"

const EcommerceDealerListModal = props => {
    const { isOpen, toggle, dealerId } = props

    return (
        <React.Fragment>
            {
                dealerId ? (
                    <Modal
                        isOpen={isOpen}
                        role="dialog"
                        autoFocus={true}
                        centered={true}
                        className="exampleModal"
                        tabIndex="-1"
                        toggle={toggle}
                    >
                        <div className="modal-content">
                            <ModalHeader toggle={toggle}>Dealer Details</ModalHeader>
                            <ModalBody>
                                <p className="mb-2">
                                    Dealer Id: <span className="text-primary">{dealerId[0].id}</span>
                                </p>
                                <p className="mb-2">
                                    Full Name: <span className="text-primary">{dealerId[0].name}</span>
                                </p>
                                <p className="mb-2">
                                    Mobile: <span className="text-primary">{dealerId[0].mobile}</span>
                                </p>
                                <p className="mb-2">
                                    Email: <span className="text-primary">{dealerId[0].email}</span>
                                </p>
                                <p className="mb-2">
                                    State: <span className="text-primary">{dealerId[0].state}</span>
                                </p>
                                <p className="mb-2">
                                    Postcode: <span className="text-primary">{dealerId[0].postcode}</span>
                                </p>
                                <p className="mb-2">
                                    EZ-Partner: <span className="text-primary">{dealerId[0].ezPartner}</span>
                                </p>
                                <p className="mb-2">
                                    FMC-Member: <span className="text-primary">{dealerId[0].fmcMember}</span>
                                </p>
                                <p className="mb-2">
                                    State Code: <span className="text-primary">{dealerId[0].stateCode}</span>
                                </p>
                                <p className="mb-2">
                                    Dealer Number: <span className="text-primary">{dealerId[0].dealerNumber}</span>
                                </p>
                                <p className="mb-2">
                                    Dealer Type: <span className="text-primary">{dealerId[0].dealerType}</span>
                                </p>
                                <p className="mb-2">
                                    Dealer Code: <span className="text-primary">{dealerId[0].dealerCode}</span>
                                </p>


                            </ModalBody>
                            <ModalFooter>
                                <Button type="button" color="secondary" onClick={toggle}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </div>
                    </Modal >) : (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )

            }
        </React.Fragment>
    )
}

EcommerceDealerListModal.propTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
    dealerId: PropTypes.string,
}

export default EcommerceDealerListModal
