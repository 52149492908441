import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"

//graphql
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from "@apollo/client"

import store from "./store"
import { setContext } from "redux-saga/effects"

// const client = new ApolloClient({
//   uri: "http://localhost:82/graphql",
//   // uri: "https://uat-admin.eautogen.my/graphql",
//   cache: new InMemoryCache(),
// })
const httpLink = createHttpLink({
  // uri: 'http://localhost:82/graphql',
  uri: "https://admin.eautogen.my/graphql",
});

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem('token');
  console.log("token", token);
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
