import React from "react"
import PropTypes from "prop-types"
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table,
} from "reactstrap"
import img7 from "../../../assets/images/product/img-7.png"
import img4 from "../../../assets/images/product/img-4.png"

const EcommerceWorkshopListModal = props => {
    const { isOpen, toggle, workshopId } = props

    return (
        <React.Fragment>
            {
                workshopId ? (
                    <Modal
                        isOpen={isOpen}
                        role="dialog"
                        autoFocus={true}
                        centered={true}
                        className="exampleModal"
                        tabIndex="-1"
                        toggle={toggle}
                    >
                        <div className="modal-content">
                            <ModalHeader toggle={toggle}>Workshop Details</ModalHeader>
                            <ModalBody>
                                <p className="mb-2">
                                    Workshop Id: <span className="text-primary">{workshopId[0].id}</span>
                                </p>
                                <p className="mb-2">
                                    Name: <span className="text-primary">{workshopId[0].name}</span>
                                </p>
                                <p className="mb-2">
                                    Email: <span className="text-primary">{workshopId[0].email}</span>
                                </p>
                                <p className="mb-2">
                                    Mobile: <span className="text-primary">{workshopId[0].mobile}</span>
                                </p>
                                <p className="mb-2">
                                    Telephone: <span className="text-primary">{workshopId[0].telephone}</span>
                                </p>
                                <p className="mb-2">
                                    State: <span className="text-primary">{workshopId[0].state}</span>
                                </p>
                                <p className="mb-2">
                                    State Code: <span className="text-primary">{workshopId[0].stateCode}</span>
                                </p>
                                <p className="mb-2">
                                    Address: <span className="text-primary">{workshopId[0].address}</span>
                                </p>
                                <p className="mb-2">
                                    Area: <span className="text-primary">{workshopId[0].area}</span>
                                </p>
                                <p className="mb-2">
                                    Person In Charge: <span className="text-primary">{workshopId[0].personInCharge}</span>
                                </p>
                                <p className="mb-2">
                                    Dealer Type: <span className="text-primary">{workshopId[0].dealerType}</span>
                                </p>
                                <p className="mb-2">
                                    Dealer Code: <span className="text-primary">{workshopId[0].dealcode}</span>
                                </p>
                                <p className="mb-2">
                                    Dealer Number: <span className="text-primary">{workshopId[0].dealerNumber}</span>
                                </p>
                                <p className="mb-2">
                                    Category: <span className="text-primary">{workshopId[0].category}</span>
                                </p>
                                <p className="mb-2">
                                    Workshop Type: <span className="text-primary">{workshopId[0].workshopType}</span>
                                </p>


                            </ModalBody>
                            <ModalFooter>
                                <Button type="button" color="secondary" onClick={toggle}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </div>
                    </Modal >) : (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )

            }
        </React.Fragment>
    )
}

EcommerceWorkshopListModal.propTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
    workshopId: PropTypes.string,
}

export default EcommerceWorkshopListModal
