import React from 'react'
import { useHistory, useLocation } from "react-router-dom";
import DeductNewCredit from "./DeductNewCredit";
import DeductCredit from "./DeductCredit";

function ShowDeductCredit() {
    const location = useLocation();
    console.log("location", typeof location.state)



    return (
        <div>
            {
                typeof location.state == "object" ? <DeductCredit creditData={location.state} /> : <DeductNewCredit />
            }
        </div>
    )
}

export default ShowDeductCredit