import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import PropTypes from 'prop-types'
import { GETALLSALES } from "../../../queries/userQueries"
import { useQuery, useMutation } from "@apollo/react-hooks"

function MultiSelectAllSales({ options, handleClickMultiSelect }) {
    const [dealers, setDealer] = React.useState([]);

    var newSalesArr = options

    let addSalesLabel = []
    let newSalesArrLen = newSalesArr ? newSalesArr.length : 0
    if (newSalesArrLen !== 0) {
        newSalesArr.map((newSalesArrItem) => {


            let newSalesArrItemObj = {
                id: 1,
                value: newSalesArrItem.salesId,
                isChecked: false,
                label: newSalesArrItem.salesName
            }


            addSalesLabel.push(newSalesArrItemObj)
        })
    }

    const [selectedOptions, setSelectedOptions] = useState([]);

    handleClickMultiSelect(selectedOptions)

    useEffect(() => {
        setSelectedOptions([...addSalesLabel]);
    }, []);

    useEffect(() => {
        if (data) {
            const salesRes = data?.getAllSales?.allSalesRes
            setDealer(salesRes)
        }

    });

    const { loading, data } = useQuery(GETALLSALES)

    if (loading) {
        return "loading..."
    }

    const allOptions = dealers
    // useEffect(() => {
    //     setSelectedOptions([...addPermissionLabel]);
    // }, []);

    function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        let selectedOptionsLen = value.length

        let selectedOptionsLabelRes
        if (selectedOptionsLen > 0) {
            let selectedOptionsLabel = value.map(function (item) {
                return " " + item['label'];
            });
            selectedOptionsLabelRes = selectedOptionsLabel.toString()
        }
        if (value && value.some((o) => o.value === "*")) {
            return `${selectedOptionsLen == 0 ? "No Sales Assigned" : selectedOptionsLabelRes}`;
        } else {
            return `${selectedOptionsLen == 0 ? "No Sales Assigned" : selectedOptionsLabelRes}`;
        }
    }

    function onChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            this.setState(this.options);
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            this.setState([]);
        } else if (event.action === "deselect-option") {
            this.setState(value.filter((o) => o.value !== "*"));
            // } else if (value.length === this.options.length - 1) {
            //     this.setState(this.options);
        } else {
            this.setState(value);
        }


    }

    let selectedOptionsLen = selectedOptions.length

    let selectedOptionsLabelRes
    if (selectedOptionsLen > 0) {
        let selectedOptionsLabel = selectedOptions.map(function (item) {
            return " " + item['label'];
        });
        selectedOptionsLabelRes = selectedOptionsLabel.toString()
    }

    return (
        <ReactMultiSelectCheckboxes
            // options={[...allOptions]}
            options={[{ value: "*", label: "All" }, ...allOptions]}
            placeholderButtonLabel={selectedOptionsLabelRes ? selectedOptionsLabelRes : ""}
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedOptions}
            onChange={onChange}
            setState={setSelectedOptions}
            style={{ width: "inherit !important" }}
        />
    );
};

MultiSelectAllSales.propTypes = {

    options: PropTypes.array,
    handleClickMultiSelect: PropTypes.func
}

export default MultiSelectAllSales;