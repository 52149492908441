import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import PropTypes from 'prop-types'

function MultiSelectAll({ options, handleClickMultiSelect }) {
    var newPermissonArr = options


    let addPermissionLabel = []
    let newPermissonArrLen = newPermissonArr.length
    if (newPermissonArrLen !== 0) {
        newPermissonArr.map((newPermissonArrItem) => {

            let newPermissonArrObjToPush
            if (newPermissonArrItem == "Manage") {
                let newPermissonArrObj = {
                    id: 1,
                    value: "Manage",
                    isChecked: false,
                    label: "Manage"
                }
                newPermissonArrObjToPush = newPermissonArrObj
                // options.push(newPermissonArrObj)
            }
            if (newPermissonArrItem == "Create") {
                let newPermissonArrObj = {
                    id: 2,
                    value: "Create",
                    isChecked: false,
                    label: "Create"
                }

                newPermissonArrObjToPush = newPermissonArrObj
                // options.push(newPermissonArrObj)
            }
            if (newPermissonArrItem == "View") {
                let newPermissonArrObj = {
                    id: 3,
                    value: "View",
                    isChecked: false,
                    label: "View"
                }

                newPermissonArrObjToPush = newPermissonArrObj
                // options.push(newPermissonArrObj)
            }
            if (newPermissonArrItem == "Edit") {
                let newPermissonArrObj = {
                    id: 4,
                    value: "Edit",
                    isChecked: false,
                    label: "Edit"
                }
                newPermissonArrObjToPush = newPermissonArrObj
                // options.push(newPermissonArrObj)
            }
            if (newPermissonArrItem == "Delete") {
                let newPermissonArrObj = {
                    id: 5,
                    value: "Delete",
                    isChecked: false,
                    label: "Delete"
                }
                newPermissonArrObjToPush = newPermissonArrObj
                // options.push(newPermissonArrObj)
            }
            if (newPermissonArrItem == "Export") {
                let newPermissonArrObj = {
                    id: 6,
                    value: "Export",
                    isChecked: false,
                    label: "Export"
                }
                newPermissonArrObjToPush = newPermissonArrObj
                // options.push(newPermissonArrObj)
            }
            addPermissionLabel.push(newPermissonArrObjToPush)
        })
    }

    const [selectedOptions, setSelectedOptions] = useState([]);

    handleClickMultiSelect(selectedOptions)

    const allOptions = [
        { id: 1, value: "Manage", isChecked: false, label: "Manage" },
        { id: 2, value: "Create", isChecked: false, label: "Create" },
        { id: 3, value: "View", isChecked: false, label: "View" },
        { id: 4, value: "Edit", isChecked: false, label: "Edit" },
        { id: 5, value: "Delete", isChecked: false, label: "Delete" },
        { id: 6, value: "Export", isChecked: false, label: "Export" }
    ]


    useEffect(() => {
        setSelectedOptions([...addPermissionLabel]);
    }, []);

    function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: All`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} selected`;
        }
    }

    function onChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            this.setState(this.options);
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            this.setState([]);
        } else if (event.action === "deselect-option") {
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            this.setState(this.options);
        } else {
            this.setState(value);
        }


    }



    return (
        <ReactMultiSelectCheckboxes
            options={[{ value: "*", label: "All" }, ...allOptions]}
            placeholderButtonLabel="Permission"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedOptions}
            onChange={onChange}
            setState={setSelectedOptions}
            style={{ width: "100%" }}
        />
    );
};

MultiSelectAll.propTypes = {

    options: PropTypes.array,
    handleClickMultiSelect: PropTypes.func
}

export default MultiSelectAll;