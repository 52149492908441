import React from 'react'
import { useHistory, useLocation } from "react-router-dom";
import AddCredit from "./AddCredit";
import TopUpCredit from "./TopUpCredit";

function ShowCreateCredit() {
    const location = useLocation();
    console.log("location", typeof location.state)



    return (
        <div>
            {
                typeof location.state == "object" ? <TopUpCredit creditData={location.state} /> : <AddCredit />
            }
        </div>
    )
}

export default ShowCreateCredit