import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as moment from "moment"
import * as Yup from "yup"
import MetaTags from "react-meta-tags"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { withRouter, Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    FormFeedback,
    Label,
    Form,
} from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"

import { GETPROMOCODELIST } from "../../../queries/userQueries"
import { DELETEPROMO, CREATEPROMOCODE, UPDATEPROMOCODE } from "../../../mutations/useMutations"
import { useQuery, useMutation } from "@apollo/react-hooks"
import DeleteModal from "../../../components/Common/DeleteModal"
import { useForm } from "../../../utils/hooks";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EcommercePromoListModal from "./EcommercePromoListModal";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker"

export const PromoCodeList = () => {

    const history = useHistory();

    const [modal, setModal] = useState(false);
    const [promoCodeList, setPromoCodeList] = React.useState([]);
    const [modal1, setModal1] = useState(false);
    const [promoId, setPromoId] = useState("");
    const [promoToDelete, setPromoToDelete] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [promoToEdit, setPromoToEdit] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [userList, setUserList] = useState([]);


    const [addPromo] = useMutation(CREATEPROMOCODE, {
        update(_, { data: { createPromocode: promoData } }) {

            setTimeout(() => {
                window.location.reload();
            }, 0);

        },
        onError(err) {
            console.log(err.message);
        },
    });

    const [editPromo] = useMutation(UPDATEPROMOCODE, {
        update(_, { data: { updatePromocode: promoData } }) {

            setTimeout(() => {
                window.location.reload();
            }, 0);

        },
        onError(err) {
            console.log(err.message);
        },
    });

    function handleUpdatePromo(updatePromo) {

        console.log("updatePromo", updatePromo)
        editPromo(
            {
                variables: {

                    id: updatePromo?.id,
                    name: updatePromo?.name,
                    discount: parseInt(updatePromo?.discount),
                    promoCode: updatePromo?.promoCode,
                    expirationDate: updatePromo?.expirationDate,
                    startDate: updatePromo?.startDate,
                    percentage: parseInt(updatePromo?.percentage),
                    financialInstitute: parseInt(updatePromo?.financialInstitute),
                    remarks: updatePromo?.remarks,
                    type: updatePromo?.type,
                    status: updatePromo?.status,
                }

            }
        )
    }

    function handleAddPromo(addPromoData) {

        addPromo(
            {
                variables: {
                    name: addPromoData?.name,
                    promoCode: addPromoData?.promoCode,
                    startDate: startDate,
                    expirationDate: endDate,
                    type: addPromoData?.type,
                    discount: parseInt(addPromoData?.discount),
                    percentage: parseInt(addPromoData?.percentage),
                    remarks: addPromoData?.remarks,
                    financialInstitute: parseInt(addPromoData?.financialInstitute),
                    status: addPromoData?.status

                }

            }
        )
    }

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: (promoToEdit && promoToEdit?.name) || '',
            discount: (promoToEdit && promoToEdit?.discount) || '',
            promoCode: (promoToEdit && promoToEdit?.promoCode) || '',
            expirationDate: (promoToEdit && promoToEdit?.expirationDate) || '',
            startDate: (promoToEdit && promoToEdit?.startDate) || '',
            percentage: (promoToEdit && promoToEdit?.percentage) || '',
            financialInstitute: (promoToEdit && promoToEdit?.financialInstitute) || '',
            remarks: (promoToEdit && promoToEdit?.remarks) || '',
            type: (promoToEdit && promoToEdit?.type) || '',
            status: (promoToEdit && promoToEdit?.status) || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Promo Name"),
            promoCode: Yup.string().required("Please Enter Promo Code"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updatePromo = {
                    id: promoToEdit?.id,
                    name: values?.name,
                    discount: values?.discount,
                    promoCode: values?.promoCode,
                    expirationDate: endDate,
                    startDate: startDate,
                    percentage: values?.percentage,
                    financialInstitute: values?.financialInstitute,
                    remarks: values?.remarks,
                    type: values?.type,
                    status: values?.status,
                };

                handleUpdatePromo(updatePromo);
                validation.resetForm();
            }
            else {
                const addPromo = {
                    name: values["name"],
                    promoCode: values["promoCode"],
                    startDate: values["startDate"],
                    expirationDate: values["expirationDate"],
                    type: values["type"],
                    discount: values["discount"],
                    percentage: values["percentage"],
                    remarks: values["remarks"],
                    financialInstitute: values["financialInstitute"],
                    status: values["status"]
                };
                // save new order
                handleAddPromo(addPromo)
                validation.resetForm();
            }
            toggle();
        },
    });

    const [deletePromo] = useMutation(DELETEPROMO, {
        update(_, { data: { deletePromocode: promoData } }) {

            setTimeout(() => {
                window.location.reload();
            }, 0);
        },
        onError(err) {
            console.log(err.message);
        },
    });

    useEffect(() => {
        if (data) {
            const promocodeListRes = data?.getAllPromocode?.promocodeRes
            setPromoCodeList(promocodeListRes)
        }
    })

    const { loading, data } = useQuery(GETPROMOCODELIST)
    if (loading) {
        return "loading..."
    }

    const promoCodeLists = promoCodeList

    const selectRow = {
        mode: "checkbox",
    };

    const pageOptions = {
        sizePerPage: 10,
        totalSize: promoCodeLists.length, // replace later with size(orders),
        custom: true,
    };
    const { SearchBar } = Search;

    const toggleViewModal = () => setModal1(!modal1)


    const toggle = () => {
        if (modal) {
            setModal(false);
            // setUser(null);
        } else {
            setModal(true);
        }
    };


    const sendPromoId = (promoId) => {
        let promoToView = promoCodeLists?.filter(item => {
            if (item.id === promoId) {
                return item
            }
        })
        setPromoId(promoToView)
    }

    const onClickDelete = (promo) => {

        setPromoToDelete(promo)
        setDeleteModal(true);
    };


    const handleDeletePromo = () => {
        if (promoToDelete) {
            deletePromo({
                variables: {
                    id: promoToDelete.id,
                },
            });
            // onPaginationPageChange(1);
            setDeleteModal(false);
        }
    };

    const handlePromoClick = arg => {

        const promoData = arg;

        setPromoToEdit({
            id: promoData?.id,
            name: promoData?.name,
            promoCode: promoData?.promoCode,
            startDate: promoData?.startDate,
            expirationDate: promoData?.expirationDate,
            type: promoData?.type,
            discount: promoData?.discount,
            percentage: promoData?.percentage,
            remarks: promoData?.remarks,
            financialInstitute: promoData?.financialInstitute,
            status: promoData?.status,

        });

        setIsEdit(true);

        toggle();
    };

    const EcommerceUserColumns = [
        //   {
        //     dataField: 'id',
        //     text: 'Dealer Id',
        //     sort: true,
        //   },
        {
            dataField: 'name',
            text: 'Promo Name',
            sort: true
        }, {
            dataField: 'promoCode',
            text: 'Promo Code',
            sort: true
        },
        {
            dataField: 'startDate',
            text: 'Start Date',
            sort: true
        },
        {
            dataField: 'expirationDate',
            text: 'End Date',
            sort: true
        },
        {
            dataField: 'discount',
            text: 'Promo Discount',
            sort: true
        },
        {
            dataField: 'percentage',
            text: 'Percentage',
            sort: true
        },
        {
            dataField: 'remarks',
            text: 'Remarks',
            sort: true
        },
        {
            dataField: 'type',
            text: 'Promo Type',
            sort: true
        },
        {
            dataField: 'financialInstitute',
            text: 'Financial Institute',
            sort: true
        },
        {
            dataField: "view",
            isDummyField: true,
            text: "View Details",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <Button
                    type="button"
                    color="primary"
                    className="btn-sm btn-rounded"
                    onClick={() => {
                        sendPromoId(row.id);
                        toggleViewModal()
                    }

                    }
                >
                    View Details
                </Button >
            ),
        },
        {
            dataField: "action",
            isDummyField: true,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, promo) => (
                <>
                    <div className="d-flex gap-3">
                        <Link
                            to="#"
                            className="text-success"
                            onClick={() => handlePromoClick(promo)}
                        >
                            <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                            <UncontrolledTooltip placement="top" target="edittooltip">
                                Edit
                            </UncontrolledTooltip>
                        </Link>
                        <Link
                            to="#"
                            className="text-danger"
                            onClick={() => onClickDelete(promo)}
                        >
                            <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                            <UncontrolledTooltip placement="top" target="deletetooltip">
                                Delete
                            </UncontrolledTooltip>
                        </Link>
                    </div>
                </>
            ),
        },

    ];

    const handleChangeStartDate = date => {
        console.log("date", date.toISOString())
        setStartDate(date)
    }

    const handleChangeEndDate = date => {

        setEndDate(date)
    }


    //add User
    const handleCustomerClicks = () => {
        setPromoToEdit("")
        setIsEdit(false);
        toggle();
    };

    const defaultSorted = [
        {
            dataField: "orderId",
            order: "desc",
        },
    ];

    return (
        <React.Fragment>
            <ToastContainer />
            <EcommercePromoListModal isOpen={modal1} toggle={toggleViewModal} promoId={promoId} />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletePromo}
                onCloseClick={() => setDeleteModal(false)}
            />
            {
                loading ?
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> :
                    <div className="page-content">

                        <Container fluid>

                            <h2>Promo Code List</h2>
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField="id"
                                                columns={EcommerceUserColumns}
                                                data={promoCodeLists}
                                            >
                                                {

                                                    ({ paginationProps, paginationTableProps }) => (
                                                        <ToolkitProvider
                                                            keyField="id"
                                                            data={promoCodeLists}
                                                            columns={EcommerceUserColumns}
                                                            bootstrap4
                                                            search
                                                        >
                                                            {toolkitProps => (
                                                                <React.Fragment>
                                                                    <Row className="mb-2">
                                                                        <Col sm="4">
                                                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                                                <div className="position-relative">
                                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                                    <i className="bx bx-search-alt search-icon" />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col sm="8">
                                                                            <div className="text-sm-end">
                                                                                <Button
                                                                                    type="button"
                                                                                    color="success"
                                                                                    className="btn-rounded  mb-2 me-2"
                                                                                    onClick={handleCustomerClicks}
                                                                                >
                                                                                    <i className="mdi mdi-plus me-1" />
                                                                                    Add New Promo
                                                                                </Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl="12">
                                                                            <div className="table-responsive">
                                                                                <BootstrapTable
                                                                                    keyField="id"
                                                                                    responsive
                                                                                    bordered={false}
                                                                                    striped={false}
                                                                                    defaultSorted={defaultSorted}
                                                                                    // selectRow={selectRow}
                                                                                    classes={
                                                                                        "table align-middle table-nowrap table-check"
                                                                                    }
                                                                                    headerWrapperClasses={"table-light"}
                                                                                    {...toolkitProps.baseProps}
                                                                                    {...paginationTableProps}
                                                                                // ref={node}
                                                                                />
                                                                            </div>
                                                                            <Modal isOpen={modal} toggle={toggle}>
                                                                                <ModalHeader toggle={toggle} tag="h4">
                                                                                    {!!isEdit ? "Edit Promo" : "Add Promo"}
                                                                                </ModalHeader>
                                                                                <ModalBody>
                                                                                    <Form
                                                                                        onSubmit={(e) => {
                                                                                            e.preventDefault();
                                                                                            validation.handleSubmit();
                                                                                            return false;
                                                                                        }}
                                                                                    >
                                                                                        <Row form>
                                                                                            <Col className="col-12">
                                                                                                <div className="mb-3">
                                                                                                    <Label className="form-label">Promo Name</Label>
                                                                                                    <Input
                                                                                                        name="name"
                                                                                                        type="text"
                                                                                                        onChange={validation.handleChange}
                                                                                                        onBlur={validation.handleBlur}
                                                                                                        value={validation.values.name || ""}
                                                                                                        invalid={
                                                                                                            validation.touched.name && validation.errors.name ? true : false
                                                                                                        }
                                                                                                    />
                                                                                                    {validation.touched.name && validation.errors.name ? (
                                                                                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                                <div className="mb-3">
                                                                                                    <Label className="form-label">Promo Code</Label>
                                                                                                    <Input
                                                                                                        name="promoCode"
                                                                                                        type="text"
                                                                                                        validate={{
                                                                                                            required: { value: true },
                                                                                                        }}
                                                                                                        onChange={validation.handleChange}
                                                                                                        onBlur={validation.handleBlur}
                                                                                                        value={validation.values.promoCode || ""}
                                                                                                        invalid={
                                                                                                            validation.touched.promoCode && validation.errors.promoCode ? true : false
                                                                                                        }
                                                                                                    />
                                                                                                    {validation.touched.promoCode && validation.errors.promoCode ? (
                                                                                                        <FormFeedback type="invalid">{validation.errors.promoCode}</FormFeedback>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                                <div className="mb-3">
                                                                                                    <Label className="form-label">Start Date</Label>
                                                                                                    <DatePicker
                                                                                                        selected={startDate}
                                                                                                        onChange={handleChangeStartDate}
                                                                                                        className="form-control"
                                                                                                        placeholderText="Select date"

                                                                                                    />
                                                                                                    {validation.touched.startDate && validation.errors.startDate ? (
                                                                                                        <FormFeedback type="invalid">{validation.errors.startDate}</FormFeedback>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                                <div className="mb-3">
                                                                                                    <Label className="form-label">End Date</Label>
                                                                                                    <DatePicker
                                                                                                        selected={endDate}
                                                                                                        onChange={handleChangeEndDate}
                                                                                                        className="form-control"
                                                                                                        placeholderText="Select date"

                                                                                                    />
                                                                                                    {validation.touched.expirationDate && validation.errors.expirationDate ? (
                                                                                                        <FormFeedback type="invalid">{validation.errors.expirationDate}</FormFeedback>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                                <div className="mb-3">
                                                                                                    <Label className="form-label">Type</Label>
                                                                                                    <Input
                                                                                                        name="type"
                                                                                                        type="select"
                                                                                                        className="form-select"
                                                                                                        onChange={validation.handleChange}
                                                                                                        onBlur={validation.handleBlur}
                                                                                                        value={
                                                                                                            validation.values.type || ""
                                                                                                        }
                                                                                                        invalid={
                                                                                                            validation.touched.type && validation.errors.type ? true : false
                                                                                                        }
                                                                                                    >
                                                                                                        <option value="" disabled selected>Select Promo Type</option>

                                                                                                        <option key="" value="percentage" >Percentage</option>
                                                                                                        <option key="" value="absolute">Absolute</option>

                                                                                                    </Input>
                                                                                                </div>
                                                                                                {
                                                                                                    validation.values.type == "absolute" && (
                                                                                                        <div className="mb-3">
                                                                                                            <Label className="form-label">Promo Discount</Label>
                                                                                                            <Input
                                                                                                                name="discount"
                                                                                                                type="text"
                                                                                                                onChange={validation.handleChange}
                                                                                                                onBlur={validation.handleBlur}
                                                                                                                value={validation.values.discount || ""}
                                                                                                                invalid={
                                                                                                                    validation.touched.discount && validation.errors.discount ? true : false
                                                                                                                }
                                                                                                            />
                                                                                                            {validation.touched.discount && validation.errors.discount ? (
                                                                                                                <FormFeedback type="invalid">{validation.errors.discount}</FormFeedback>
                                                                                                            ) : null}
                                                                                                        </div>
                                                                                                    )}
                                                                                                {
                                                                                                    validation.values.type == "percentage" && (
                                                                                                        <div className="mb-3">
                                                                                                            <Label className="form-label">Percentage</Label>
                                                                                                            <Input
                                                                                                                name="percentage"
                                                                                                                type="text"
                                                                                                                onChange={validation.handleChange}
                                                                                                                onBlur={validation.handleBlur}
                                                                                                                value={validation.values.percentage || ""}
                                                                                                                invalid={
                                                                                                                    validation.touched.percentage && validation.errors.percentage ? true : false
                                                                                                                }
                                                                                                            />
                                                                                                            {validation.touched.percentage && validation.errors.percentage ? (
                                                                                                                <FormFeedback type="invalid">{validation.errors.percentage}</FormFeedback>
                                                                                                            ) : null}
                                                                                                        </div>
                                                                                                    )
                                                                                                }

                                                                                                <div className="mb-3">
                                                                                                    <Label className="form-label">Status</Label>
                                                                                                    <Input
                                                                                                        name="status"
                                                                                                        type="select"
                                                                                                        className="form-select"
                                                                                                        onChange={validation.handleChange}
                                                                                                        onBlur={validation.handleBlur}
                                                                                                        value={
                                                                                                            validation.values.status || ""
                                                                                                        }
                                                                                                    >
                                                                                                        <option value="" disabled selected>Select Status</option>

                                                                                                        <option key="" value="live">Live</option>
                                                                                                        <option key="" value="paused">Paused</option>

                                                                                                    </Input>
                                                                                                    {validation.touched.status && validation.errors.status ? (
                                                                                                        <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                                <div className="mb-3">
                                                                                                    <Label className="form-label">Remarks</Label>
                                                                                                    <Input
                                                                                                        name="remarks"
                                                                                                        type="text"
                                                                                                        onChange={validation.handleChange}
                                                                                                        onBlur={validation.handleBlur}
                                                                                                        value={validation.values.remarks || ""}
                                                                                                        invalid={
                                                                                                            validation.touched.remarks && validation.errors.remarks ? true : false
                                                                                                        }
                                                                                                    />
                                                                                                    {validation.touched.remarks && validation.errors.remarks ? (
                                                                                                        <FormFeedback type="invalid">{validation.errors.remarks}</FormFeedback>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                                <div className="mb-3">
                                                                                                    <Label className="form-label">Financial Institute</Label>
                                                                                                    <Input
                                                                                                        name="financialInstitute"
                                                                                                        type="text"
                                                                                                        onChange={validation.handleChange}
                                                                                                        onBlur={validation.handleBlur}
                                                                                                        value={validation.values.financialInstitute || ""}
                                                                                                        invalid={
                                                                                                            validation.touched.financialInstitute && validation.errors.financialInstitute ? true : false
                                                                                                        }
                                                                                                    />

                                                                                                </div>

                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <div className="text-end">
                                                                                                    <button
                                                                                                        type="submit"
                                                                                                        className="btn btn-success save-user"
                                                                                                    >
                                                                                                        Save
                                                                                                    </button>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Form>
                                                                                </ModalBody>
                                                                            </Modal>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="align-items-md-center mt-30">
                                                                        <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            )}
                                                        </ToolkitProvider>
                                                    )}
                                            </PaginationProvider>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
            }
        </React.Fragment>
    )
}
