import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'
import { Card, Container, CardBody, Col, Row } from 'reactstrap'
import './CreateOrder.css'
import DeleteModal from "../../../components/Common/DeleteModal"
import Select from 'react-select'
import { GETALLSEARCHDEALER, GETALLSTATES, GETALLBRANDS, GETALLMODELBYBRANDID } from "../../../queries/userQueries"
import { CREATPLANBYADMIN } from "../../../mutations/useMutations"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { useForm } from "../../../utils/hooks";
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';

export default function CreatePlan(props) {
    const history = useHistory();
    const [deleteModal, setDeleteModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    // Add Plan
    const [addPlanByAdmin] = useMutation(CREATPLANBYADMIN, {
        update(_, { data: { createQuotePlan: planData } }) {
            console.log("planData", planData)
            if (planData?.id) {
                setIsLoading(false)
                history.push("/plans");
            }
        },
        onError(err) {
            setIsLoading(false);
            console.log(err.message);
        },
    });

    const { onChange, onChangeMulti, onSubmit, values } = useForm(handleSubmit, {
      CoveredComponentsDetails:[],
      EWPCategory:"",
      CoveredComponents:"",
      NoOfCoveredComponents:"",
      CarType:"Private-use cars only",
      CarAge:"",
      OdometerReading:"up to 200000",
      CarBrandByCountry:"",
      MaxPerVisit:"",
      MaxPerYear:"",
      FinancialInstituitionsRate:"",
      DealersRate:"",
      RetailRate:"",
    });


    function handleAddUser() {
        setIsLoading(true);
        addPlanByAdmin(
            {
                variables: {
                    CoveredComponentsDetails:values.CoveredComponentsDetails,
                    EWPCategory:values.EWPCategory,
                    CoveredComponents:values.CoveredComponents,
                    NoOfCoveredComponents:values.NoOfCoveredComponents,
                    CarType:values.CarType,
                    CarAge:values.CarAge,
                    OdometerReading:values.OdometerReading,
                    CarBrandByCountry:values.CarBrandByCountry,
                    MaxPerVisit:values.MaxPerVisit,
                    MaxPerYear:values.MaxPerYear,
                    FinancialInstituitionsRate:values.FinancialInstituitionsRate,
                    DealersRate:values.DealersRate,
                    RetailRate:values.RetailRate,
                }
            }
        )
    }

    function handleSubmit() {
        //  console.log("hello")
    }

    const handleDeleteOrder = () => {
        setDeleteModal(true)
    };

    const onDetailChange = (evt)=>{
      const selectedOpts = [...evt.target.options].filter(o => o.selected).map(o => o.value) 
      const formated = [];
      selectedOpts.map((text)=>{
        let regex = /\[([^\][]*)]/g;
        let m = regex.exec(text);
        if(m?.[1]){
          let exists = formated.findIndex((ar)=>ar?.name===m[1]);
          if(exists===-1){
            formated.push({
              name:m[1],
              components:[
                {type:text.replace(m[0],"")}
              ]
            });
          }
          else{
            formated?.[exists]?.components.push({type:text.replace(m[0],"")});
          }
        }
      })
      onChangeMulti("CoveredComponentsDetails", selectedOpts);
      onChangeMulti("NoOfCoveredComponents", selectedOpts?.length?.toString());
    }


    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <h2>Create Plan</h2>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <div className="form-group">
                                        <label >EWP Category</label>
                                        <input type="text" className="form-control" placeholder="Name" name="EWPCategory" onChange={onChange} />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <label >Covered Components Title</label>
                                        <input type="text" className="form-control" name="CoveredComponents" placeholder={'Covered Components'} onChange={onChange} />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="form-group">
                                        <label >Covered Components</label>
                                        <select className="form-control" name="CoveredComponentsDetails" onChange={(e)=>onDetailChange(e)} multiple>
                                            <option value="">Select components</option>
                                            <option disabled className='bg-light' value="">Engine</option>
                                            <option value="[Engine]Engine Block" >Engine Block</option>
                                            <option value="[Engine]Cylinder Head" >Cylinder Head</option>
                                            <option value="[Engine]Crankshaft" >Crankshaft</option>
                                            <option value="[Engine]Eccentric Shaft" >Eccentric Shaft</option>
                                            <option value="[Engine]Connecting Rod" >Connecting Rod</option>
                                            <option value="[Engine]Piston" >Piston</option>
                                            <option value="[Engine]Piston Ring" >Piston Ring</option>
                                            <option value="[Engine]Variable Valve Timing" >Variable Valve Timing</option>
                                            <option value="[Engine]Camshaft Adjuster Solenoid" >Camshaft Adjuster Solenoid</option>
                                            <option value="[Engine]Balancer Shaft" >Balancer Shaft</option>
                                            <option value="[Engine]Intake Valve" >Intake Valve</option>
                                            <option value="[Engine]Exhaust Valve" >Exhaust Valve</option>
                                            <option value="[Engine]Intake Camshaft" >Intake Camshaft</option>
                                            <option value="[Engine]Exhaust Camshaft" >Exhaust Camshaft</option>
                                            <option value="[Engine]Valvetronic Motor" >Valvetronic Motor</option>
                                            <option value="[Engine]Oil Pump" >Oil Pump</option>
                                            <option value="[Engine]Emission Control Air Pump" >Emission Control Air Pump</option>
                                            <option value="[Engine]Air Mass Sensor (Air Flow Sensor)" >Air Mass Sensor (Air Flow Sensor)</option>
                                            <option value="[Engine]Engine Vacuum Pump" >Engine Vacuum Pump</option>
                                            <option value="[Engine]Position Sensor" >Position Sensor</option>

                                            <option disabled className='bg-light' value="">Transmission</option>
                                            <option value="[Transmission](Automatic or Manual) Clutch Temperature Sensor">(Automatic or Manual) Clutch Temperature Sensor</option>
                                            <option value="[Transmission]Transmission Control Module (TCM)">Transmission Control Module (TCM)</option>
                                            <option value="[Transmission]Transmission Oil Pump">Transmission Oil Pump</option>
                                            <option value="[Transmission]All Type of Solenoid Valve">All Type of Solenoid Valve</option>
                                            <option value="[Transmission]Torque Converter">Torque Converter</option>
                                            <option value="[Transmission]Transmission Speed Sensor">Transmission Speed Sensor</option>
                                            <option value="[Transmission]Gear Shaft">Gear Shaft</option>
                                            <option value="[Transmission]Gear Shifting / Selector Module">Gear Shifting / Selector Module</option>
                                            <option value="[Transmission]Valve Body">Valve Body</option>

                                            <option disabled className='bg-light' value="">Braking System</option>
                                            <option value="[Braking System]Brake Servo Unit">Brake Servo Unit</option>
                                            <option value="[Braking System]Calipers">Calipers</option>
                                            <option value="[Braking System]Wheels Cylinder">Wheels Cylinder</option>
                                            <option value="[Braking System]Brake Master Pump (Master Cylinder)">Brake Master Pump (Master Cylinder)</option>
                                            <option value="[Braking System]ABS Pump">ABS Pump</option>

                                            <option disabled className='bg-light' value="">ECU / ECM & TCM</option>
                                            <option value="[ECU / ECM & TCM]Engine Control Unit (ECU)/ Engine Control Module (ECM)">Engine Control Unit (ECU)/ Engine Control Module (ECM)</option>
                                            <option value="[ECU / ECM & TCM]Transmission Control Module (TCM)">Transmission Control Module (TCM)</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="form-group">
                                        <label >Car Type</label>
                                        <input type="text" className="form-control" name="carType" readOnly value={'Private-use cars only'} onChange={onChange} />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="form-group">
                                        <label >Car Age</label>
                                        <select className="form-control" name="CarAge" onChange={onChange}>
                                            <option value="">Select car age</option>
                                            <option value={'up to 10 years'}>up to 10 years</option>
                                            <option value={'11 to 16 years'}>11 to 16 years</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="form-group">
                                        <label>Odometer Reading</label>
                                        <input type="text" className="form-control" name="OdometerReading" readOnly value={'up to 200000'} onChange={onChange} />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="form-group">
                                        <label>Car Brand By Country</label>
                                        <select className="form-control" name="CarBrandByCountry" onChange={onChange}>
                                            <option value="">Select car brand by country</option>
                                            <option value={'China, Korea & Other Cars'}>China, Korea & Other Cars</option>
                                            <option value={'Continental Vehicle Model'}>Continental Vehicle Model</option>
                                            <option value={'Luxury Continental'}>Luxury Continental</option>
                                            <option value={'National & Japanese Vehicle Model'}>National & Japanese Vehicle Model</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="form-group">
                                        <label >Max. Per Visit</label>
                                        <input type="number" className="form-control" name="MaxPerVisit" placeholder={'Max. Per Visit'} onChange={onChange} />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="form-group">
                                        <label >Max. Per Year</label>
                                        <input type="number" className="form-control" name="MaxPerYear" placeholder={'Max. Per Year'} onChange={onChange} />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="form-group">
                                        <label >Dealers Rate</label>
                                        <input type="number" className="form-control" name="DealersRate" placeholder={'Dealers Rate'} onChange={onChange} />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="form-group">
                                        <label >Retail Rate</label>
                                        <input type="number" className="form-control" name="RetailRate" placeholder={'Retail Rate'} onChange={onChange} />
                                    </div>
                                </Col>
                                <div className='text-end'>
                                 { isLoading ? <button className="btn create-btn justify-content-center" onClick={handleAddUser} style={{ width:"111px", height:"40px"}}>                                 
                                                 <Spinner animation="border" variant="light" style={{ width: "25px", height: "25px", marginLeft: "10px" }} />
                                                </button>
                                :   <button className="btn create-btn" onClick={handleAddUser}>
                                        Create Plan
                                    </button>
                                }
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    )
}

CreatePlan.propTypes = {}

// export default CreateOrder;