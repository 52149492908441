import React from "react"
import PropTypes from "prop-types"
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table,
} from "reactstrap"


const EcommerceCreditListModal = props => {
    const { isOpen, toggle, dealerId } = props

    return (
        <React.Fragment>
            {
                dealerId ? (
                    <Modal
                        isOpen={isOpen}
                        role="dialog"
                        autoFocus={true}
                        centered={true}
                        className="exampleModal"
                        tabIndex="-1"
                        toggle={toggle}
                    >
                        <div className="modal-content">
                            <ModalHeader toggle={toggle}>Credit Details</ModalHeader>
                            <ModalBody>
                                <p className="mb-2">
                                    Dealer Id: <span className="text-primary">{dealerId[0].id}</span>
                                </p>
                                <p className="mb-2">
                                    Full Name: <span className="text-primary">{dealerId[0].name}</span>
                                </p>
                                <p className="mb-2">
                                    Mobile: <span className="text-primary">{dealerId[0].mobile}</span>
                                </p>
                                <p className="mb-2">
                                    Email: <span className="text-primary">{dealerId[0].email}</span>
                                </p>
                                <p className="mb-2">
                                    Amount: <span className="text-primary">{dealerId[0].amount}</span>
                                </p>
                                <p className="mb-2">
                                    Type: <span className="text-primary">{dealerId[0].type}</span>
                                </p>

                            </ModalBody>
                            <ModalFooter>
                                <Button type="button" color="secondary" onClick={toggle}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </div>
                    </Modal >) : (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )

            }
        </React.Fragment>
    )
}

EcommerceCreditListModal.propTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
    dealerId: PropTypes.string,
}

export default EcommerceCreditListModal
