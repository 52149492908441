import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as moment from "moment"
import * as Yup from "yup"
import MetaTags from "react-meta-tags"
import { CardTitle, CardSubtitle, UncontrolledTooltip } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { withRouter, Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap"
import { InputGroup } from "react-bootstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

import { useSelector, useDispatch } from "react-redux"

import { FETCHALLUSERS } from "../../../queries/userQueries"
import { DELETEUSER, UPDATEUSER, CREATEUSER } from "../../../mutations/useMutations"

import { useQuery, useMutation } from "@apollo/react-hooks"
import DeleteModal from "../../../components/Common/DeleteModal"
import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "store/e-commerce/actions"

import EcommerceUserListModal from "./EcommerceUserListModal"
import { useForm } from "../../../utils/hooks";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminUser = props => {

  const [modal, setModal] = useState(false);
  const [user, setUser] = React.useState([]);
  const [modal1, setModal1] = useState(false);
  const [userId, setUserId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const [userList, setUserList] = useState([]);


  const [updateUser] = useMutation(UPDATEUSER, {
    update(_, { data: { updateUser: userData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);

    },
    onError(err) {
      console.log(err.message);
    },
  });

  // Add User
  const [addUserByAdmin] = useMutation(CREATEUSER, {
    update(_, { data: { userCreateByAdmin: userData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);

    },
    onError(err) {
      toast(err.message);
    },
  });


  function handleupdateUser(updateOrder) {

    updateUser(
      {
        variables: {
          userId: updateOrder?.id,
          name: updateOrder?.name,
          email: updateOrder?.email,
          mobile: updateOrder?.mobile,
          role: updateOrder?.role,
          status: updateOrder?.status.toLowerCase(),
          nric: updateOrder?.nric,
          state: updateOrder?.state,
          updatedByuserId: updateOrder?.id,
        }

      }
    )
  }

  function handleAddUser(addUser) {

    addUserByAdmin(
      {
        variables: {
          name: addUser?.name,
          email: addUser?.email,
          mobile: addUser?.mobile,
          status: addUser?.status.toLowerCase(),
          nric: addUser?.nric,
          state: addUser?.state,
          // updatedByuserId: updateOrder?.id,
        }

      }
    )
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (userToEdit && userToEdit?.name) || '',
      email: (userToEdit && userToEdit?.email) || '',
      mobile: (userToEdit && userToEdit?.mobile) || '',
      nric: (userToEdit && userToEdit?.nric) || '',
      state: (userToEdit && userToEdit?.state) || '',
      status: (userToEdit && userToEdit?.status) || 'active',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter User Name"),
      email: Yup.string().required("Please Enter User Email"),
      mobile: Yup.string().required("Please Enter User Mobile Number"),
      status: Yup.string().required("Please Enter User Status"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          id: userToEdit?.id,
          name: values?.name,
          email: values?.email,
          mobile: values?.mobile,
          nric: values?.nric,
          state: values?.state,
          status: values?.status,
        };
        // update order
        // dispatch(onUpdateOrder(updateOrder));
        handleupdateUser(updateOrder);
        validation.resetForm();
      }
      else {
        const addUser = {
          name: values["name"],
          email: values["email"],
          mobile: values["mobile"],
          nric: values["nric"],
          state: values["state"],
          status: values["status"],
        };
        // save new order
        handleAddUser(addUser)
        validation.resetForm();
      }
      toggle();
    },
  });

  const [deleteUser] = useMutation(DELETEUSER, {
    update(_, { data: { deleteUser: userData } }) {


      setTimeout(() => {
        window.location.reload();
      }, 0);
    },
    onError(err) {
      console.log(err.message);
    },
  });

  useEffect(() => {
    if (data) {
      const userRes = data?.getAllUsers?.allUserRes
      setUser(userRes)
    }
  })

  const { loading, data } = useQuery(FETCHALLUSERS)
  if (loading) {
    return "loading..."
  }

  const users = user

  const selectRow = {
    mode: "checkbox",
  };

  const pageOptions = {
    sizePerPage: 10,
    totalSize: users.length, // replace later with size(orders),
    custom: true,
  };
  const { SearchBar } = Search;

  const toggleViewModal = () => setModal1(!modal1)

  const sendUserId = (userId) => {
    let userToView = users?.filter(item => {
      if (item.id === userId) {
        return item
      }
    })
    setUserId(userToView)
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      // setUser(null);
    } else {
      setModal(true);
    }
  };


  const handleOrderClick = arg => {

    const userData = arg;
    setUserToEdit({
      id: userData?.id,
      name: userData?.name,
      email: userData?.email,
      mobile: userData?.mobile,
      nric: userData?.nric,
      state: userData?.state,
      status: userData?.status,
    });

    setIsEdit(true);

    toggle();
  };

  //delete order

  const onClickDelete = (user) => {

    setUserToDelete(user)
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {

    if (userToDelete) {
      deleteUser({
        variables: {
          userId: userToDelete.id,
        },
      });
      // onPaginationPageChange(1);
      setDeleteModal(false);
    }
  };


  const EcommerceUserColumns = [{
    dataField: 'id',
    text: 'User Id',
    sort: true,
  }, {
    dataField: 'name',
    text: 'Full Name',
    sort: true
  }, {
    dataField: 'email',
    text: 'Email',
    sort: true
  }, {
    dataField: 'mobile',
    text: 'Mobile',
    sort: true
  }, {
    dataField: 'nric',
    text: 'NRIC Number',
    sort: true
  }, {
    dataField: 'state',
    text: 'State',
    sort: true
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className="font-size-12 badge-soft-"
        color={row.status === "inactive" ? "danger" : "success"}
        pill
      >
        {row.status}
      </Badge>
    ),
  },
  {
    dataField: "view",
    isDummyField: true,
    text: "View Details",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (
      <Button
        type="button"
        color="primary"
        className="btn-sm btn-rounded"
        onClick={() => {
          sendUserId(row.id);
          toggleViewModal()
        }

        }
      >
        View Details
      </Button >
    ),
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, user) => (
      <>
        <div className="d-flex gap-3">
          <Link
            to="#"
            className="text-success"
            onClick={() => handleOrderClick(user)}
          >
            <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            <UncontrolledTooltip placement="top" target="edittooltip">
              Edit
            </UncontrolledTooltip>
          </Link>
          <Link
            to="#"
            className="text-danger"
            onClick={() => onClickDelete(user)}
          >
            <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            <UncontrolledTooltip placement="top" target="deletetooltip">
              Delete
            </UncontrolledTooltip>
          </Link>
        </div>
      </>
    ),
  },
  ];


  //add User
  const handleCustomerClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];



  return (
    <React.Fragment>
      <ToastContainer />
      <EcommerceUserListModal isOpen={modal1} toggle={toggleViewModal} userId={userId} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      {
        loading ?
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div> :
          <div className="page-content">

            <Container fluid>
              {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
              <h2>Admin User List</h2>
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={EcommerceUserColumns}
                        data={users}
                      >
                        {

                          ({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              data={users}
                              columns={EcommerceUserColumns}
                              bootstrap4
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col sm="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar {...toolkitProps.searchProps} />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm="8">
                                      <div className="text-sm-end">
                                        <Button
                                          type="button"
                                          color="success"
                                          className="btn-rounded  mb-2 me-2"
                                          onClick={handleCustomerClicks}
                                        >
                                          <i className="mdi mdi-plus me-1" />
                                          Add New User
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField="id"
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          // selectRow={selectRow}
                                          classes={
                                            "table align-middle table-nowrap table-check"
                                          }
                                          headerWrapperClasses={"table-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        // ref={node}
                                        />
                                      </div>
                                      <Modal isOpen={modal} toggle={toggle}>
                                        <ModalHeader toggle={toggle} tag="h4">
                                          {!!isEdit ? "Edit User" : "Add User"}
                                        </ModalHeader>
                                        <ModalBody>
                                          <Form
                                            onSubmit={(e) => {
                                              e.preventDefault();
                                              validation.handleSubmit();
                                              return false;
                                            }}
                                          >
                                            <Row form>
                                              <Col className="col-12">
                                                <div className="mb-3">
                                                  <Label className="form-label">Name</Label>
                                                  <Input
                                                    name="name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.name || ""}
                                                    invalid={
                                                      validation.touched.name && validation.errors.name ? true : false
                                                    }
                                                  />
                                                  {validation.touched.name && validation.errors.name ? (
                                                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">Email</Label>
                                                  <Input
                                                    name="email"
                                                    type="text"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.email || ""}
                                                    invalid={
                                                      validation.touched.email && validation.errors.email ? true : false
                                                    }
                                                  />
                                                  {validation.touched.email && validation.errors.email ? (
                                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">Mobile</Label>
                                                  <Input
                                                    name="mobile"
                                                    type="text"
                                                    // value={orderList.orderdate || ""}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.mobile || ""}
                                                    invalid={
                                                      validation.touched.mobile && validation.errors.mobile ? true : false
                                                    }
                                                  />
                                                  {validation.touched.mobile && validation.errors.mobile ? (
                                                    <FormFeedback type="invalid">{validation.errors.mobile}</FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">NRIC</Label>
                                                  <Input
                                                    name="nric"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.nric || ""}
                                                    invalid={
                                                      validation.touched.nric && validation.errors.nric ? true : false
                                                    }
                                                  />
                                                  {validation.touched.nric && validation.errors.nric ? (
                                                    <FormFeedback type="invalid">{validation.errors.nric}</FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">Status</Label>
                                                  <Input
                                                    name="status"
                                                    type="select"
                                                    className="form-select"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={
                                                      validation.values.status || ""
                                                    }
                                                  >
                                                    <option>Active</option>
                                                    <option>Inactive</option>

                                                  </Input>
                                                  {validation.touched.status && validation.errors.status ? (
                                                    <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">State</Label>
                                                  <Input
                                                    name="state"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.state || ""}
                                                    invalid={
                                                      validation.touched.state && validation.errors.state ? true : false
                                                    }
                                                  />
                                                  {validation.touched.state && validation.errors.state ? (
                                                    <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                                  ) : null}
                                                </div>

                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <div className="text-end">
                                                  <button
                                                    type="submit"
                                                    className="btn btn-success save-user"
                                                  >
                                                    Save
                                                  </button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </Form>
                                        </ModalBody>
                                      </Modal>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
      }
    </React.Fragment>
    // <React.Fragment>
    //   <div className="page-content">
    //   {status === true && (
    //       <div>
    //         <Row className="popup justify-content-center ">



    //           <Col md={10} >

    //           <Row className="mt-5">
    //               <Col>
    //                 {" "}
    //                 <h1
    //                   style={{
    //                     fontWeight: "bold",
    //                     marginBottom: "10px",
    //                     fontSize: "24px",
    //                   }}
    //                 >
    //                   User Details
    //                 </h1>
    //               </Col>
    //               <Col  className=" justify-content-end  text-end">
    //                 <button
    //                   style={{
    //                     color: "white",
    //                     background: "red",
    //                     border: "none",
    //                     padding: "10px",
    //                   }}
    //                   onClick={() => setStatus(!status)}
    //                 >
    //                   Close
    //                 </button>
    //               </Col>
    //             </Row>
    //             {curr === null ? null : (
    //               <table style={{ width: "100%" ,marginTop:"50px" }}>






    //                 <tr>
    //                   <th style={{ fontWeight: "normal" }}>Id</th>
    //                   <td style={{ fontWeight: "bold" }}>
    //                     : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].id}
    //                   </td>
    //                 </tr>
    //                 <tr>
    //                   <th style={{ fontWeight: "normal" }}>User Name</th>
    //                   <td style={{ fontWeight: "bold" }}>
    //                     : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].name}
    //                   </td>
    //                 </tr>
    //                 <tr>
    //                   <th style={{ fontWeight: "normal" }}>Email</th>
    //                   <td style={{ fontWeight: "bold" }}>
    //                     : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].email}
    //                   </td>
    //                 </tr>
    //                 <tr>
    //                   <th  style={{ fontWeight: "normal" }}>Mobile</th>
    //                   <td style={{ fontWeight: "bold" }}>
    //                     : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].mobile}
    //                   </td>
    //                 </tr>
    //                 <tr>
    //                   <th style={{ fontWeight: "normal" }}>Nric Number</th>
    //                   <td style={{ fontWeight: "bold" }}>
    //                     : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].nric}
    //                   </td>
    //                 </tr>
    //                 <tr>
    //                   <th style={{ fontWeight: "normal" }}>State</th>
    //                   <td style={{ fontWeight: "bold" }}>
    //                     : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].state}
    //                   </td>
    //                 </tr>




    //               </table>
    //             )}
    //           </Col>
    //         </Row>
    //       </div>
    //     )}
    //     {
    //       loading ? 
    //       <div className="d-flex justify-content-center">
    //           <div className="spinner-border text-primary" role="status">
    //             <span className="sr-only">Loading...</span>
    //           </div>
    //         </div> : 

    //     <div className="container-fluid">
    //       <h2>User List</h2>
    //      <Col md={3}>
    //      <Input  onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search..."/>
    //      </Col>
    //       <Row className="mt-2">
    //         <Col>
    //           <Card>
    //             <CardBody>
    //               <div className="table-rep-plugin">
    //                 <div
    //                   className="table-responsive mb-0"
    //                   data-pattern="priority-columns"
    //                 >
    //                   <Table
    //                     id="tech-companies-1"
    //                     className="table table-striped table-bordered"
    //                   >
    //                     <Thead>
    //                       <Tr>
    //                         <Th data-priority="1">Id</Th>
    //                         <Th data-priority="2">Full Name</Th>
    //                         <Th data-priority="3">Email</Th>
    //                         <Th data-priority="4">Mobile</Th>
    //                         <Th data-priority="5">NRIC Number</Th>
    //                         <Th data-priority="6">State</Th>
    //                         <Th data-priority="9"> Action </Th>
    //                       </Tr>
    //                     </Thead>
    //                     <Tbody>

    //                       {orders?.filter((val)=>{
    //                 if(searchTerm===''){
    //                   return val
    //                 }
    //                 else if(
    //                   val.id?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
    //                   val.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
    //                   val.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
    //                   val.mobile?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
    //                   val.nric?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
    //                   val.state?.toLowerCase().includes(searchTerm?.toLowerCase())
    //                 ){
    //                   return val;
    //                 }
    //                 }
    //                 ).map((p, ind) => (
    //                         <Tr key={ind}>
    //                           <Td>{p.id}</Td>
    //                           <Td>{p.name}</Td>
    //                           <Td>{p.email}</Td>
    //                           <Td>{p.mobile}</Td>
    //                           <Td>{p.nric}</Td>
    //                           <Td>{p.state}</Td>
    //                           <Td
    //                               style={{
    //                                 fontWeight: "bold",
    //                                 cursor: "pointer",
    //                               }}
    //                               onClick={() => handleDetails(p.id)}
    //                             >
    //                               {" "}
    //                               View Details
    //                             </Td>
    //                         </Tr>
    //                       ))}
    //                     </Tbody>
    //                   </Table>
    //                 </div>
    //               </div>
    //             </CardBody>
    //           </Card>
    //         </Col>
    //       </Row>
    //     </div>
    //     }
    //   </div>
    // </React.Fragment>

  )
}

AdminUser.propTypes = {
  customers: PropTypes.array,
  onGetCustomers: PropTypes.func,
  onAddNewCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func,
}

export default AdminUser
