import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from 'prop-types'
import { Card, Container, CardBody, Col, Row } from 'reactstrap'
import '../Create Order/CreateOrder.css'
import Select from 'react-select'
import { ADDCREDIT, GETCURRENTDEALERCREDIT } from "../../../mutations/useMutations"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { useForm } from "../../../utils/hooks";
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import {
    Label,
} from "reactstrap"

const TopUpCredit = props => {
    console.log("props", props)

    const location = useLocation();
    console.log("location", location.state)

    const history = useHistory();
    const [dealer, setDealer] = React.useState([]);
    const [currentCreditvalue, setCurrentCreditvalue] = useState("");
    const [dealerId, setDealerId] = React.useState("");

    const [isLoading, setIsLoading] = useState(false);

    // Add Credit
    const [addCreditByAdmin] = useMutation(ADDCREDIT, {
        update(_, { data: { addCredit: creditData } }) {

            if (creditData?.error == false) {
                window.location.href="/credit-list";
                // history.push("/credit-list");
            }


        },
        onError(err) {
            console.log(err.message);
        },
    });

    const [getDealerCurrentCredit] = useMutation(GETCURRENTDEALERCREDIT, {
        update(_, { data: { getDealerCurrentCredit: currentCreditData } }) {

            if (currentCreditData) {
                setCurrentCreditvalue(currentCreditData.amount)
            }


        },
        onError(err) {
            console.log(err.message);
        },
    });



    const { onChange, onSubmit, values } = useForm(handleSubmit, {
        amount: 0,
        remark: "",
        name: "",
    });


    function handleAddCredit() {
        setIsLoading(true);
        addCreditByAdmin(
            {
                variables: {
                    amount: parseFloat(values.amount),
                    remark: values.remark,
                    dealerId: props.creditData[0].id,
                }

            }
        )
    }




    function handleSubmit() {
        //  console.log("hello")
    }



    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <h2>Top Up Credit</h2>
                        <Card >
                            <CardBody>
                                <Row >
                                    <Col md={4}>

                                        <div className="form-group">
                                            <label>Dealer</label>
                                            <input type="text" className="form-control" placeholder="name" name="name" onChange={onChange} value={props.creditData[0].name} disabled />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <form>
                                            <div className="form-group">
                                                <label >Amount</label>
                                                <input type="number" className="form-control" placeholder="Amount" name="amount" onChange={onChange} required />
                                            </div>
                                        </form>
                                    </Col >
                                    <Col md={4}>
                                        <form>
                                            <div className="form-group">
                                                <label >Remark</label>
                                                <input type="text" className="form-control" placeholder="remark" name="remark" onChange={onChange} />
                                            </div>
                                        </form>

                                    </Col>
                                    <Row className='d-flex justify-content-between'>
                                        <Col md="4">
                                            <div>
                                                <Label className="form-label">Current Credit Amount: <b>{props.creditData[0].amount}</b></Label>
                                            </div>
                                        </Col>

                                        <Col md="4">
                                            <div className='text-end'>
                                                <button className="btn create-btn" onClick={handleAddCredit}>
                                                    Top Up Credit
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>


                                </Row>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment >
        </>
    )
}

TopUpCredit.propTypes = {
    creditData: PropTypes.array
}

export default TopUpCredit