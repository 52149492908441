import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as moment from "moment"
import * as Yup from "yup"
import MetaTags from "react-meta-tags"
import { CardTitle, CardSubtitle, UncontrolledTooltip } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { withRouter, Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalHeader,
    ModalBody,
    UncontrolledDropdown,
    Input,
    FormFeedback,
    Label,
    Form,
} from "reactstrap"
import { InputGroup } from "react-bootstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import MultiSelectAllManager from "../EcommerceAdmins/MultiSelectAllManager"
import MultiSelectAllDealer from "../EcommerceAdmins/MultiSelectAllDealer"
import MultiSelectAllSales from "../EcommerceAdmins/MultiSelectAllSales"

import { useSelector, useDispatch } from "react-redux"

import { GETALLSEARCHDEALER, FETCHALLSALESFORMANAGER } from "../../../queries/userQueries"
import { DELETEADMIN, UPDATEADMIN, CREATEADMIN } from "../../../mutations/useMutations"

import { useQuery, useMutation } from "@apollo/react-hooks"
import DeleteModal from "../../../components/Common/DeleteModal"
import {
    getCustomers as onGetCustomers,
    addNewCustomer as onAddNewCustomer,
    updateCustomer as onUpdateCustomer,
    deleteCustomer as onDeleteCustomer,
} from "store/e-commerce/actions"

import EcommerceSalesListModal from "./EcommerceSalesListModal"
import { useForm } from "../../../utils/hooks";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMemo } from "react"

const SalesList = props => {

    const [modal, setModal] = useState(false);
    const [admin, setAdmin] = React.useState([]);
    const [modal1, setModal1] = useState(false);
    const [adminId, setAdminId] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [adminToDelete, setAdminToDelete] = useState(null);
    const [adminToEdit, setAdminToEdit] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const [roleForUser, setRole] = useState("");

    const [adminList, setAdminList] = useState([]);

    const [managerToSendMultiSelectAll, setManagerToSendMultiSelectAll] = useState([]);
    const [salesToSendMultiSelectAll, setSalesToSendMultiSelectAll] = useState([]);
    const [dealerToSendMultiSelectAll, setDealerToSendMultiSelectAll] = useState([]);

    const [updatedManager, setUpdatedManagar] = useState([]);
    const [updatedDealer, setUpdatedDealer] = useState([]);
    const [updatedSales, setUpdatedSales] = useState([]);

    const [updateAdmin] = useMutation(UPDATEADMIN, {
        update(_, { data: { updateAdmin: adminData } }) {

            setTimeout(() => {
                window.location.reload();
            }, 0);

        },
        onError(err) {
            console.log(err.message);
        },
    });

    // Add Admin
    const [addAdminByAdmin] = useMutation(CREATEADMIN, {
        update(_, { data: { userCreateByAdminOnAdminDashboard: adminData } }) {

            setTimeout(() => {
                window.location.reload();
            }, 0);

        },
        onError(err) {
            toast(err.message);
        },
    });


    function handleupdateAdmin(updateOrder) {

        let addAdminByAdminVarObj = {
            variables: {
                adminId: updateOrder?.id,
                name: updateOrder?.name,
                email: updateOrder?.email,
                mobile: updateOrder?.mobile,
                role: updateOrder?.role.toLowerCase(),
                status: updateOrder?.status.toLowerCase(),
                password: updateOrder?.password,
                state: updateOrder?.state,
                updatedByadminId: userId,
            }

        }

        let updatedManagerLen = updatedManager.length
        let updatedDealerLen = updatedDealer.length
        let updatedSalesLen = updatedSales.length

        if (updatedManagerLen >= 0) {
            let managerArr = []
            updatedManager.map((updatedManagerItem) => {

                if (updatedManagerItem.value !== "*") {
                    let managerObj = {
                        managerId: updatedManagerItem.value,
                        managerName: updatedManagerItem.label
                    }
                    managerArr.push(managerObj)
                }
            })

            addAdminByAdminVarObj.variables.assignedManager = managerArr
        }

        if (updatedDealerLen >= 0) {
            let dealerArr = []
            updatedDealer.map((updatedDealerItem) => {

                if (updatedDealerItem.value !== "*") {
                    let dealerObj = {
                        dealerId: updatedDealerItem.value,
                        dealerName: updatedDealerItem.label
                    }
                    dealerArr.push(dealerObj)
                }
            })

            addAdminByAdminVarObj.variables.assignedDealer = dealerArr
        }

        if (updatedSalesLen >= 0) {
            let salesArr = []
            updatedSales.map((updatedSalesItem) => {

                if (updatedSalesItem.value !== "*") {
                    let salesObj = {
                        salesId: updatedSalesItem.value,
                        salesName: updatedSalesItem.label
                    }
                    salesArr.push(salesObj)
                }
            })

            addAdminByAdminVarObj.variables.assignedSales = salesArr
        }

        updateAdmin(addAdminByAdminVarObj)
    }

    function handleAddAdmin(addAdmin) {

        let addAdminByAdminVarObj = {
            variables: {
                name: addAdmin?.name,
                email: addAdmin?.email,
                mobile: addAdmin?.mobile,
                status: addAdmin?.status.toLowerCase(),
                password: addAdmin?.password,
                state: addAdmin?.state,
                role: addAdmin?.role.toLowerCase(),
                createdBy: userId,
            }

        }

        let updatedManagerLen = updatedManager.length
        let updatedDealerLen = updatedDealer.length
        let updatedSalesLen = updatedSales.length

        if (updatedManagerLen > 0) {
            let managerArr = []
            updatedManager.map((updatedManagerItem) => {
                if (updatedManagerItem.value !== "*") {
                    let managerObj = {
                        managerId: updatedManagerItem.value,
                        managerName: updatedManagerItem.label
                    }
                    managerArr.push(managerObj)
                }
            })

            addAdminByAdminVarObj.variables.assignedManager = managerArr
        }

        if (updatedDealerLen > 0) {
            let dealerArr = []
            updatedDealer.map((updatedDealerItem) => {
                if (updatedDealerItem.value !== "*") {
                    let dealerObj = {
                        dealerId: updatedDealerItem.value,
                        dealerName: updatedDealerItem.label
                    }
                    dealerArr.push(dealerObj)
                }
            })

            addAdminByAdminVarObj.variables.assignedDealer = dealerArr
        }

        if (updatedSalesLen > 0) {
            let salesArr = []
            updatedSales.map((updatedSalesItem) => {
                if (updatedSalesItem.value !== "*") {
                    let salesObj = {
                        salesId: updatedSalesItem.value,
                        salesName: updatedSalesItem.label
                    }
                    salesArr.push(salesObj)
                }
            })

            addAdminByAdminVarObj.variables.assignedSales = salesArr
        }

        addAdminByAdmin(addAdminByAdminVarObj)
    }

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: (adminToEdit && adminToEdit?.name) || '',
            email: (adminToEdit && adminToEdit?.email) || '',
            mobile: (adminToEdit && adminToEdit?.mobile) || '',
            password: (adminToEdit && adminToEdit?.password) || '',
            state: (adminToEdit && adminToEdit?.state) || '',
            status: (adminToEdit && adminToEdit?.status) || 'active',
            role: (adminToEdit && adminToEdit?.role) || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            email: Yup.string().required("Please Enter Email"),
            mobile: Yup.string().required("Please Enter Mobile Number"),
            status: Yup.string().required("Please Enter Status"),
        }),
        onSubmit: (values) => {
            if (isEdit) {

                const updateAdmin = {
                    id: adminToEdit?.id,
                    name: values?.name,
                    email: values?.email,
                    mobile: values?.mobile,
                    password: values?.password,
                    state: values?.state,
                    status: values?.status,
                    role: roleForUser
                };
                // update order
                // dispatch(onUpdateOrder(updateOrder));
                handleupdateAdmin(updateAdmin);
                validation.resetForm();
            }
            else {
                const addAdmin = {
                    name: values["name"],
                    email: values["email"],
                    mobile: values["mobile"],
                    password: values["password"],
                    state: values["state"],
                    status: values["status"],
                    role: roleForUser
                };
                // save new order
                handleAddAdmin(addAdmin)
                validation.resetForm();
            }
            toggle();
        },
    });

    const admins = admin

    const [deleteAdmin] = useMutation(DELETEADMIN, {
        update(_, { data: { deleteAdmin: adminData } }) {


            setTimeout(() => {
                window.location.reload();
            }, 0);
        },
        onError(err) {
            console.log(err.message);
        },
    });

    useEffect(() => {
        if (data && type == "manager") {
            const adminRes = data?.getAllSalesForManager?.allAdminRes
            setAdmin(adminRes)
        }
    })

    // const { loading, data } = useQuery(FETCHALLADMINS)


    let userId = localStorage.getItem("userId")
    let role = localStorage.getItem("role")

    let data, loading, type
    if (role == "manager") {
        const { loading: managerLoading, data: managerData } = useQuery(FETCHALLSALESFORMANAGER, {
            variables: { managerId: userId }
        });
        data = managerData
        loading = managerLoading
        type = "manager"
    }



    const { loading: searchDealer, data: searchDealerData } = useQuery(GETALLSEARCHDEALER)

    if (loading) {
        return "loading..."
    }

    const selectRow = {
        mode: "checkbox",
    };

    const pageOptions = {
        sizePerPage: 10,
        totalSize: admins.length, // replace later with size(orders),
        custom: true,
    };
    const { SearchBar } = Search;

    const toggleViewModal = () => setModal1(!modal1)

    const sendAdminId = (adminId) => {
        let adminToView = admins?.filter(item => {
            if (item.id === adminId) {
                return item
            }
        })
        setAdminId(adminToView)
    }

    const toggle = () => {
        if (modal) {
            setModal(false);
            // setAdmin(null);
        } else {
            setModal(true);
        }
    };


    const handleOrderClick = arg => {
        const adminData = arg;
        setRole(adminData?.role)
        setManagerToSendMultiSelectAll(adminData?.assignedManager)
        setSalesToSendMultiSelectAll(adminData?.assignedSales)
        setDealerToSendMultiSelectAll(adminData?.assignedDealer)
        setAdminToEdit({
            id: adminData?.id,
            name: adminData?.name,
            email: adminData?.email,
            mobile: adminData?.mobile,
            password: adminData?.password,
            state: adminData?.state,
            status: adminData?.status,
            role: adminData?.role,
        });

        setIsEdit(true);

        toggle();
    };

    //delete order

    const onClickDelete = (admin) => {

        setAdminToDelete(admin)
        setDeleteModal(true);
    };

    const handleDeleteOrder = () => {

        if (adminToDelete) {
            deleteAdmin({
                variables: {
                    adminId: adminToDelete.id,
                },
            });
            // onPaginationPageChange(1);
            setDeleteModal(false);
        }
    };


    const EcommerceAdminColumns = [
        {
            dataField: 'name',
            text: 'Full Name',
            sort: true
        }, {
            dataField: 'email',
            text: 'Email',
            sort: true
        }, {
            dataField: 'mobile',
            text: 'Mobile',
            sort: true
        },
        {
            dataField: 'role',
            text: 'Role',
            sort: true
        },
        {
            dataField: 'state',
            text: 'State',
            sort: true
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: (cellContent, row) => (
                <Badge
                    className="font-size-12 badge-soft-"
                    color={row.status === "inactive" ? "danger" : "success"}
                    pill
                >
                    {row.status}
                </Badge>
            ),
        },
        {
            dataField: "view",
            isDummyField: true,
            text: "View Details",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <Button
                    type="button"
                    color="primary"
                    className="btn-sm btn-rounded"
                    onClick={() => {
                        sendAdminId(row.id);
                        toggleViewModal()
                    }

                    }
                >
                    View Details
                </Button >
            ),
        },
        {
            dataField: "action",
            isDummyField: true,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, admin) => (
                <>
                    <div className="d-flex gap-3">
                        <Link
                            to="#"
                            className="text-success"
                            onClick={() => handleOrderClick(admin)}
                        >
                            <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                            <UncontrolledTooltip placement="top" target="edittooltip">
                                Edit
                            </UncontrolledTooltip>
                        </Link>
                        {/* <Link
                            to="#"
                            className="text-danger"
                            onClick={() => onClickDelete(admin)}
                        >
                            <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                            <UncontrolledTooltip placement="top" target="deletetooltip">
                                Delete
                            </UncontrolledTooltip>
                        </Link> */}
                    </div>
                </>
            ),
        },
    ];


    //add Admin
    const handleCustomerClicks = () => {
        // setAdminList("");
        setAdminToEdit("")
        setRole("")
        setManagerToSendMultiSelectAll([]);
        setSalesToSendMultiSelectAll([]);
        setDealerToSendMultiSelectAll([]);
        setIsEdit(false);
        toggle();
    };

    const defaultSorted = [
        {
            dataField: "orderId",
            order: "desc",
        },
    ];

    const handleClickManager = (ManagerArrItem) => {
        setUpdatedManagar(ManagerArrItem)
    }

    const handleClickDealer = (DealerArrItem) => {
        setUpdatedDealer(DealerArrItem)
    }

    const handleClickSales = (SalesArrItem) => {
        setUpdatedSales(SalesArrItem)
    }

    const handleChangeRole = (e) => {
        setRole(e)
    }


    return (
        <React.Fragment>
            <ToastContainer />
            <EcommerceSalesListModal isOpen={modal1} toggle={toggleViewModal} adminId={adminId} />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            {
                loading ?
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> :
                    <div className="page-content">

                        <Container fluid>
                            {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
                            <h2>Sales List</h2>
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField="id"
                                                columns={EcommerceAdminColumns}
                                                data={admins}
                                            >
                                                {

                                                    ({ paginationProps, paginationTableProps }) => (
                                                        <ToolkitProvider
                                                            keyField="id"
                                                            data={admins}
                                                            columns={EcommerceAdminColumns}
                                                            bootstrap4
                                                            search
                                                        >
                                                            {toolkitProps => (
                                                                <React.Fragment>
                                                                    <Row className="mb-2">
                                                                        <Col sm="4">
                                                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                                                <div className="position-relative">
                                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                                    <i className="bx bx-search-alt search-icon" />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        {role == "sales" || role == "manager" ? "" : (<Col sm="8">
                                                                            <div className="text-sm-end">
                                                                                <Button
                                                                                    type="button"
                                                                                    color="success"
                                                                                    className="btn-rounded  mb-2 me-2"
                                                                                    onClick={handleCustomerClicks}
                                                                                >
                                                                                    <i className="mdi mdi-plus me-1" />
                                                                                    Add New Role
                                                                                </Button>
                                                                            </div>
                                                                        </Col>)}

                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl="12">
                                                                            <div className="table-responsive">
                                                                                <BootstrapTable
                                                                                    keyField="id"
                                                                                    responsive
                                                                                    bordered={false}
                                                                                    striped={false}
                                                                                    defaultSorted={defaultSorted}
                                                                                    // selectRow={selectRow}
                                                                                    classes={
                                                                                        "table align-middle table-nowrap table-check"
                                                                                    }
                                                                                    headerWrapperClasses={"table-light"}
                                                                                    {...toolkitProps.baseProps}
                                                                                    {...paginationTableProps}
                                                                                // ref={node}
                                                                                />
                                                                            </div>
                                                                            <Modal isOpen={modal} toggle={toggle}>
                                                                                <ModalHeader toggle={toggle} tag="h4">
                                                                                    {!!isEdit ? "Edit Admin" : "Add Admin"}
                                                                                </ModalHeader>
                                                                                <ModalBody>
                                                                                    <Form
                                                                                        onSubmit={(e) => {
                                                                                            e.preventDefault();
                                                                                            validation.handleSubmit();
                                                                                            return false;
                                                                                        }}
                                                                                    >
                                                                                        <Row form>
                                                                                            <Col className="col-12">
                                                                                                <div className="mb-3">
                                                                                                    <Label className="form-label">Name</Label>
                                                                                                    <Input
                                                                                                        name="name"
                                                                                                        type="text"
                                                                                                        onChange={validation.handleChange}
                                                                                                        onBlur={validation.handleBlur}
                                                                                                        value={validation.values.name || ""}
                                                                                                        invalid={
                                                                                                            validation.touched.name && validation.errors.name ? true : false
                                                                                                        }
                                                                                                    />
                                                                                                    {validation.touched.name && validation.errors.name ? (
                                                                                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                                <div className="mb-3">
                                                                                                    <Label className="form-label">Email</Label>
                                                                                                    <Input
                                                                                                        name="email"
                                                                                                        type="text"
                                                                                                        validate={{
                                                                                                            required: { value: true },
                                                                                                        }}
                                                                                                        onChange={validation.handleChange}
                                                                                                        onBlur={validation.handleBlur}
                                                                                                        value={validation.values.email || ""}
                                                                                                        invalid={
                                                                                                            validation.touched.email && validation.errors.email ? true : false
                                                                                                        }
                                                                                                    />
                                                                                                    {validation.touched.email && validation.errors.email ? (
                                                                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                                <div className="mb-3">
                                                                                                    <Label className="form-label">Mobile</Label>
                                                                                                    <Input
                                                                                                        name="mobile"
                                                                                                        type="text"
                                                                                                        // value={orderList.orderdate || ""}
                                                                                                        onChange={validation.handleChange}
                                                                                                        onBlur={validation.handleBlur}
                                                                                                        value={validation.values.mobile || ""}
                                                                                                        invalid={
                                                                                                            validation.touched.mobile && validation.errors.mobile ? true : false
                                                                                                        }
                                                                                                    />
                                                                                                    {validation.touched.mobile && validation.errors.mobile ? (
                                                                                                        <FormFeedback type="invalid">{validation.errors.mobile}</FormFeedback>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                                {!isEdit ? (<div className="mb-3">
                                                                                                    <Label className="form-label">Password</Label>
                                                                                                    <Input
                                                                                                        name="password"
                                                                                                        type="text"
                                                                                                        onChange={validation.handleChange}
                                                                                                        onBlur={validation.handleBlur}
                                                                                                        value={validation.values.password || ""}
                                                                                                        invalid={
                                                                                                            validation.touched.password && validation.errors.password ? true : false
                                                                                                        }
                                                                                                    />
                                                                                                    {validation.touched.password && validation.errors.password ? (
                                                                                                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                                                                    ) : null}
                                                                                                </div>) : ""}

                                                                                                <div className="mb-3">
                                                                                                    <Label className="form-label">Status</Label>
                                                                                                    <Input
                                                                                                        name="status"
                                                                                                        type="select"
                                                                                                        className="form-select"
                                                                                                        onChange={validation.handleChange}
                                                                                                        onBlur={validation.handleBlur}
                                                                                                        value={
                                                                                                            validation.values.status || ""
                                                                                                        }
                                                                                                    >
                                                                                                        <option value="active">Active</option>
                                                                                                        <option value="inactive">Inactive</option>

                                                                                                    </Input>
                                                                                                    {validation.touched.status && validation.errors.status ? (
                                                                                                        <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                                <div className="mb-3 ">
                                                                                                    <Label className="form-label">Role</Label>
                                                                                                    <Input
                                                                                                        name="role"
                                                                                                        type="select"
                                                                                                        className="form-select"
                                                                                                        onChange={e => handleChangeRole(e.currentTarget.value)}
                                                                                                        onBlur={validation.handleBlur}
                                                                                                        value={
                                                                                                            roleForUser || ""
                                                                                                        }
                                                                                                    >
                                                                                                        <option>Select Role</option>
                                                                                                        {role == "admin" || role == "super manager" ? (<option value="super manager">Super Manager</option>) : ""}
                                                                                                        {role == "admin" || role == "super manager" ? (<option value="admin">Admin</option>) : ""}
                                                                                                        {role == "admin" || role == "super manager" ? (<option value="manager">Manager</option>) : ""}
                                                                                                        {role == "admin" || role == "super manager" || role == "manager" ? (<option value="sales">Sales</option>) : ""}
                                                                                                        {/* {role == "admin" || role == "super manager" || role == "manager" || role == "sales" ? (<option value="dealer">Dealer</option>) : ""} */}
                                                                                                        {/*<option>Staff</option> */}

                                                                                                    </Input>
                                                                                                    {validation.touched.role && validation.errors.role ? (
                                                                                                        <FormFeedback type="invalid">{validation.errors.role}</FormFeedback>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                                {/* {role == "admin" || role == "super manager" ? (<div className="mb-3 cust-wrap">
                                                  <MultiSelectAllManager options={managerToSendMultiSelectAll} handleClickMultiSelect={handleClickManager} />
                                                </div>) : ""} */}
                                                                                                {roleForUser == "manager" ? (
                                                                                                    <div className="mb-3 cust-wrap">
                                                                                                        <Label className="form-label">Assign Sales</Label>
                                                                                                        <MultiSelectAllSales options={salesToSendMultiSelectAll} handleClickMultiSelect={handleClickSales} />
                                                                                                    </div>) : ""}
                                                                                                {roleForUser == "sales" || roleForUser == "manager" ? (<div className="mb-3 cust-wrap">
                                                                                                    <Label className="form-label">Assign Dealer</Label>
                                                                                                    <MultiSelectAllDealer options={dealerToSendMultiSelectAll} handleClickMultiSelect={handleClickDealer} />
                                                                                                </div>) : ""}

                                                                                                <div className="mb-3">
                                                                                                    <Label className="form-label">State</Label>
                                                                                                    <Input
                                                                                                        name="state"
                                                                                                        type="text"
                                                                                                        onChange={validation.handleChange}
                                                                                                        onBlur={validation.handleBlur}
                                                                                                        value={validation.values.state || ""}
                                                                                                        invalid={
                                                                                                            validation.touched.state && validation.errors.state ? true : false
                                                                                                        }
                                                                                                    />
                                                                                                    {validation.touched.state && validation.errors.state ? (
                                                                                                        <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                                                                                    ) : null}
                                                                                                </div>

                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <div className="text-end">
                                                                                                    <button
                                                                                                        type="submit"
                                                                                                        className="btn btn-success save-admin"
                                                                                                    >
                                                                                                        Save
                                                                                                    </button>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Form>
                                                                                </ModalBody>
                                                                            </Modal>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="align-items-md-center mt-30">
                                                                        <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            )}
                                                        </ToolkitProvider>
                                                    )}
                                            </PaginationProvider>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
            }
        </React.Fragment >
        // <React.Fragment>
        //   <div className="page-content">
        //   {status === true && (
        //       <div>
        //         <Row className="popup justify-content-center ">



        //           <Col md={10} >

        //           <Row className="mt-5">
        //               <Col>
        //                 {" "}
        //                 <h1
        //                   style={{
        //                     fontWeight: "bold",
        //                     marginBottom: "10px",
        //                     fontSize: "24px",
        //                   }}
        //                 >
        //                   User Details
        //                 </h1>
        //               </Col>
        //               <Col  className=" justify-content-end  text-end">
        //                 <button
        //                   style={{
        //                     color: "white",
        //                     background: "red",
        //                     border: "none",
        //                     padding: "10px",
        //                   }}
        //                   onClick={() => setStatus(!status)}
        //                 >
        //                   Close
        //                 </button>
        //               </Col>
        //             </Row>
        //             {curr === null ? null : (
        //               <table style={{ width: "100%" ,marginTop:"50px" }}>






        //                 <tr>
        //                   <th style={{ fontWeight: "normal" }}>Id</th>
        //                   <td style={{ fontWeight: "bold" }}>
        //                     : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].id}
        //                   </td>
        //                 </tr>
        //                 <tr>
        //                   <th style={{ fontWeight: "normal" }}>User Name</th>
        //                   <td style={{ fontWeight: "bold" }}>
        //                     : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].name}
        //                   </td>
        //                 </tr>
        //                 <tr>
        //                   <th style={{ fontWeight: "normal" }}>Email</th>
        //                   <td style={{ fontWeight: "bold" }}>
        //                     : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].email}
        //                   </td>
        //                 </tr>
        //                 <tr>
        //                   <th  style={{ fontWeight: "normal" }}>Mobile</th>
        //                   <td style={{ fontWeight: "bold" }}>
        //                     : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].mobile}
        //                   </td>
        //                 </tr>
        //                 <tr>
        //                   <th style={{ fontWeight: "normal" }}>Nric Number</th>
        //                   <td style={{ fontWeight: "bold" }}>
        //                     : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].nric}
        //                   </td>
        //                 </tr>
        //                 <tr>
        //                   <th style={{ fontWeight: "normal" }}>State</th>
        //                   <td style={{ fontWeight: "bold" }}>
        //                     : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].state}
        //                   </td>
        //                 </tr>




        //               </table>
        //             )}
        //           </Col>
        //         </Row>
        //       </div>
        //     )}
        //     {
        //       loading ? 
        //       <div className="d-flex justify-content-center">
        //           <div className="spinner-border text-primary" role="status">
        //             <span className="sr-only">Loading...</span>
        //           </div>
        //         </div> : 

        //     <div className="container-fluid">
        //       <h2>User List</h2>
        //      <Col md={3}>
        //      <Input  onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search..."/>
        //      </Col>
        //       <Row className="mt-2">
        //         <Col>
        //           <Card>
        //             <CardBody>
        //               <div className="table-rep-plugin">
        //                 <div
        //                   className="table-responsive mb-0"
        //                   data-pattern="priority-columns"
        //                 >
        //                   <Table
        //                     id="tech-companies-1"
        //                     className="table table-striped table-bordered"
        //                   >
        //                     <Thead>
        //                       <Tr>
        //                         <Th data-priority="1">Id</Th>
        //                         <Th data-priority="2">Full Name</Th>
        //                         <Th data-priority="3">Email</Th>
        //                         <Th data-priority="4">Mobile</Th>
        //                         <Th data-priority="5">NRIC Number</Th>
        //                         <Th data-priority="6">State</Th>
        //                         <Th data-priority="9"> Action </Th>
        //                       </Tr>
        //                     </Thead>
        //                     <Tbody>

        //                       {orders?.filter((val)=>{
        //                 if(searchTerm===''){
        //                   return val
        //                 }
        //                 else if(
        //                   val.id?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        //                   val.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        //                   val.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        //                   val.mobile?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        //                   val.nric?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        //                   val.state?.toLowerCase().includes(searchTerm?.toLowerCase())
        //                 ){
        //                   return val;
        //                 }
        //                 }
        //                 ).map((p, ind) => (
        //                         <Tr key={ind}>
        //                           <Td>{p.id}</Td>
        //                           <Td>{p.name}</Td>
        //                           <Td>{p.email}</Td>
        //                           <Td>{p.mobile}</Td>
        //                           <Td>{p.nric}</Td>
        //                           <Td>{p.state}</Td>
        //                           <Td
        //                               style={{
        //                                 fontWeight: "bold",
        //                                 cursor: "pointer",
        //                               }}
        //                               onClick={() => handleDetails(p.id)}
        //                             >
        //                               {" "}
        //                               View Details
        //                             </Td>
        //                         </Tr>
        //                       ))}
        //                     </Tbody>
        //                   </Table>
        //                 </div>
        //               </div>
        //             </CardBody>
        //           </Card>
        //         </Col>
        //       </Row>
        //     </div>
        //     }
        //   </div>
        // </React.Fragment>

    )
}

SalesList.propTypes = {
    customers: PropTypes.array,
    onGetCustomers: PropTypes.func,
    onAddNewCustomer: PropTypes.func,
    onDeleteCustomer: PropTypes.func,
    onUpdateCustomer: PropTypes.func,
}

export default SalesList
