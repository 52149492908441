import { gql } from "@apollo/client"

const GETAllUSERS = gql`
  query {
    getAllUsers {
      allUserRes {
        fullName
        role
        _id
        mobile
        email
      }
    }
  }
`

const GETALLDEALERS = gql`
  query {
    getAllDealer {
      allDealerRes {
        id
        name
        no
        mobile
        email
        postcode
        state
        ezPartner
        fmcMember
        dealerCode
        stateCode
        dealerType
        dealerNumber
      }
    }
  }
`
const GETALLINSPECTIONCENTER = gql`
query {
     getAllInspection {
       allInspectionRes {
         id
         stateId
         name
         address
         telephone
    
       
         email
    
         dealerType
         dealcode
         dealerNumber
         channel
         stateName
       }
     }
   }

`

const GETALLWORKSHOPS = gql`
query {
     getAllWorkshop {
       allWorkshopRes {
         id
         stateId
         name
         state
         stateCode
         address
         telephone
         mobile
         fax
         email
         area
         personInCharge
         dealerType
         dealcode
         dealerNumber
         distribution
         category
         workshopType
       }
     }
   }

`

const FETCHALLUSERS = gql`
  query {
    getAllUsers {
      allUserRes {
        id
        name
        email
        mobile
        nric
        state
        status
      }
    }
  }
`
const FETCHORDERLIST = gql`
  query {
    getAllBooking {
      allBookingRes {
        id
        brandName
        modelName
        userName
        email
        mobile
        price
        status
        orderId
        voucherCode
        year
        nric
        state
        transactionId
        mileage
        creditCardName
        creditCardNumber
        currency
        bankName
        bankCountry
        transactionDate
        provider
        errDesc
        dealerName
        dealerCode
        orderDate
        promocodeApplied
        planPrice
        discount
      }
    }
  }
`
const FETCHALLADMINS = gql`
  query {
    getAllAdmins {
      allAdminRes {
        id
        name
        email
        mobile
        password
        state
        status
        role
      }
    }
  }
`

const FETCHALLADMINSCREATEDBYADMIN = gql`
  query getAllAdminCreatedByAdmin($createdByadminId: ID)
  {
      getAllAdminCreatedByAdmin(createdByadminId: $createdByadminId)
      {
        allAdminRes {
          id
          name
          email
          mobile
          password
          state
          role
          status
          assignedManager{
            managerId
            managerName
          }
          assignedSales{
            salesId
            salesName
          }
          assignedDealer{
            dealerId
            dealerName
          }
        }
      }
  }
`

const FETCHALLDEALERS = gql`
  query {
    getAllDealer {
      allUserRes {
        id
        name
        email
        mobile
        nric
        state
      }
    }
  }
`

const ALLDEALERSCOUNT = gql`
  query {
    getAllDealerNumber {
      dealerCount
    }
  }
`

const ALLUSERSCOUNT = gql`
  query {
    getAllUserNumber {
      userCount
    }
  }
`

const GETALLINS = gql`
  {
    getAllInstructor {
      allUserRes {
        fullName
        email
        mobile
        _id
        role
      }
    }
  }
`
const GETALLSTATES = gql`
  {
    getAllState {
      allStateRes {
        id
        name
      }
    }
  }
`

const GETBOOKINGCOUNT = gql`
  {
  getBookingCount {
    bookingCount
  }
}
`
const GETCONFIRMBOOKINGCOUNT = gql`
{
  getConfirmBookingCount {
    bookingCount
  }
}
`
const GETFAILUREMBOOKINGCOUNT = gql`
{
  getFailureBookingCount {
    bookingCount
  }
}
`

const GETALLBRANDS = gql`
{
    getAllBrands {
      allVehicleRes {
      id
      brand
      }
    }
}
`

const GETALLMODELBYBRANDID = gql`
  query getBrandById($brandId: ID){
    getBrandById(brandId: $brandId) {
      allVehicleModelRes {
        id
        modelName
      }
    }
}
`

const GETALLBOOKINGFORSALESREPORT = gql`
mutation getAllBookingForSalesReport(
    $fromDate: Date
    $toDate: Date
    $brandId: ID
    $modelId: ID
    $purchaseType: String
    $paymentStatus: String
    $paymentGateway: String
    $productType: String
    $stateId: String
    ){
      getAllBookingForSalesReport(allBookingForSalesReportInput: {
        fromDate: $fromDate
        toDate: $toDate
        brandId: $brandId
        modelId: $modelId
        purchaseType: $purchaseType
        paymentStatus: $paymentStatus
        paymentGateway: $paymentGateway
        productType: $productType
        stateId: $stateId
      }) {
        allBookingRes {
          id
          userName
          price
          status
          nric
          state
          voucherCode
          message
          orderId
          startDate
          endDate
          ownerName
          transactionId
          createdByName
          brandName
          modelName
          year
          email
          mobile
          mileage
          creditCardName
          creditCardNumber
          currency
          bankName
          bankCountry
          transactionDate
          provider
          errDesc
          dealerName
          dealerCode
          orderDate
          promocodeApplied
          planPrice
          discount
        }
        totalRevenue
    }
}
`
const GETALLBOOKINGFORSALESREPORTREFETCH = gql`
  mutation getAllBookingForSalesReportRefetch(
    $fromDate: Date
    $toDate: Date
    $brandId: ID
    $modelId: ID
    $purchaseType: String
    $paymentStatus: String
    $paymentGateway: String
    $productType: String
    $stateId: String
    ){
      getAllBookingForSalesReportRefetch(allBookingForSalesReportInput: {
        fromDate: $fromDate
        toDate: $toDate
        brandId: $brandId
        modelId: $modelId
        purchaseType: $purchaseType
        paymentStatus: $paymentStatus
        paymentGateway: $paymentGateway
        productType: $productType
        stateId: $stateId
      }) {
        allBookingRes {
          id
          userName
          price
          status
          nric
          state
          voucherCode
          message
          orderId
          startDate
          endDate
          ownerName
          transactionId
          createdByName
          brandName
          modelName
          year
          email
          mobile
          mileage
          creditCardName
          creditCardNumber
          currency
          bankName
          bankCountry
          transactionDate
          provider
          errDesc
          dealerName
          dealerCode
          orderDate
          promocodeApplied
          planPrice
          discount
        }
        totalRevenue
    }
}
`
const GETALLSEARCHDEALER = gql`
{
  searchDealer {
    searchResults {
      value 
      label
      dealerCode
    }
  }
}
`
const GETCREDITLIST = gql`
{
  getCreditList {
    dealerResults {
      id
      name
      email
      mobile
      amount
      type
    }
  }
}
`
const GETPROMOCODELIST = gql`
{
  getAllPromocode {
    promocodeRes {
      id
      name
      promoCode
      discount
      expirationDate
      percentage
      financialInstitute
      startDate
      remarks
      type
      status
    }
  }
}
`

const GET_ROLE_AND_PERMISSION_LIST = gql`
{
  getAllRoleAndPermission {
    roleAndPermissionRes {
      id
      role
      permission
      permissionType
    }
  }
}
`

const GETALLMANAGER = gql`
{
  getAllManager {
    allManagerRes {
      label
      value
    }
  }
}
`
const GETALLSALES = gql`
{
  getAllSales {
    allSalesRes {
      label
      value
    }
  }
}
`

const FETCHALLSALESANDDEALERFORMANAGER = gql`
  query getAllSalesAndDealerForManager($managerId: ID)
  {
    getAllSalesAndDealerForManager(managerId: $managerId)
      {
        allAdminRes {
          id
          name
          email
          mobile
          password
          state
          role
          status
          assignedManager{
            managerId
            managerName
          }
          assignedSales{
            salesId
            salesName
          }
          assignedDealer{
            dealerId
            dealerName
          }
        }
      }
  }
`

const FETCHALLSALESFORMANAGER = gql`
  query getAllSalesForManager($managerId: ID)
  {
    getAllSalesForManager(managerId: $managerId)
      {
        allAdminRes {
          id
          name
          email
          mobile
          password
          state
          role
          status
          assignedSales{
            salesId
            salesName
          }
          assignedSales{
            salesId
            salesName
          }
          assignedDealer{
            dealerId
            dealerName
          }
        }
      }
  }
`
const FETCHALLDEALERSFORMANAGER = gql`
  query getAllDealerForManager($managerId: ID)
  {
    getAllDealerForManager(managerId: $managerId)
      {
        allAdminRes {
          id
          name
          email
          mobile
          password
          state
          role
          status
          no
        postcode
        ezPartner
        fmcMember
        dealerCode
        stateCode
        dealerType
        dealerNumber
        }
      }
  }
`

const ALLSALESCOUNTFORMANAGER = gql`
  query getAllSalesNumber($managerId: ID){
    getAllSalesNumber(managerId: $managerId) {
      salesCount
    }
  }
`

const ALLDEALERCOUNTFORMANAGER = gql`
  query getAllDealerNumberForManager($managerId: ID){
    getAllDealerNumberForManager(managerId: $managerId) {
      dealerCount
    }
  }
`

const ALLBOOKINGCOUNTFORMANAGERANDSALES = gql`
  query getBookingCountForManagerAndSales($userId: ID){
    getBookingCountForManagerAndSales(userId: $userId) {
      bookingCount
    }
  }
`

const ALLCONFIRMBOOKINGCOUNTFORMANAGERANDSALES = gql`
  query getConfirmBookingCountForManagerAndSales($userId: ID){
    getConfirmBookingCountForManagerAndSales(userId: $userId) {
      bookingCount
    }
  }
`

const ALLFAILUREBOOKINGCOUNTFORMANAGERANDSALES = gql`
  query getFailureBookingCountForManagerAndSales($userId: ID){
    getFailureBookingCountForManagerAndSales(userId: $userId) {
      bookingCount
    }
  }
`

const FETCHALLDEALERFORSALES = gql`
  query getAllDealerForSales($salesId: ID)
  {
    getAllDealerForSales(salesId: $salesId)
      {
        allAdminRes {
          id
          name
          email
          mobile
          password
          state
          role
          status
          assignedManager{
            managerId
            managerName
          }
          assignedSales{
            salesId
            salesName
          }
          assignedDealer{
            dealerId
            dealerName
          }
        }
      }
  }
`

const GETALLSEARCHDEALERBYOTHERADMIN = gql`
query searchDealerByOtherAdminUser($userId: ID)
{
  searchDealerByOtherAdminUser(userId: $userId) {
    searchResults {
      value 
      label
      dealerCode
    }
  }
}
`;

const GETPLANSBYCRITERIA = gql`
  query getQuotePlanByCriteria($brandId: ID, $odometer: String, $year: String) {
    getQuotePlanByCriteria(brandId: $brandId, odometer: $odometer, year: $year){
      allQuotePlanRes{
        id
        EWPCategory
        CoveredComponents
        CoveredComponentsDetails{
          name
          components{
            type
          }
        }
        NoOfCoveredComponents
        CarType
        CarAge
        OdometerReading
        CarBrandByCountry
        MaxPerVisit
        MaxPerYear
        FinancialInstituitionsRate
        DealersRate
      }
      brands{
        brand
        category
      }
    }
  }
`;

export {
  GETAllUSERS,
  GETALLINS,
  FETCHALLUSERS,
  FETCHALLDEALERS,
  FETCHALLADMINS,
  FETCHORDERLIST,
  GETALLDEALERS,
  GETALLWORKSHOPS,
  GETALLINSPECTIONCENTER,
  ALLDEALERSCOUNT,
  ALLUSERSCOUNT,
  GETALLSTATES,
  GETBOOKINGCOUNT,
  GETCONFIRMBOOKINGCOUNT,
  GETFAILUREMBOOKINGCOUNT,
  GETALLBRANDS,
  GETALLMODELBYBRANDID,
  GETALLBOOKINGFORSALESREPORT,
  GETALLBOOKINGFORSALESREPORTREFETCH,
  GETALLSEARCHDEALER,
  GETCREDITLIST,
  GETPROMOCODELIST,
  GET_ROLE_AND_PERMISSION_LIST,
  FETCHALLADMINSCREATEDBYADMIN,
  GETALLMANAGER,
  GETALLSALES,
  FETCHALLSALESANDDEALERFORMANAGER,
  FETCHALLDEALERFORSALES,
  GETALLSEARCHDEALERBYOTHERADMIN,
  FETCHALLSALESFORMANAGER,
  FETCHALLDEALERSFORMANAGER,
  ALLSALESCOUNTFORMANAGER,
  ALLDEALERCOUNTFORMANAGER,
  ALLBOOKINGCOUNTFORMANAGERANDSALES,
  ALLCONFIRMBOOKINGCOUNTFORMANAGERANDSALES,
  ALLFAILUREBOOKINGCOUNTFORMANAGERANDSALES,
  GETPLANSBYCRITERIA
}
