import React, { useEffect, useState, useRef } from "react"
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as moment from "moment"
import * as Yup from "yup"
import MetaTags from "react-meta-tags"

import { CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form,
  UncontrolledTooltip
} from "reactstrap"
import "./index.css"
// import {
//     MDBBtn,
//     MDBModal,
//     MDBModalDialog,
//     MDBModalContent,
//     MDBModalHeader,
//     MDBModalTitle,
//     MDBModalBody,
//     MDBModalFooter,
//   } from "mdb-react-ui-kit";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ReactPaginate from 'react-paginate';

import { useSelector, useDispatch } from "react-redux"

import { FETCHORDERLIST, GETALLSTATES, GETALLBRANDS, GETALLMODELBYBRANDID, GETALLSEARCHDEALER, GETPLANSBYCRITERIA } from "../../../queries/userQueries"
import { DELETEBOOKING, UPDATEBOOKING, FETCHORDERLISTBYPAGINATION } from "../../../mutations/useMutations"
import { useQuery, useMutation } from "@apollo/react-hooks"
import DeleteModal from "../../../components/Common/DeleteModal"
import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "store/e-commerce/actions"
import EcommerceOrdersModal from "../EcommerceOrders/EcommerceOrdersModal";
import DatePicker from "react-flatpickr";

const OrderList = props => {

  const [modal, setModal] = useState(false);
  const [order, setOrder] = React.useState([]);
  const [modal1, setModal1] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [bookingToDelete, setBookingToDelete] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [orderToEdit, setOrderToEdit] = useState(null);
  const [states, setStates] = React.useState([]);
  const [dealer, setDealer] = React.useState([]);
  const [dealerValue, setDealerValue] = React.useState("");
  const [dealerName, setDealerName] = React.useState("");
  const [dealerId, setDealerID] = React.useState("");
  const [mileageValue, setMileagevalue] = React.useState("");

  const [isChecked, setIsChecked] = useState(false);

  const [brandvalue, setBrandvalue] = useState("");
  const [modelvalue, setModelvalue] = useState("");
  const [stateValue, setStatevalue] = useState("");

  const [brandNamevalue, setBrandNamevalue] = useState("");
  const [modelNamevalue, setModelNamevalue] = useState("");

  const [totalOrderLen, setTotalOrderLen] = useState("");

  const [yearvalue, setYearvalue] = useState("");

  const [pageNumber, setPageNumber] = useState(1);

  let userId = localStorage.getItem("userId")
  let role = localStorage.getItem("role")

  // We start with an empty list of items.
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  localStorage.setItem("brandId", brandvalue);
  localStorage.setItem("modelId", modelvalue);
  localStorage.setItem("stateId", stateValue);
  localStorage.setItem("owner_year", yearvalue);
  localStorage.setItem("mileageValue", mileageValue);

  // Edit Booking
  const [editBooking] = useMutation(UPDATEBOOKING, {
    update(_, { data: { updateBooking: bookingData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);

    },
    onError(err) {
      console.log(err.message);
    },
  });


  const [deleteBooking] = useMutation(DELETEBOOKING, {
    update(_, { data: { deleteBooking: bookingData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);
    },
    onError(err) {
      console.log(err.message);
    },
  });

  const brandId = localStorage.getItem("brandId");
  const modelId = localStorage.getItem("modelId");
  const stateId = localStorage.getItem("stateId");
  const planyearvalue = localStorage.getItem("owner_year");
  const planmileageValue = localStorage.getItem("mileageValue");

  function handleUpdateBooking(updateBooking) {

    editBooking(
      {
        variables: {

          orderId: updateBooking?.orderId,
          status: updateBooking?.status,
          userName: updateBooking?.userName,
          email: updateBooking?.email,
          mobile: updateBooking?.mobile,
          brandId: brandId,
          modelId: modelId,
          price: updateBooking?.price,
          dealerName: updateBooking?.dealerName,
          dealerCode: updateBooking?.dealerCode,
          voucherCode: updateBooking?.voucherCode,
          year: updateBooking?.year,
          nric: updateBooking?.nric,
          stateId: updateBooking?.stateId,
          mileage: updateBooking?.mileage,
          isVoucher: updateBooking?.isVoucher,
          userId: updateBooking?.userId,
          promoCode: updateBooking?.promoCode,
          planId: updateBooking?.planId

        }

      }
    )
  }


  const { data: dataBrand } = useQuery(GETALLBRANDS);
  const brand = dataBrand?.getAllBrands?.allVehicleRes;

  const { data: dataModel } = useQuery(GETALLMODELBYBRANDID, { variables: { brandId } });
  const { data: dataPlans } = useQuery(GETPLANSBYCRITERIA, { variables: { brandId, year:planyearvalue, odometer:planmileageValue } });

  const model = dataModel?.getBrandById?.allVehicleModelRes;

  const [getAllBookingPagination] = useMutation(FETCHORDERLISTBYPAGINATION, {
    update(_, { data: { getAllBookingPagination: reportData } }) {

      if (reportData) {

        const orderRes = reportData?.allBookingRes
        const totalOrderLen = reportData?.orderLen

        setTimeout(() => {
          setTotalOrderLen(totalOrderLen)
          setOrder(orderRes)
        }, 1000);

      }

    },
    onError(err) {
      console.log(err.message);
    },
  });

  useEffect(() => {
    // if (data) {
    //   const orderRes = data?.getAllBooking?.allBookingRes
    //   setOrderLen(orderRes)
    // }
    if (dataState) {
      const stateRes = dataState?.getAllState?.allStateRes
      setStates(stateRes)
    }
    if (dealerData) {
      const dealerRes = dealerData?.searchDealer?.searchResults
      setDealer(dealerRes)
    }

  })

  useEffect(() => {

    getAllBookingPagination({
      variables:
      {
        page: 1,
        limit: 10,
        userId: userId
      }
    })
  }, [])

  let itemsPerPage = 10

  useEffect(() => {
    setPageCount(Math.ceil(totalOrderLen / itemsPerPage));
  }, [totalOrderLen]);

  // validation

  const validationDropDown = {
    initialValues: {
      brandName: (orderToEdit && orderToEdit?.brandName) || '',
      modelName: (orderToEdit && orderToEdit?.modelName) || '',
      state: (orderToEdit && orderToEdit?.state) || '',
      year: (orderToEdit && orderToEdit?.year) || '',
    }
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      orderId: (orderToEdit && orderToEdit?.orderId) || '',
      status: (orderToEdit && orderToEdit?.status) || '',
      userName: (orderToEdit && orderToEdit?.userName) || '',
      email: (orderToEdit && orderToEdit?.email) || '',
      mobile: (orderToEdit && orderToEdit?.mobile) || '',
      brandName: '',
      modelName: '',
      price: (orderToEdit && orderToEdit?.price) || '',
      dealerName: (orderToEdit && orderToEdit?.dealerName) || '',
      dealerCode: (orderToEdit && orderToEdit?.dealerCode) || '',
      voucherCode: (orderToEdit && orderToEdit?.voucherCode) || '',
      promoCode: (orderToEdit && orderToEdit?.promoCode) || '',
      year: '',
      nric: (orderToEdit && orderToEdit?.nric) || '',
      state: '',
      mileage: (orderToEdit && orderToEdit?.mileage) || '',
      planName: (orderToEdit && orderToEdit?.planName) || '',
      planId: (orderToEdit && orderToEdit?.planId) || '',

    },
    onSubmit: (values) => {
      if (isEdit) {

        let brandsId
        if (brand !== null) {
          let brandIdRes = brand?.filter((brandItem) => {
            if (brandItem.id === brandId) {
              return brandItem;
            }
          });
          brandsId = brandIdRes
        }


        let modelsId
        if (model !== null) {
          let modelIdRes = model?.filter((modelItem) => {
            if (modelItem.id === modelId) {
              return modelItem;
            }
          });
          modelsId = modelIdRes
        }


        let statesId
        if (states !== null) {
          let stateIdRes = states?.filter((stateItem) => {
            if (stateItem.id === stateId) {
              return stateItem;
            }
          });
          statesId = stateIdRes
        }


        const updateBooking = {
          orderId: values?.orderId,
          status: values?.status,
          userName: values?.userName,
          email: values?.email,
          mobile: values?.mobile,
          brandId: brandsId.length !== 0 ? brandsId[0].id : null,
          modelId: modelsId ? modelsId[0].id : null,
          price: values?.price,
          dealerName: dealerName ? dealerName : null,
          dealerCode: dealerValue ? dealerValue : null,
          voucherCode: values?.voucherCode,
          year: yearvalue ? yearvalue : null,
          nric: values?.nric,
          stateId: statesId.length !== 0 ? statesId[0].id : null,
          mileage: values?.mileage,
          isVoucher: isChecked,
          userId: dealerId ? dealerId : null,
          promoCode: values?.promoCode,
          planId: values?.planId
        };

        handleUpdateBooking(updateBooking);
        validation.resetForm();
      }

      toggle();
    },
  });

  const { loading: loadingState, data: dataState } = useQuery(GETALLSTATES)
  // const { loading, data } = useQuery(FETCHORDERLIST)
  const { loading: dealerLoading, data: dealerData } = useQuery(GETALLSEARCHDEALER)

  if (loadingState) {
    return "loading..."
  }

  // if (loading) {
  //   return "loading..."
  // }

  if (dealerLoading) {
    return "loading..."
  }

  const orders = order
  const selectRow = {
    mode: "checkbox",
  };

  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalOrderLen, // replace later with size(orders),
    custom: true,
  };
  const { SearchBar } = Search;

  const toggleViewModal = () => setModal1(!modal1)

  const sendOrderId = (orderId) => {
    let orderToView = orders?.filter(item => {
      if (item.id === orderId) {
        return item
      }
    })
    setOrderId(orderToView)
  }

  //delete booking

  const onClickDelete = (booking) => {

    setBookingToDelete(booking)
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (bookingToDelete) {
      deleteBooking({
        variables: {
          bookingId: bookingToDelete.id,
        },
      });
      // onPaginationPageChange(1);
      setDeleteModal(false);
    }
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      // setUser(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = async arg => {

    const orderData = arg;

    setOrderToEdit({
      orderId: orderData?.orderId,
      status: orderData?.status,
      userName: orderData?.userName,
      email: orderData?.email,
      mobile: orderData?.mobile,
      brandName: orderData?.brandName,
      modelName: orderData?.modelName,
      price: orderData?.price,
      dealerName: orderData?.dealerName,
      dealerCode: orderData?.dealerCode,
      voucherCode: orderData?.voucherCode,
      year: orderData?.year,
      nric: orderData?.nric,
      state: orderData?.state,
      mileage: orderData?.mileage,
      promoCode: orderData?.promocodeApplied,
      planName: orderData?.planName,
      planId: orderData?.planId,
    });

    setIsEdit(true);

    toggle();
  };

  const handleOnChange = () => {

    setIsChecked(!isChecked);

  };

  const handleOnChangeDealer = (e) => {

    const dealerRes = dealer?.filter((dealerItem) => {
      if (dealerItem.value === e.target.value) {
        return dealerItem;
      }

    })

    setDealerValue(dealerRes[0].dealerCode);
    setDealerName(dealerRes[0].label);
    setDealerID(dealerRes[0].value);


  };

  let EcommerceOrderColumns
  if (role == "manager" || role == "sales") {
    EcommerceOrderColumns = [{
      dataField: 'orderId',
      text: 'Order Id',
      sort: true,
    }, {
      dataField: 'userName',
      text: 'User Name',
      sort: true
    }, {
      dataField: 'email',
      text: 'Email',
      sort: true
    }, {
      dataField: 'brandName',
      text: 'Brand Name',
      sort: true
    }, {
      dataField: 'modelName',
      text: 'Model Name',
      sort: true
    },
    {
      dataField: 'planName',
      text: 'Plan',
      sort: true
    }, {
      dataField: 'planPrice',
      text: 'Plan Price',
      sort: true
    },
    {
      dataField: 'price',
      text: 'Paid Price',
      sort: true
    },
    {
      dataField: 'discount',
      text: 'Discount',
      sort: true
    },
    {
      dataField: 'promocodeApplied',
      text: 'Promocode',
      sort: true
    },
    // {
    //   dataField: 'voucherCode',
    //   text: 'Voucher Code',
    //   sort: true
    // },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: (cellContent, row) => (
        <Badge
          className="font-size-12 badge-soft-"
          color={row.status === "failure" ? "danger" : "success"}
          pill
        >
          {row.status}
        </Badge>
      ),
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => {
            sendOrderId(row.id);
            toggleViewModal()
          }

          }
        >
          View Details
        </Button >
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, booking) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleOrderClick(booking)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            {role == "manager" || role == "sales" ? "" : (<Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(booking)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>)}

          </div>
        </>
      ),
    },
    ];
  } else {
    EcommerceOrderColumns = [{
      dataField: 'orderId',
      text: 'Order Id',
      sort: true,
    }, {
      dataField: 'userName',
      text: 'User Name',
      sort: true
    }, {
      dataField: 'email',
      text: 'Email',
      sort: true
    }, {
      dataField: 'brandName',
      text: 'Brand Name',
      sort: true
    }, {
      dataField: 'modelName',
      text: 'Model Name',
      sort: true
    },
    {
      dataField: 'planName',
      text: 'Plan',
      sort: true
    }, {
      dataField: 'planPrice',
      text: 'Plan Price',
      sort: true
    },
    {
      dataField: 'price',
      text: 'Paid Price',
      sort: true
    },
    {
      dataField: 'discount',
      text: 'Discount',
      sort: true
    },
    {
      dataField: 'promocodeApplied',
      text: 'Promocode',
      sort: true
    },
    {
      dataField: 'voucherCode',
      text: 'Voucher Code',
      sort: true
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: (cellContent, row) => (
        <Badge
          className="font-size-12 badge-soft-"
          color={row.status === "failure" ? "danger" : "success"}
          pill
        >
          {row.status}
        </Badge>
      ),
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => {
            sendOrderId(row.id);
            toggleViewModal()
          }

          }
        >
          View Details
        </Button >
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, booking) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleOrderClick(booking)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(booking)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>

          </div>
        </>
      ),
    },
    ];
  }

  const defaultSorted = [
    {
      // dataField: "orderId",
      order: "desc",
    },
  ];

  const handlePageClick = (event) => {
    getAllBookingPagination({
      variables:
      {
        page: parseInt(event.selected) + 1,
        limit: 10,
        userId: userId
      }
    })
  };
  const handleChangeStartDate = (e)=>{
    var d = new Date(e);
    setYearvalue(d);
  }

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} orderId={orderId} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      {
        // loading ?
        //   <div className="d-flex justify-content-center">
        //     <div className="spinner-border text-primary" role="status">
        //       <span className="sr-only">Loading...</span>
        //     </div>
        //   </div> :


        <div className="page-content">

          <Container fluid>
            {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
            <h2>Order List</h2>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={EcommerceOrderColumns}
                      data={orders}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        // {()=>{setPageNumber(paginationProps)}}
                        <ToolkitProvider
                          keyField="id"
                          data={orders}
                          columns={EcommerceOrderColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">

                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="id"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    // ref={node}
                                    />
                                  </div>
                                  <Modal isOpen={modal} toggle={toggle}>
                                    <ModalHeader toggle={toggle} tag="h4">
                                      {!!isEdit ? "Edit Order" : "Add Order"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <Form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                          validation.handleSubmit();
                                          return false;
                                        }}
                                      >
                                        <Row form>
                                          <Col className="col-12">
                                            <div className="mb-3">
                                              <Label className="form-label">Order Id</Label>
                                              <Input
                                                name="orderId"
                                                type="text"
                                                onChange={validation.handleChange}
                                                value={validation.values.orderId || ""}

                                                disabled
                                              />

                                            </div>

                                            <div className="mb-3">
                                              <Label className="form-label">Status</Label>
                                              <Input
                                                name="status"
                                                type="select"
                                                className="form-select"
                                                onChange={validation.handleChange}
                                                value={validation.values.status || ""}
                                                style={{ textTransform: "capitalize" }}
                                              >
                                                <option value={validation.values.status} >{validation.values.status}</option>
                                                <option value="init">Init</option>
                                                <option value="confirm">Confirm</option>
                                                <option value="failure">Failure</option>
                                              </Input>

                                            </div>

                                            <div className="mb-3">
                                              <Label className="form-label">User Name</Label>
                                              <Input
                                                name="userName"
                                                type="text"
                                                value={validation.values.userName || ""}
                                                onChange={validation.handleChange}
                                              />

                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">Email</Label>
                                              <Input
                                                name="email"
                                                type="text"
                                                onChange={validation.handleChange}
                                                value={validation.values.email || ""}

                                              />

                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">Mobile Number</Label>
                                              <Input
                                                name="mobile"
                                                type="text"
                                                onChange={validation.handleChange}
                                                value={validation.values.mobile || ""}
                                              />

                                            </div>

                                            <div className="mb-3">
                                              <Label className="form-label">Current Brand Name: <b>{validationDropDown.initialValues.brandName}</b></Label>
                                              <Input
                                                name="brandName"
                                                type="select"
                                                className="form-select"
                                                onChange={(e) => {
                                                  setBrandvalue(e.target.value);
                                                }}
                                              // value={
                                              //   validation.values.brandName || ""
                                              // }

                                              >

                                                <option value="" >Select Brand</option>
                                                {brand?.map((option, index) => (
                                                  <option key={index} value={option.id}>
                                                    {option.brand}
                                                  </option>
                                                ))}
                                              </Input>

                                            </div>

                                            <div className="mb-3">
                                              <Label className="form-label">Current Model Name: <b>{validationDropDown.initialValues.modelName}</b></Label>
                                              <Input
                                                name="modelName"
                                                type="select"
                                                className="form-select"
                                                onChange={(e) => {
                                                  setModelvalue(e.target.value);
                                                }}
                                              // value={
                                              //   validation.values.modelName || ""
                                              // }
                                              >
                                                <option value="" >Select Model</option>
                                                {model?.map((option, index) => (
                                                  <option key={index} value={option.id}>
                                                    {option.modelName}
                                                  </option>
                                                ))}
                                              </Input>
                                              {(brandvalue !== "" && modelvalue == "") ? (
                                                <p style={{ color: "red", margin: "2px" }}>Please select Model</p>
                                              ) : null}

                                            </div>
                                            {/* <div className="mb-3">
                                              <Label className="form-label">Price</Label>
                                              <Input
                                                name="price"
                                                type="text"
                                                onChange={validation.handleChange}
                                                value={validation.values.price || ""}

                                              />

                                            </div> */}
                                            {/* <div className="mb-3">
                                                <Label className="form-label">Dealer Name</Label>
                                                <Input
                                                  name="dealerName"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  value={validation.values.dealerName || ""}
                                                  disabled
                                                />

                                              </div> */}

                                            <div className="mb-3">
                                              <Label className="form-label">Dealer Name: <b>{validation.values.dealerName}</b></Label>

                                              <Input
                                                name="dealerName"
                                                type="select"
                                                className="form-select"
                                                onChange={handleOnChangeDealer}
                                              // value={dealerName || ""}
                                              >
                                                <option value="" >Select Dealer</option>
                                                {
                                                  dealer?.map((dealerItem, index) => (

                                                    <option key={index} value={dealerItem.value}>{dealerItem?.label}</option>
                                                  ))

                                                }
                                              </Input>
                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">Dealer Code: <b>{validation.values.dealerCode}</b></Label>
                                              <Input
                                                name="dealerCode"
                                                type="text"
                                                onChange={validation.handleChange}
                                                value={dealerValue || ""}
                                              />

                                            </div>

                                            {/* <div className="mb-3">
                                                <Label className="form-label">Dealer Code</Label>
                                                <Input
                                                  name="dealerCode"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  value={validation.values.dealerCode || ""}
                                                  disabled
                                                />

                                              </div> */}

                                            {role == "manager" || role == "sales" ? "" : (<div className="mb-3">
                                              <Label className="form-label">Voucher Code</Label>
                                              <Input
                                                name="voucherCode"
                                                type="text"
                                                onChange={validation.handleChange}
                                                value={validation.values.voucherCode || ""}
                                              // disabled
                                              />

                                            </div>)}

                                            {
                                              validation.values.voucherCode ? <></> :
                                                <>{role == "manager" || role == "sales" ? "" : (<div className="my-3 mx-4" style={{ fontSize: "14px", color: "black" }}>
                                                  <Input
                                                    id="topping"
                                                    name="voucher"
                                                    type="checkbox"
                                                    onChange={handleOnChange}


                                                  />
                                                  Create Voucher Code
                                                </div>)}</>

                                            }
                                            {role == "manager" || role == "sales" ? "" : (<div className="mb-3">
                                              <Label className="form-label">Promo Code</Label>
                                              <Input
                                                name="promoCode"
                                                type="text"
                                                onChange={validation.handleChange}
                                                value={validation.values.promoCode || ""}
                                              // disabled
                                              />

                                            </div>)}
                                            {/*
                                            <div className="mb-3">
                                              <Label className="form-label">Current Year: <b>{validationDropDown.initialValues.year}</b></Label>
                                              <Input
                                                name="year"
                                                type="select"
                                                className="form-select"
                                                onChange={(e) => {
                                                  setYearvalue(e.target.value);
                                                }}
                                              >
                                                <option value="">Select Year</option>
                                                <option value="2022">2022</option>
                                                <option value="2021">2021</option>
                                                <option value="2020">2020</option>
                                                <option value="2019">2019</option>
                                                <option value="2018">2018</option>
                                                <option value="2017">2017</option>
                                                <option value="2016">2016</option>
                                                <option value="2015">2015</option>
                                                <option value="2014">2014</option>
                                                <option value="2013">2013</option>
                                                <option value="2012">2012</option>
                                              </Input>
                                            </div>
                                            */}

                                            <div className="mb-3">
                                              <Label className="form-label">Current Year: <b>{moment(new Date(validationDropDown.initialValues.year)).format('YYYY-MM-DD')}</b></Label>
                                              <DatePicker
                                                onChange={handleChangeStartDate}
                                                className="form-control"
                                                placeholderText="Select date" />
                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">NRIC</Label>
                                              <Input
                                                name="nric"
                                                type="text"
                                                onChange={validation.handleChange}
                                                value={validation.values.nric || ""}
                                              />

                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">Current State: <b>{validationDropDown.initialValues.state}</b></Label>
                                              <Input
                                                name="state"
                                                type="select"
                                                className="form-select"
                                                onChange={(e) => {
                                                  setStatevalue(e.target.value);
                                                }}
                                              >
                                                <option value="" >Select State</option>
                                                {
                                                  states?.map((statesItem, index) => (

                                                    <option key={index} value={statesItem.id}>{statesItem?.name}</option>
                                                  ))

                                                }
                                              </Input>

                                            </div>

                                            <div className="mb-3">
                                              <Label className="form-label">Mileage</Label>
                                              <Input
                                                name="mileage"
                                                type="text"
                                                value={validation.values.mileage || ""}
                                                onChange={(e)=>{
                                                  validation.handleChange(e);
                                                  setMileagevalue(e.target.value)
                                                }}
                                              />

                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">Current Plan: <b>{validation.values.planName}</b></Label>
                                              <Input
                                                name="planId"
                                                type="select"
                                                className="form-select"
                                                onChange={validation.handleChange}
                                              >
                                                <option value="" >Select State</option>
                                                {
                                                  dataPlans?.getQuotePlanByCriteria?.allQuotePlanRes?.map((plansItem, index) => (
                                                    <option key={index} value={plansItem.id}>{plansItem?.EWPCategory}</option>
                                                  ))
                                                }
                                              </Input>
                                            </div>

                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Form>
                                    </ModalBody>
                                  </Modal>
                                </Col>
                              </Row>
                              <ReactPaginate
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={pageCount}
                                previousLabel="< previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                                style={{ justifyContent: "end", marginTop: "5px", flexWrap: "wrap" }}

                              />
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      }
    </React.Fragment>
  )
}

// EcommerceCustomers.propTypes = {
//   customers: PropTypes.array,
//   onGetCustomers: PropTypes.func,
//   onAddNewCustomer: PropTypes.func,
//   onDeleteCustomer: PropTypes.func,
//   onUpdateCustomer: PropTypes.func,
// };

export default OrderList
