import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import Table from 'react-bootstrap/Table'
import { Button, Card, CardBody, Col, Row, Badge } from "reactstrap"
import { getOrders as onGetOrders } from "store/actions"

import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal"

//redux
import { useSelector, useDispatch } from "react-redux"

//graphql

import { GETAllUSERS } from "../../queries/userQueries"
import { useQuery } from "@apollo/react-hooks"

const LatestTranaction = props => {
  const { loading,data } = useQuery(GETAllUSERS )
  // console.log("data", data?.getAllUsers?.allUserRes[0])

  const dispatch = useDispatch()
  // console.logorder
  // const { orders} = useSelector(state => ({
  //   orders: state.ecommerce.orders,
  //   // orders:data.getAllUsers.allUserRes

  // }))

  const orders = data?.getAllUsers?.allUserRes
  console.log(orders)
  // useEffect(() => {
    
  //   dispatch(onGetOrders())
  // }, [dispatch])

  // const selectRow = {
  //   mode: "checkbox",
  // }

  // const [modal, setModal] = useState(false)
  // const [modal1, setModal1] = useState(false)
  // const [orderList, setOrderList] = useState([])
  // const [isEdit, setIsEdit] = useState(false)

  // //pagination customization
  // const pageOptions = {
  //   sizePerPage: 6,
  //   // totalSize: orders.length, // replace later with size(orders),
  //   custom: true,
  // }
  // const { SearchBar } = Search

  // // const toggleModal = () => {
  // //   setModal1(!modal1)
  // // }
  // const toggleViewModal = () => setModal1(!modal1)

  // const EcommerceOrderColumns = toggleModal => [
  //   {
  //     dataField: "orderId",
  //     text: "User ID",
  //     sort: true,
  //     // eslint-disable-next-line react/display-name
  //     formatter: (cellContent, row) => (
  //       <Link to="#" className="text-body fw-bold">
  //         {row.orderId}
  //       </Link>
  //     ),
  //   },
  //   {
  //     dataField: "billingName",
  //     text: "Full Name",
      
  //     sort: true,
  //   },
  //   {
  //     dataField: "orderdate",
  //     text: "Email",
  //     sort: true,
  //   },
  //   {
  //     dataField: "total",
  //     text: "Mobile",
  //     sort: true,
  //   },
  //   {
  //     dataField: "paymentStatus",
  //     text: "Role",
  //     sort: true,
  //     // eslint-disable-next-line react/display-name
  //     formatter: (cellContent, row) => (
  //       <Badge
  //         className={"font-size-12 badge-soft-" + row.badgeclass}
  //         color={row.badgeClass}
  //         pill
  //       >
  //         {row.paymentStatus}
  //       </Badge>
  //     ),
  //   },
  // ]

  // useEffect(() => {
  //   if (orders && !orders.length) {
  //     onGetOrders()
  //   }
  // }, [onGetOrders, orders])

  // useEffect(() => {
  //   setOrderList(orders)
  // }, [orders])

  // useEffect(() => {
  //   if (!isEmpty(orders) && !!isEdit) {
  //     setOrderList(orders)
  //     setIsEdit(false)
  //   }
  // }, [orders])

  // const toggle = () => {
  //   setModal(!modal)
  // }

  // const toLowerCase1 = str => {
  //   return str.toLowerCase()
  // }

  // const defaultSorted = [
  //   {
  //     dataField: "orderId",
  //     order: "desc",
  //   },
  // ]

  return (
    <React.Fragment>
      <table  className="table table-bordered table-hover">
  <thead className="thead-dark">
    <tr>
      <th scope="col">ID</th>
      <th scope="col">Full Name</th>
      <th scope="col">Mobile</th>
      <th scope="col">Email</th>
      <th scope="col">Role</th>
    </tr>
  </thead>
  <tbody>
  {
        orders?.map((p,ind)=>
            <tr key={ind}>
                <td >{p._id}</td>
                <td >{p.fullName}</td>
                <td >{p.mobile}</td>
                <td >{p.email}</td>
                <td >{p.role}</td>
            </tr>
        )
    }
   
      
  </tbody>
</table>
     
      {/* <table>
    <thead>
        <tr>
            <th>ID</th>
            <th>Full Name</th>
            <th>Mobile</th>
            <th>Role</th>
        </tr>
    </thead>
<tbody>
    {
        orders?.map((p,ind)=>
            <tr key={ind}>
                <td style={{margin:"10px"}}>{p._id}</td>
                <td style={{margin:"10px"}}>{p.fullName}</td>
                <td style={{margin:"10px"}}>{p.mobile}</td>
                <td style={{margin:"10px"}}>{p.role}</td>
            </tr>
        )
    }
</tbody>
</table> */}
{/* <table className='table table-hover mt-3'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th></th>
            </tr>
          </thead>
          <tbody> */}
            {/* {orders.map((client,index  ) => (
              < tr key={index}>
      <td>{client.fullName}</td>
      <td>{client.email}</td>
      <td>{client._id}</td>
      <td>
        <button className='btn btn-danger btn-sm' onClick={deleteClient}>
          
        </button>
      </td>
    </tr>
            ))} */}
           
          {/* </tbody>
        </table> */}
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">User Details</div>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={EcommerceOrderColumns(toggle)}
            data={orders}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={EcommerceOrderColumns(toggle)}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="id"
                            responsive
                            bordered={false}
                            // data={orders._id}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={
                              "table align-middle table-nowrap table-check"
                            }
                            headerWrapperClasses={"table-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="pagination pagination-rounded justify-content-end">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card> */}
    </React.Fragment>
  )
}

// LatestTranaction.propTypes = {
//   orders: PropTypes.array,
//   onGetOrders: PropTypes.func,
// }

export default withRouter(LatestTranaction)
