import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import PropTypes from 'prop-types'
import { GETALLSEARCHDEALER, GETALLSEARCHDEALERBYOTHERADMIN } from "../../../queries/userQueries"
import { useQuery, useMutation } from "@apollo/react-hooks"

function MultiSelectAllDealer({ options, handleClickMultiSelect }) {
    const [dealers, setDealer] = React.useState([]);

    var newDealerArr = options

    let addDealerLabel = []
    let newDealerArrLen = newDealerArr ? newDealerArr.length : 0
    if (newDealerArrLen !== 0) {
        newDealerArr.map((newDealerArrItem) => {


            let newDealerArrItemObj = {
                id: 1,
                value: newDealerArrItem.dealerId,
                isChecked: false,
                label: newDealerArrItem.dealerName
            }


            addDealerLabel.push(newDealerArrItemObj)
        })
    }

    const [selectedOptions, setSelectedOptions] = useState([]);

    handleClickMultiSelect(selectedOptions)

    useEffect(() => {
        setSelectedOptions([...addDealerLabel]);
    }, []);

    useEffect(() => {
        if (data && type == "admin") {
            const dealerRes = data?.searchDealer?.searchResults
            setDealer(dealerRes)
        }
        if (data && type == "manager") {
            const dealerRes = data?.searchDealerByOtherAdminUser?.searchResults
            console.log("dealerRes", dealerRes)
            setDealer(dealerRes)
        }

    });

    let userId = localStorage.getItem("userId")
    let role = localStorage.getItem("role")

    let data, loading, type
    if (role == "manager" || role == "sales") {
        const { loading: dealerLoading, data: dealerData } = useQuery(GETALLSEARCHDEALERBYOTHERADMIN, {
            variables: { userId: userId }
        });
        data = dealerData
        loading = dealerLoading
        type = "manager"
    } else {
        const { loading: adminLoading, data: adminData } = useQuery(GETALLSEARCHDEALER)
        data = adminData
        loading = adminLoading
        type = "admin"
    }



    if (loading) {
        return "loading..."
    }

    const allOptions = dealers
    // useEffect(() => {
    //     setSelectedOptions([...addDealerLabel]);
    // }, []);

    function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        let selectedOptionsLen = value.length

        let selectedOptionsLabelRes
        if (selectedOptionsLen > 0) {
            let selectedOptionsLabel = value.map(function (item) {
                return " " + item['label'];
            });
            selectedOptionsLabelRes = selectedOptionsLabel.toString()
        }
        if (value && value.some((o) => o.value === "*")) {
            return `${selectedOptionsLen == 0 || selectedOptionsLen == [] ? "No Dealer Assigned" : selectedOptionsLabelRes}`;
        } else {
            return `${selectedOptionsLen == 0 || selectedOptionsLen == [] ? "No Dealer Assigned" : selectedOptionsLabelRes}`;
        }
    }

    function onChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            this.setState(this.options);
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            this.setState([]);
        } else if (event.action === "deselect-option") {
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            this.setState(this.options);
        } else {
            this.setState(value);
        }


    }


    let selectedOptionsLen = selectedOptions.length

    let selectedOptionsLabelRes
    if (selectedOptionsLen > 0) {
        let selectedOptionsLabel = selectedOptions.map(function (item) {
            return " " + item['label'];
        });
        selectedOptionsLabelRes = selectedOptionsLabel.toString()
    }

    return (
        <ReactMultiSelectCheckboxes
            // options={[...allOptions]}
            options={[{ value: "*", label: "All" }, ...allOptions]}
            placeholderButtonLabel={selectedOptionsLabelRes ? selectedOptionsLabelRes : ""}
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedOptions}
            onChange={onChange}
            setState={setSelectedOptions}
            style={{ width: "inherit" }}
        />
    );
};

MultiSelectAllDealer.propTypes = {

    options: PropTypes.array,
    handleClickMultiSelect: PropTypes.func
}

export default MultiSelectAllDealer;