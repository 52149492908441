import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { map } from "lodash"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Badge,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

//Date Picker
import DatePicker from "react-datepicker"
// import "react-datepicker/dist/react-datepicker.css"

import "assets/scss/datatables.scss"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
// import cryptoOrderColumn from "./cryptoOrderColumn"
import { getCryptoOrders } from "store/crypto/actions"
import MiniWidget from "./mini-widget"

import { useQuery, useMutation } from "@apollo/react-hooks"
import { GETALLSTATES, GETALLBRANDS, GETALLMODELBYBRANDID, FETCHORDERLIST, GETALLBOOKINGFORSALESREPORT, GETALLBOOKINGFORSALESREPORTREFETCH } from "../../../queries/userQueries"
import { useForm } from "../../../utils/hooks";
import SalesReportListModal from "./SalesReportListModal";
import ExportReport from "./ExportReport"

const SalesReport = props => {
    // const { orders, onGetOrders } = props

    const defaultStartDate = new Date();
    defaultStartDate.setUTCHours(0, 0, 0, 0)
    defaultStartDate.setDate(defaultStartDate.getDate() - 7);
    let defaultStartDateRes = defaultStartDate
    const [startDate, setStartDate] = useState(defaultStartDateRes)
    const defaultEndDate = new Date();
    defaultEndDate.setUTCHours(23, 59, 59, 999)
    const [endDate, setEndDate] = useState(defaultEndDate)
    const [activeTab, setActiveTab] = useState("1")
    const [states, setStates] = React.useState([]);
    const [modal1, setModal1] = useState(false);
    // const [brands, setBrands] = React.useState([]);
    const [orders, setOrder] = React.useState([]);
    const [orderId, setOrderId] = useState("");
    const [totalRevenue, setTotalRevenue] = React.useState("");
    const [defaultDate, setDefaultDate] = React.useState(Date());
    const [visible, setvisible] = useState(false);

    const { onChange, onSubmit, values } = useForm(handleSubmit, {
        brandId: "",
        stateId: "",
        fromDate: "",
        toDate: "",
        modelId: "",
        purchaseType: "",
        paymentStatus: "",
        paymentGateway: "",
        productType: "",
    });

    localStorage.setItem("brandId", values.brandId);
    localStorage.setItem("modelId", values.modelId);
    localStorage.setItem("stateId", values.stateId);
    localStorage.setItem("purchaseType", values.purchaseType);
    localStorage.setItem("paymentStatus", values.paymentStatus);
    localStorage.setItem("paymentGateway", values.paymentGateway);
    localStorage.setItem("productType", values.productType);


    const [getAllBookingForSalesReportRefetch] = useMutation(GETALLBOOKINGFORSALESREPORTREFETCH, {
        update(_, { data: { getAllBookingForSalesReportRefetch: reportRefetchData } }) {

            if (reportRefetchData) {

                const orderRes = reportRefetchData?.allBookingRes
                const totalRevenueRes = reportRefetchData?.totalRevenue

                setTimeout(() => {
                    setTotalRevenue(totalRevenueRes)
                    setOrder(orderRes)
                }, 1000);

            }

        },
        onError(err) {
            console.log(err.message);
        },
    });

    const [getAllBookingForSalesReport] = useMutation(GETALLBOOKINGFORSALESREPORT, {
        update(_, { data: { getAllBookingForSalesReport: reportData } }) {

            if (reportData) {

                const orderRes = reportData?.allBookingRes
                const totalRevenueRes = reportData?.totalRevenue

                setTimeout(() => {
                    setTotalRevenue(totalRevenueRes)
                    setOrder(orderRes)
                }, 1000);

            }

        },
        onError(err) {
            console.log(err.message);
        },
    });

    var { loading, data } = useQuery(GETALLBRANDS)
    const brands = data?.getAllBrands?.allVehicleRes;

    const brandId = localStorage.getItem("brandId");

    const { data: modelData } = useQuery(GETALLMODELBYBRANDID, { variables: { brandId: brandId } });
    const models = modelData?.getBrandById?.allVehicleModelRes;

    const handleChangeStartDate = date => {
        let newStartDate = new Date(date)
        newStartDate.setUTCHours(0, 0, 0, 0)
        console.log("start date", newStartDate)
        setStartDate(newStartDate)
    }

    const handleChangeEndDate = date => {
        let newEndDate = new Date(date)
        newEndDate.setHours(23, 59, 59, 999)
        console.log("end date", newEndDate)

        setEndDate(newEndDate)
    }


    const stateId = localStorage.getItem("stateId");
    const modelId = localStorage.getItem("modelId");
    const purchaseType = localStorage.getItem("purchaseType");
    const paymentStatus = localStorage.getItem("paymentStatus");
    const paymentGateway = localStorage.getItem("paymentGateway");
    const productType = localStorage.getItem("productType");



    useEffect(() => {
        // if (data) {

        //     setBrands(brand)
        // }
        if (dataState) {
            const stateRes = dataState?.getAllState?.allStateRes
            setStates(stateRes)
        }


    })

    useEffect(() => {

        getAllBookingForSalesReport({
            variables:
            {
                stateId: "",
                brandId: "",
                fromDate: startDate,
                toDate: endDate,
                modelId: "",
                purchaseType: "",
                paymentStatus: "",
                paymentGateway: "",
                productType: "",

            }
        })
    }, [])


    const { loading: loadingState, data: dataState } = useQuery(GETALLSTATES)




    // const { loading: orderLoading, data: orderData } = useQuery(FETCHORDERLIST)
    // const { loading: orderLoading, data: orderData } = useQuery(GETALLBOOKINGFORSALESREPORT,
    //     {
    //         variables:
    //         {
    //             stateId: "",
    //             brandId: "",
    //             fromDate: startDate,
    //             toDate: endDate,
    //             modelId: "",
    //             purchaseType: "",
    //             paymentStatus: "",
    //             paymentGateway: "",
    //             productType: "",

    //         }
    //     })



    // const [getDog, { loading: orderLoadingOnRevenueGenerate, error, data: orderDataOnRevenueGenerate }] = useLazyQuery(GETALLBOOKINGFORSALESREPORTREPORT);


    if (loading) {
        return "loading..."
    }
    if (loadingState) {
        return "loading..."
    }
    // if (orderLoading) {
    //     return "loading..."
    // }





    function handleSubmit() {
        getAllBookingForSalesReportRefetch({
            variables:
            {
                stateId: stateId,
                brandId: brandId,
                fromDate: startDate,
                toDate: endDate,
                modelId: modelId,
                purchaseType: purchaseType,
                paymentStatus: paymentStatus,
                paymentGateway: paymentGateway,
                productType: productType,
            }
        }
        )
        // getReport({
        //     variables:
        //     {
        //         stateId: stateId,
        //         brandId: brandId,
        //         fromDate: startDate,
        //         toDate: endDate,
        //         modelId: modelId,
        //         purchaseType: purchaseType,
        //         paymentStatus: paymentStatus,
        //         paymentGateway: paymentGateway,
        //         productType: productType,
        //     }
        // })
    }

    const toggleViewModal = () => setModal1(!modal1)

    const sendOrderId = (orderId) => {
        let orderToView = orders?.filter(item => {
            if (item.id === orderId) {
                return item
            }
        })
        setOrderId(orderToView)
    }

    const columns = [{
        dataField: 'orderId',
        text: 'Order Id',
        sort: true,
    }, {
        dataField: 'userName',
        text: 'User Name',
        sort: true
    }, {
        dataField: 'email',
        text: 'Email',
        sort: true
    }, {
        dataField: 'brandName',
        text: 'Brand Name',
        sort: true
    }, {
        dataField: 'modelName',
        text: 'Model Name',
        sort: true
    }, {
        dataField: 'planPrice',
        text: 'Plan Price',
        sort: true
    },
    {
        dataField: 'price',
        text: 'Paid Price',
        sort: true
    },
    {
        dataField: 'discount',
        text: 'Discount',
        sort: true
    },
    {
        dataField: 'promocodeApplied',
        text: 'Promocode',
        sort: true
    },
    {
        dataField: 'voucherCode',
        text: 'Voucher Code',
        sort: true
    },
    {
        dataField: 'status',
        text: 'Status',
        sort: true,
        formatter: (cellContent, row) => (
            <Badge
                className="font-size-12 badge-soft-"
                color={row.status === "failure" ? "danger" : "success"}
                pill
            >
                {row.status}
            </Badge>
        ),
    },
    {
        dataField: "view",
        isDummyField: true,
        text: "View Details",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
            <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={() => {
                    sendOrderId(row.id);
                    toggleViewModal()
                }

                }
            >
                View Details
            </Button >
        ),
    },
    ];


    const reports = [

        {
            icon: "bx bx-copy-alt",
            title: "Total Amount",
            value: totalRevenue,
            // badgeValue: "+ 0.2%",
            // color: "success",
            // desc: "From previous period",
        },
    ];


    return (
        <React.Fragment>
            <SalesReportListModal isOpen={modal1} toggle={toggleViewModal} orderId={orderId} />
            <div className="page-content">
                <MetaTags>
                    <title>Sales Report</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
                    <Row>
                        <div className="col-xl-3 col-sm-6">
                            <div className="mt-3">
                                <MiniWidget reports={reports} />
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="cust-table-wrap">
                                    <h4 className="card-title mb-3">Sales Report</h4>

                                    <TabContent activeTab={activeTab} className="p-3 pt-0">
                                        <TabPane tabId="1" id="all-order">
                                            <Form>
                                                <Row className="mb-0 align-items-center">
                                                    <div className="col-xl col-sm-6">
                                                        <FormGroup className="mt-3 mb-0">
                                                            <Label>From :</Label>
                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={handleChangeStartDate}
                                                                className="form-control"
                                                                placeholderText="Select date"
                                                            />
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-xl col-sm-6">
                                                        <FormGroup className="mt-3 mb-0">
                                                            <Label>To :</Label>
                                                            <DatePicker
                                                                selected={endDate}
                                                                onChange={handleChangeEndDate}
                                                                className="form-control"
                                                                placeholderText="Select date"
                                                            />
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-xl col-sm-6">
                                                        <FormGroup className="mt-3 mb-0">
                                                            <Label>Brand Name</Label>
                                                            <select className="form-control select2-search-disable" onChange={onChange}
                                                                name="brandId">
                                                                <option value="" disabled selected>Select Brand</option>
                                                                {
                                                                    brands?.map((brandItem, index) => (

                                                                        <option key={index} value={brandItem.id}>{brandItem?.brand}</option>
                                                                    ))

                                                                }
                                                            </select>
                                                        </FormGroup>
                                                    </div>


                                                    <div className="col-xl col-sm-6">
                                                        <FormGroup className="mt-3 mb-0">
                                                            <Label>Model Name</Label>
                                                            <select className="form-control select2-search-disable" onChange={onChange}
                                                                name="modelId">
                                                                <option value="" disabled selected>Select Model</option>
                                                                {
                                                                    models?.map((modelItem, index) => (

                                                                        <option key={index} value={modelItem.id}>{modelItem?.modelName}</option>
                                                                    ))

                                                                }
                                                            </select>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-xl col-sm-6">
                                                        <FormGroup className="mt-3 mb-0">
                                                            <Label>Purchase Type</Label>
                                                            <select className="form-control select2-search-disable" onChange={onChange} name="purchaseType">
                                                                <option value="" disabled selected>Select Purchase Type</option>
                                                                <option value="directDealer" >
                                                                    Direct Dealer
                                                                </option>
                                                                <option value="directCustomer" >
                                                                    Direct Customer
                                                                </option>
                                                                <option value="eAutogenWorkshop" >
                                                                    eAutogen Workshop
                                                                </option>
                                                                <option value="BSIWorkshop" >
                                                                    BSI Workshop
                                                                </option>

                                                            </select>
                                                        </FormGroup>
                                                    </div>

                                                </Row>

                                                <Row className="d-flex align-items-center" >
                                                    <div className="col-xl col-sm-6">
                                                        <FormGroup className="mt-0 mb-0">
                                                            <Label>Payment Status</Label>
                                                            <select className="form-control select2-search-disable" onChange={onChange} name="paymentStatus">
                                                                <option value="" disabled selected>Select Payment Status</option>
                                                                <option value="confirm" >
                                                                    Confirm
                                                                </option>
                                                                <option value="failure">Failure</option>
                                                            </select>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xl col-sm-6">
                                                        <FormGroup className="mt-0 mb-0">
                                                            <Label>Payment Gateway</Label>
                                                            <select className="form-control select2-search-disable" onChange={onChange} name="paymentGateway">
                                                                <option value="" disabled selected>Select Payment Type</option>
                                                                <option value="iPay88" >
                                                                    Ipay88
                                                                </option>
                                                                <option value="revPAY">RevPay</option>
                                                                <option value="credit">Credit</option>
                                                            </select>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xl col-sm-6">
                                                        <FormGroup className="mt-0 mb-0">
                                                            <Label>Product Type</Label>
                                                            <select className="form-control select2-search-disable" onChange={onChange} name="productType">
                                                                <option value="" disabled selected>Select Product Type</option>
                                                                <option value="298" >
                                                                    RM 298
                                                                </option>
                                                                <option value="528">RM 528</option>
                                                                <option value="1088">RM 1088</option>
                                                                <option value="1688">RM 1688</option>
                                                            </select>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xl col-sm-6">
                                                        <FormGroup className="mt-0 mb-0">
                                                            <Label>State</Label>
                                                            <select className="form-control select2-search-disable" onChange={onChange} name="stateId">
                                                                <option value="" disabled selected>Select State</option>
                                                                {
                                                                    states?.map((statesItem, index) => (

                                                                        <option key={index} value={statesItem.id}>{statesItem?.name}</option>
                                                                    ))

                                                                }
                                                            </select>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-xl col-sm-6 ">
                                                        <div className="mt-2">
                                                            <Button
                                                                type="button"
                                                                color="primary"
                                                                className="w-md"
                                                                style={{ width: "100%" }}
                                                                // onClick={handleSubmitSalesReport}
                                                                onClick={onSubmit}

                                                            >
                                                                Generate Report
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl  col-sm-6">
                                                        <div className="mt-2">
                                                            <ExportReport excelData={orders} fileName={"Sales Report"} />
                                                        </div>
                                                    </div>


                                                </Row>
                                            </Form>

                                            <BootstrapTable style={{ overflowX: "scroll" }} keyField='id' className="mt-4" data={orders} columns={columns} pagination={paginationFactory()} />

                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

SalesReport.propTypes = {
    orders: PropTypes.array,
    // onGetOrders: PropTypes.func,
}

// const mapStateToProps = ({ crypto }) => ({
//     orders: crypto.orders,
// })

// const mapDispatchToProps = dispatch => ({
//     onGetOrders: () => dispatch(getCryptoOrders()),
// })

// export default connect(
//     mapStateToProps,
//     // mapDispatchToProps
// )(withRouter(SalesReport))

export default SalesReport;
