import React, { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import { useFormik } from "formik"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Form,
  UncontrolledTooltip
} from "reactstrap"
import "./index.css"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ReactPaginate from 'react-paginate';


import { FETCHORDERLIST, GETALLSTATES, GETALLBRANDS, GETALLMODELBYBRANDID, GETALLSEARCHDEALER } from "../../../queries/userQueries"
import { DELETEBOOKING, UPDATEBOOKING, FETCHORDERLISTBYPAGINATION, FETCHQUOTEPLANPAGINATION, UPDATEPLAN, DELETEPLAN } from "../../../mutations/useMutations"
import { useQuery, useMutation } from "@apollo/react-hooks"
import DeleteModal from "../../../components/Common/DeleteModal"
import PlanModal from "./EcommerceOrdersModal";

const PlanList = props => {

  const [modal, setModal] = useState(false);
  const [order, setOrder] = React.useState([]);
  const [modal1, setModal1] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [bookingToDelete, setBookingToDelete] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [orderToEdit, setOrderToEdit] = useState(null);
  const [states, setStates] = React.useState([]);
  const [dealer, setDealer] = React.useState([]);
  const [dealerValue, setDealerValue] = React.useState("");
  const [dealerName, setDealerName] = React.useState("");
  const [dealerId, setDealerID] = React.useState("");

  const [isChecked, setIsChecked] = useState(false);

  const [brandvalue, setBrandvalue] = useState("");
  const [modelvalue, setModelvalue] = useState("");
  const [stateValue, setStatevalue] = useState("");

  const [brandNamevalue, setBrandNamevalue] = useState("");
  const [modelNamevalue, setModelNamevalue] = useState("");

  const [totalOrderLen, setTotalOrderLen] = useState("");

  const [yearvalue, setYearvalue] = useState("");

  const [pageNumber, setPageNumber] = useState(1);

  let userId = localStorage.getItem("userId")
  let role = localStorage.getItem("role")

  // We start with an empty list of items.
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  localStorage.setItem("brandId", brandvalue);
  localStorage.setItem("modelId", modelvalue);
  localStorage.setItem("stateId", stateValue);
  localStorage.setItem("owner_year", yearvalue);

  // Edit Booking
  const [editBooking] = useMutation(UPDATEPLAN, {
    update(_, { data: { updateBooking: bookingData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);

    },
    onError(err) {
      console.log(err.message);
    },
  });


  const [deletePlan] = useMutation(DELETEPLAN, {
    update(_, { data: { deletePlan: bookingData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);
    },
    onError(err) {
      console.log(err.message);
    },
  });

  const brandId = localStorage.getItem("brandId");
  const modelId = localStorage.getItem("modelId");
  const stateId = localStorage.getItem("stateId");

  function handleUpdateBooking(updateBooking) {
    editBooking(
      {
        variables: {
            id: updateBooking?.id,
            CoveredComponentsDetails: updateBooking?.CoveredComponentsDetails,
            EWPCategory: updateBooking?.EWPCategory,
            CoveredComponents: updateBooking?.CoveredComponents,
            NoOfCoveredComponents: updateBooking?.NoOfCoveredComponents,
            CarType: updateBooking?.CarType,
            CarAge: updateBooking?.CarAge,
            OdometerReading: updateBooking?.OdometerReading,
            CarBrandByCountry: updateBooking?.CarBrandByCountry,
            MaxPerVisit: updateBooking?.MaxPerVisit,
            MaxPerYear: updateBooking?.MaxPerYear,
            FinancialInstituitionsRate: updateBooking?.FinancialInstituitionsRate,
            DealersRate: updateBooking?.DealersRate,
            RetailRate: updateBooking?.RetailRate,
        }
      }
    )
  }


  const { data: dataBrand } = useQuery(GETALLBRANDS);
  const brand = dataBrand?.getAllBrands?.allVehicleRes;

  const { data: dataModel } = useQuery(GETALLMODELBYBRANDID, { variables: { brandId } });
  const model = dataModel?.getBrandById?.allVehicleModelRes;

  const [getAllBookingPagination] = useMutation(FETCHQUOTEPLANPAGINATION, {
    update(_, { data:{getAllQuotePlanPagination: reportData} }) {
      // update(_, { data: { AllQuotePlanList: reportData } }) {
      console.log("reportData",reportData?.AllQuotePlanList, reportData?.QuotePlanLen);
      if (reportData) {

        const orderRes = reportData?.AllQuotePlanList
        const totalOrderLen = reportData?.QuotePlanLen

        setTimeout(() => {
          setTotalOrderLen(totalOrderLen)
          setOrder(orderRes)
        }, 1000);

      }

    },
    onError(err) {
      console.log("err.message",err.message);
    },
  });

  useEffect(() => {
    // if (data) {
    //   const orderRes = data?.getAllBooking?.allBookingRes
    //   setOrderLen(orderRes)
    // }
    if (dataState) {
      const stateRes = dataState?.getAllState?.allStateRes
      setStates(stateRes)
    }
    if (dealerData) {
      const dealerRes = dealerData?.searchDealer?.searchResults
      setDealer(dealerRes)
    }

  })

  useEffect(() => {

    getAllBookingPagination({
      variables:
      {
        page: 1,
        limit: 10,
        userId: userId
      }
    })
  }, [])

  let itemsPerPage = 10

  useEffect(() => {
    setPageCount(Math.ceil(totalOrderLen / itemsPerPage));
  }, [totalOrderLen]);

  // validation

  const validationDropDown = {
    initialValues: {
      brandName: (orderToEdit && orderToEdit?.brandName) || '',
      modelName: (orderToEdit && orderToEdit?.modelName) || '',
      state: (orderToEdit && orderToEdit?.state) || '',
      year: (orderToEdit && orderToEdit?.year) || '',
    }
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: (orderToEdit && orderToEdit?.id) || "",
      CoveredComponentsDetails: (orderToEdit && orderToEdit?.CoveredComponentsDetails) || [],
      EWPCategory: (orderToEdit && orderToEdit?.EWPCategory) || "",
      CoveredComponents: (orderToEdit && orderToEdit?.CoveredComponents) || "",
      NoOfCoveredComponents: (orderToEdit && orderToEdit?.NoOfCoveredComponents) || "",
      CarType: (orderToEdit && orderToEdit?.CarType) || "Private-use cars only",
      CarAge: (orderToEdit && orderToEdit?.CarAge) || "",
      OdometerReading: (orderToEdit && orderToEdit?.OdometerReading) || "up to 200000",
      CarBrandByCountry: (orderToEdit && orderToEdit?.CarBrandByCountry) || "",
      MaxPerVisit: (orderToEdit && orderToEdit?.MaxPerVisit) || "",
      MaxPerYear: (orderToEdit && orderToEdit?.MaxPerYear) || "",
      FinancialInstituitionsRate: (orderToEdit && orderToEdit?.FinancialInstituitionsRate) || "",
      DealersRate: (orderToEdit && orderToEdit?.DealersRate) || "",
      RetailRate: (orderToEdit && orderToEdit?.RetailRate) || "",
    },
    onSubmit: (values) => {
      if (isEdit) {

        const updateBooking = {
          id: values?.id,
          CoveredComponentsDetails: values?.CoveredComponentsDetails,
          EWPCategory: values?.EWPCategory,
          CoveredComponents: values?.CoveredComponents,
          NoOfCoveredComponents: values?.NoOfCoveredComponents,
          CarType: values?.CarType,
          CarAge: values?.CarAge,
          OdometerReading: values?.OdometerReading,
          CarBrandByCountry: values?.CarBrandByCountry,
          MaxPerVisit: values?.MaxPerVisit,
          MaxPerYear: values?.MaxPerYear,
          FinancialInstituitionsRate: values?.FinancialInstituitionsRate,
          DealersRate: values?.DealersRate,
          RetailRate: values?.RetailRate,
        };

        handleUpdateBooking(updateBooking);
        validation.resetForm();
      }

      toggle();
    },
  });

  const { loading: loadingState, data: dataState } = useQuery(GETALLSTATES)
  // const { loading, data } = useQuery(FETCHORDERLIST)
  const { loading: dealerLoading, data: dealerData } = useQuery(GETALLSEARCHDEALER)

  if (loadingState) {
    return "loading..."
  }

  // if (loading) {
  //   return "loading..."
  // }

  if (dealerLoading) {
    return "loading..."
  }

  const orders = order
  const selectRow = {
    mode: "checkbox",
  };

  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalOrderLen, // replace later with size(orders),
    custom: true,
  };
  const { SearchBar } = Search;

  const toggleViewModal = () => setModal1(!modal1)

  const sendOrderId = (orderId) => {
    let orderToView = orders?.filter(item => {
      if (item.id === orderId) {
        return item
      }
    })
    setOrderId(orderToView)
  }

  //delete booking

  const onClickDelete = (booking) => {

    setBookingToDelete(booking)
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (bookingToDelete) {
      deletePlan({
        variables: {
          planId: bookingToDelete.id,
        },
      });
      // onPaginationPageChange(1);
      setDeleteModal(false);
    }
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      // setUser(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = async arg => {

    const orderData = arg;

    setOrderToEdit({
      id: orderData?.id,
      CoveredComponentsDetails: orderData?.CoveredComponentsDetails,
      EWPCategory: orderData?.EWPCategory,
      CoveredComponents: orderData?.CoveredComponents,
      NoOfCoveredComponents: orderData?.NoOfCoveredComponents,
      CarType: orderData?.CarType,
      CarAge: orderData?.CarAge,
      OdometerReading: orderData?.OdometerReading,
      CarBrandByCountry: orderData?.CarBrandByCountry,
      MaxPerVisit: orderData?.MaxPerVisit,
      MaxPerYear: orderData?.MaxPerYear,
      FinancialInstituitionsRate: orderData?.FinancialInstituitionsRate,
      DealersRate: orderData?.DealersRate,
      RetailRate: orderData?.RetailRate,
    });

    setIsEdit(true);

    toggle();
  };

  const handleOnChange = () => {

    setIsChecked(!isChecked);

  };

  const handleOnChangeDealer = (e) => {

    const dealerRes = dealer?.filter((dealerItem) => {
      if (dealerItem.value === e.target.value) {
        return dealerItem;
      }

    })

    setDealerValue(dealerRes[0].dealerCode);
    setDealerName(dealerRes[0].label);
    setDealerID(dealerRes[0].value);


  };

  let EcommerceOrderColumns
  // if (role == "manager" || role == "sales") {
  //   EcommerceOrderColumns = [{
  //     dataField: 'orderId',
  //     text: 'Order Id',
  //     sort: true,
  //   }, {
  //     dataField: 'userName',
  //     text: 'User Name',
  //     sort: true
  //   }, {
  //     dataField: 'email',
  //     text: 'Email',
  //     sort: true
  //   }, {
  //     dataField: 'brandName',
  //     text: 'Brand Name',
  //     sort: true
  //   }, {
  //     dataField: 'modelName',
  //     text: 'Model Name',
  //     sort: true
  //   },
  //   {
  //     dataField: 'planName',
  //     text: 'Plan',
  //     sort: true
  //   }, {
  //     dataField: 'planPrice',
  //     text: 'Plan Price',
  //     sort: true
  //   },
  //   {
  //     dataField: 'price',
  //     text: 'Paid Price',
  //     sort: true
  //   },
  //   {
  //     dataField: 'discount',
  //     text: 'Discount',
  //     sort: true
  //   },
  //   {
  //     dataField: 'promocodeApplied',
  //     text: 'Promocode',
  //     sort: true
  //   },
  //   // {
  //   //   dataField: 'voucherCode',
  //   //   text: 'Voucher Code',
  //   //   sort: true
  //   // },
  //   {
  //     dataField: 'status',
  //     text: 'Status',
  //     sort: true,
  //     formatter: (cellContent, row) => (
  //       <Badge
  //         className="font-size-12 badge-soft-"
  //         color={row.status === "failure" ? "danger" : "success"}
  //         pill
  //       >
  //         {row.status}
  //       </Badge>
  //     ),
  //   },
  //   {
  //     dataField: "view",
  //     isDummyField: true,
  //     text: "View Details",
  //     sort: true,
  //     // eslint-disable-next-line react/display-name
  //     formatter: (cellContent, row) => (
  //       <Button
  //         type="button"
  //         color="primary"
  //         className="btn-sm btn-rounded"
  //         onClick={() => {
  //           sendOrderId(row.id);
  //           toggleViewModal()
  //         }

  //         }
  //       >
  //         View Details
  //       </Button >
  //     ),
  //   },
  //   {
  //     dataField: "action",
  //     isDummyField: true,
  //     text: "Action",
  //     // eslint-disable-next-line react/display-name
  //     formatter: (cellContent, booking) => (
  //       <>
  //         <div className="d-flex gap-3">
  //           <Link
  //             to="#"
  //             className="text-success"
  //             onClick={() => handleOrderClick(booking)}
  //           >
  //             <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
  //             <UncontrolledTooltip placement="top" target="edittooltip">
  //               Edit
  //             </UncontrolledTooltip>
  //           </Link>
  //           {role == "manager" || role == "sales" ? "" : (<Link
  //             to="#"
  //             className="text-danger"
  //             onClick={() => onClickDelete(booking)}
  //           >
  //             <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
  //             <UncontrolledTooltip placement="top" target="deletetooltip">
  //               Delete
  //             </UncontrolledTooltip>
  //           </Link>)}

  //         </div>
  //       </>
  //     ),
  //   },
  //   ];
  // } else {
  // }
  EcommerceOrderColumns = [
    {
      dataField:"EWPCategory",
      text:"EWPCategory",
      sort:true,
    },
    {
      dataField:"CoveredComponents",
      text:"CoveredComponents",
      sort:true,
    },
    {
      dataField:"NoOfCoveredComponents",
      text:"NoOfCoveredComponents",
      sort:true,
    },
    {
      dataField:"CarType",
      text:"CarType",
      sort:true,
    },
    {
      dataField:"CarAge",
      text:"CarAge",
      sort:true,
    },
    {
      dataField:"OdometerReading",
      text:"OdometerReading",
      sort:true,
    },
    {
      dataField:"CarBrandByCountry",
      text:"CarBrandByCountry",
      sort:true,
    },
    {
      dataField:"MaxPerVisit",
      text:"MaxPerVisit",
      sort:true,
    },
    {
      dataField:"MaxPerYear",
      text:"MaxPerYear",
      sort:true,
    },
    {
      dataField:"FinancialInstituitionsRate",
      text:"FinancialInstituitionsRate",
      sort:true,
    },
    {
      dataField:"DealersRate",
      text:"DealersRate",
      sort:true,
    },
    {
      dataField:"RetailRate",
      text:"RetailRate",
      sort:true,
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => {
            sendOrderId(row.id);
            toggleViewModal()
          }}
        >
          View Details
        </Button >
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, booking) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleOrderClick(booking)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(booking)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "EWPCategory",
      order: "desc",
    },
  ];

  const handlePageClick = (event) => {
    getAllBookingPagination({
      variables:
      {
        page: parseInt(event.selected) + 1,
        limit: 10,
        userId: userId
      }
    })
  };

  return (
    <React.Fragment>
      <PlanModal isOpen={modal1} toggle={toggleViewModal} orderId={orderId} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      {
        <div className="page-content">

          <Container fluid>
            <h2>Plan List</h2>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={EcommerceOrderColumns}
                      data={orders}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        // {()=>{setPageNumber(paginationProps)}}
                        <ToolkitProvider
                          keyField="id"
                          data={orders}
                          columns={EcommerceOrderColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">

                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="id"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    // ref={node}
                                    />
                                  </div>
                                  <Modal isOpen={modal} toggle={toggle}>
                                    <ModalHeader toggle={toggle} tag="h4">
                                      {!!isEdit ? "Edit Plan" : "Add Plan"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <Form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                          validation.handleSubmit();
                                          return false;
                                        }}
                                      >
                                        <Row form>
                                          <Col className="col-12">
                                            <div className="mb-3">
                                              <Label className="form-label">Plan Id</Label>
                                              <Input
                                                name="id"
                                                type="text"
                                                onChange={validation.handleChange}
                                                value={validation.values.id || ""}

                                                disabled
                                              />

                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">EWP Category</Label>
                                              <Input
                                                name="EWPCategory"
                                                type="text"
                                                value={validation.values.EWPCategory || ""}
                                                onChange={validation.handleChange}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">Car Type</Label>
                                              <Input
                                                name="CarType"
                                                type="text"
                                                value={validation.values.CarType || ""}
                                                onChange={validation.handleChange}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">Car Age</Label>
                                              <Input
                                                name="CarAge"
                                                type="select"
                                                className="form-select"
                                                value={validation.values.CarAge || ""}
                                                onChange={(e) => {
                                                  setBrandvalue(e.target.value);
                                                }}
                                              >
                                                <option value="">Select car age</option>
                                                <option value={'up to 10 years'}>up to 10 years</option>
                                                <option value={'11 to 16 years'}>11 to 16 years</option>
                                              </Input>
                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">Odometer Reading</Label>
                                              <Input
                                                name="OdometerReading"
                                                type="text"
                                                onChange={validation.handleChange}
                                                value={validation.values.OdometerReading || ""}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">Car Brand By Country</Label>
                                              <Input
                                                name="CarBrandByCountry"
                                                type="select"
                                                className="form-select"
                                                value={validation.values.CarBrandByCountry || ""}
                                                onChange={(e) => {
                                                  setBrandvalue(e.target.value);
                                                }}
                                              >
                                                <option value="">Select car brand by country</option>
                                                <option value={'China, Korea & Other Cars'}>China, Korea & Other Cars</option>
                                                <option value={'Continental Vehicle Model'}>Continental Vehicle Model</option>
                                                <option value={'Luxury Continental'}>Luxury Continental</option>
                                                <option value={'National & Japanese Vehicle Model'}>National & Japanese Vehicle Model</option>
                                              </Input>
                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">Max. Per Visit</Label>
                                              <Input
                                                name="MaxPerVisit"
                                                type="text"
                                                onChange={validation.handleChange}
                                                value={validation.values.MaxPerVisit || ""}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">Max. Per Year</Label>
                                              <Input
                                                name="MaxPerYear"
                                                type="text"
                                                onChange={validation.handleChange}
                                                value={validation.values.MaxPerYear || ""}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">Dealers Rate</Label>
                                              <Input
                                                name="DealersRate"
                                                type="text"
                                                onChange={validation.handleChange}
                                                value={validation.values.DealersRate || ""}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">Retail Rate</Label>
                                              <Input
                                                name="RetailRate"
                                                type="text"
                                                onChange={validation.handleChange}
                                                value={validation.values.RetailRate || ""}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Form>
                                    </ModalBody>
                                  </Modal>
                                </Col>
                              </Row>
                              <ReactPaginate
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={pageCount}
                                previousLabel="< previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                                style={{ justifyContent: "end", marginTop: "5px", flexWrap: "wrap" }}

                              />
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      }
    </React.Fragment>
  )
}

// EcommerceCustomers.propTypes = {
//   customers: PropTypes.array,
//   onGetCustomers: PropTypes.func,
//   onAddNewCustomer: PropTypes.func,
//   onDeleteCustomer: PropTypes.func,
//   onUpdateCustomer: PropTypes.func,
// };

export default PlanList
