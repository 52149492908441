import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './User.css'
import { useFormik } from "formik"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalHeader,
    ModalBody,
    UncontrolledDropdown,
    Input,
    FormFeedback,
    Label,
    Form,
} from "reactstrap"
import { Dropdown } from 'react-bootstrap'
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { Link } from 'react-router-dom'
import { CardTitle, CardSubtitle, UncontrolledTooltip } from "reactstrap"
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../../components/Common/DeleteModal"
import { GET_ROLE_AND_PERMISSION_LIST } from "../../../queries/userQueries"
import { DELETE_ROLE_AND_PERMISSION, CREATE_ROLE_AND_PERMISSION, UPDATE_ROLE_AND_PERMISSION } from "../../../mutations/useMutations"
import { useQuery, useMutation } from "@apollo/react-hooks"
import MultiSelectAll from "./MultiSelectAll"
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

import * as Yup from "yup";

const RoleandPermission = props => {
    const myArray = localStorage.getItem("permission");
    const permitList =myArray?.split(",")

    const [modal, setModal] = useState(false);
    const [roleAndPermission, setRoleAndPermission] = React.useState([]);
    const [modal1, setModal1] = useState(false);
    const [userId, setUserId] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [roleAndPermissionToDelete, setRoleAndPermissionToDelete] = useState(null);
    const [userToEdit, setUserToEdit] = useState(null);
    const [roleAndPerimissionToEdit, setRoleAndPerimissionToEdit] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const [userList, setUserList] = useState([]);
    const [permissionToSendMultiSelectAll, setPermissionToSendMultiSelectAll] = useState([]);

    const [updatedPermissions, setUpdatedPermissions] = useState([]);


    const [updateRoleAndPermission] = useMutation(UPDATE_ROLE_AND_PERMISSION, {
        update(_, { data: { updateRoleAndPermission: updateRoleAndPermissionData } }) {

            setTimeout(() => {
                window.location.reload();
            }, 0);

        },
        onError(err) {
            console.log(err.message);
        },
    });

    // Add Role And Permission
    const [createRoleAndPermission] = useMutation(CREATE_ROLE_AND_PERMISSION, {
        update(_, { data: { createRoleAndPermission: createRoleAndPermissionData } }) {

            setTimeout(() => {
                window.location.reload();
            }, 0);

        },
        onError(err) {
            toast(err.message);
        },
    });


    function handleUpdateRole(updateRole) {

        let updatedPermissionsLen = updatedPermissions.length
        let permissionType
        if (updatedPermissionsLen >= 6) {
            permissionType = "All"
        } else {
            permissionType = "Limited"
        }

        let permissionArr = []
        updatedPermissions.map((updatedPermissionsItem) => {
            if (updatedPermissionsItem.value !== "*") {
                permissionArr.push(updatedPermissionsItem.value)
            }

        })

        updateRoleAndPermission(
            {
                variables: {
                    id: updateRole?.id,
                    role: updateRole?.role,
                    permissionType: permissionType,
                    permission: permissionArr,
                }

            }
        )
    }

    function handleAddRole(addRole) {

        let updatedPermissionsLen = updatedPermissions.length
        let permissionType
        if (updatedPermissionsLen >= 6) {
            permissionType = "All"
        } else {
            permissionType = "Limited"
        }

        let permissionArr = []
        updatedPermissions.map((updatedPermissionsItem) => {
            if (updatedPermissionsItem.value !== "*") {
                permissionArr.push(updatedPermissionsItem.value)
            }

        })

        createRoleAndPermission(
            {
                variables: {
                    role: addRole?.role,
                    permissionType: permissionType,
                    permission: permissionArr,
                }

            }
        )
    }

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            role: (roleAndPerimissionToEdit && roleAndPerimissionToEdit?.role) || '',
            permissionType: (roleAndPerimissionToEdit && roleAndPerimissionToEdit?.permissionType) || '',
            permission: (roleAndPerimissionToEdit && roleAndPerimissionToEdit?.permission) || '',
        },
        validationSchema: Yup.object({
            role: Yup.string().required("Please Select Role"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updateRole = {
                    id: roleAndPerimissionToEdit?.id,
                    role: values?.role,
                    permissionType: values?.permissionType,
                    permission: values?.permission,
                };

                handleUpdateRole(updateRole);
                validation.resetForm();
            }
            else {
                const addRole = {
                    role: values["role"],
                };
                // save new order
                handleAddRole(addRole)
                validation.resetForm();
            }
            toggle();
        },
    });

    const [deleteRoleAndPermission] = useMutation(DELETE_ROLE_AND_PERMISSION, {
        update(_, { data: { deleteRoleAndPermission: roleData } }) {

            setTimeout(() => {
                window.location.reload();
            }, 0);
        },
        onError(err) {
            console.log(err.message);
        },
    });

    useEffect(() => {
        if (data) {
            const roleAndPermissionRes = data?.getAllRoleAndPermission?.roleAndPermissionRes
            setRoleAndPermission(roleAndPermissionRes)
        }
    })

    const { loading, data } = useQuery(GET_ROLE_AND_PERMISSION_LIST)
    if (loading) {
        return "loading..."
    }

    const roleAndPermissions = roleAndPermission


    const pageOptions = {
        sizePerPage: 10,
        totalSize: roleAndPermissions.length, // replace later with size(orders),
        custom: true,
    };
    const { SearchBar } = Search;

    const toggleViewModal = () => setModal1(!modal1)

    const sendUserId = (userId) => {
        let userToView = roleAndPermissions?.filter(item => {
            if (item.id === userId) {
                return item
            }
        })
        setUserId(userToView)
    }

    const toggle = () => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    };


    const handleOrderClick = arg => {

        const roleData = arg;
        setPermissionToSendMultiSelectAll(roleData?.permission)
        setRoleAndPerimissionToEdit({
            id: roleData?.id,
            role: roleData?.role,
            permissionType: roleData?.permissionType,
            permission: roleData?.permission,
        });

        setIsEdit(true);

        toggle();
    };

    //delete order

    const onClickDelete = (roleAndPermission) => {

        setRoleAndPermissionToDelete(roleAndPermission)
        setDeleteModal(true);
    };

    const handleDeleteRoleAndPermission = () => {

        if (roleAndPermissionToDelete) {
            deleteRoleAndPermission({
                variables: {
                    id: roleAndPermissionToDelete.id,
                },
            });
            // onPaginationPageChange(1);
            setDeleteModal(false);
        }
    };

    // let permissionString = ""
    const EcommerceUserColumns = [{
        dataField: 'role',
        text: 'Roles',
        sort: true,
    }, {
        dataField: 'permission',
        text: 'Permission',
        sort: true,
        // formatter: (cellContent, row) => (
        //     // {
        //     //     row.permission.map((permissionItem) => {
        //     //         // if(permissionItem){
        //     //         permissionString = permissionString + permissionItem
        //     //         // }

        //     //     })

        //     // }
        //     <Label
        //         className="font-size-12 badge-soft-"
        //         // color={row.status === "inactive" ? "danger" : "success"}
        //         pill
        //     >
        //         {row.permission}
        //     </Label>
        // ),
        formatter: (value, row) => {
            return row.permissionType + " " + "(" + value + "" + ") "
        },
    },
    {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, roleAndPermission) => (
            <>
                <div className="d-flex gap-3">
                {
                permitList?.find((task)=> task === "Edit") == "Edit" && (
                    <Link
                        to="#"
                        className="text-success"
                        onClick={() => handleOrderClick(roleAndPermission)}
                    >
                        <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                        <UncontrolledTooltip placement="top" target="edittooltip">
                            Edit
                        </UncontrolledTooltip>
                    </Link>
                )}
                {
                    permitList?.find((task)=> task === "Delete") == "Delete" && (
                    <Link
                        to="#"
                        className="text-danger"
                        onClick={() => onClickDelete(roleAndPermission)}
                    >
                        <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                        <UncontrolledTooltip placement="top" target="deletetooltip">
                            Delete
                        </UncontrolledTooltip>
                    </Link>
                )}
                </div>
            </>
        ),
    },
    ];


    //add User
    const handleCustomerClicks = () => {
        setUserList("");
        setIsEdit(false);
        toggle();
    };

    const defaultSorted = [
        {
            dataField: "orderId",
            order: "desc",
        },
    ];

    const handleClick = (permissionArrItem) => {
        setUpdatedPermissions(permissionArrItem)
    }

    return (
        <React.Fragment>
            <ToastContainer />
            {/* <EcommerceUserListModal isOpen={modal1} toggle={toggleViewModal} userId={userId} /> */}
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteRoleAndPermission}
                onCloseClick={() => setDeleteModal(false)}
            />
            {
                loading ?
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> :
                    <div className="page-content">

                        <Container fluid>
                            {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
                            <h2>Role and Permission</h2>
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField="id"
                                                columns={EcommerceUserColumns}
                                                data={roleAndPermissions}
                                            >
                                                {

                                                    ({ paginationProps, paginationTableProps }) => (
                                                        <ToolkitProvider
                                                            keyField="id"
                                                            data={roleAndPermissions}
                                                            columns={EcommerceUserColumns}
                                                            bootstrap4
                                                            search
                                                        >
                                                            {toolkitProps => (
                                                                <React.Fragment>
                                                                    <Row className="mb-2">
                                                                        <Col sm="4">
                                                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                                                <div className="position-relative">
                                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                                    <i className="bx bx-search-alt search-icon" />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        {
                                                                        permitList?.find((task)=> task == "Create") == "Create" && (
                                                                            <Col sm="8">
                                                                                <div className="text-sm-end">
                                                                                    <Button
                                                                                        type="button"
                                                                                        color="success"
                                                                                        className="btn-rounded  mb-2 me-2"
                                                                                        onClick={handleCustomerClicks}
                                                                                    >
                                                                                        <i className="mdi mdi-plus me-1" />
                                                                                        Add New Role
                                                                                    </Button>
                                                                                </div>
                                                                            </Col>
                                                                        )}
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl="12">
                                                                            <div className="table-responsive">
                                                                                <BootstrapTable
                                                                                    keyField="id"
                                                                                    responsive
                                                                                    bordered={false}
                                                                                    striped={false}
                                                                                    defaultSorted={defaultSorted}
                                                                                    classes={
                                                                                        "table align-middle table-nowrap table-check"
                                                                                    }
                                                                                    headerWrapperClasses={"table-light"}
                                                                                    {...toolkitProps.baseProps}
                                                                                    {...paginationTableProps}
                                                                                // ref={node}
                                                                                />
                                                                            </div>
                                                                            <Modal isOpen={modal} toggle={toggle}>
                                                                                <ModalHeader toggle={toggle} tag="h4">
                                                                                    {!!isEdit ? "Edit Role" : "Add New Role"}
                                                                                </ModalHeader>
                                                                                <ModalBody>
                                                                                    <Form
                                                                                        onSubmit={(e) => {
                                                                                            e.preventDefault();
                                                                                            validation.handleSubmit();
                                                                                            return false;
                                                                                        }}
                                                                                    >
                                                                                        <Row form>
                                                                                            <Col className="col-12">


                                                                                                <div className="mb-3">
                                                                                                    <Label className="form-label">Role</Label>
                                                                                                    <Input
                                                                                                        name="role"
                                                                                                        type="select"
                                                                                                        className="form-select"
                                                                                                        onChange={validation.handleChange}
                                                                                                        onBlur={validation.handleBlur}
                                                                                                        value={
                                                                                                            validation.values.role || ""
                                                                                                        }
                                                                                                    >
                                                                                                        <option>Select Role</option>
                                                                                                        <option>Super Manager</option>
                                                                                                        <option>Manager</option>
                                                                                                        <option>Sales</option>
                                                                                                        <option>Dealer</option>
                                                                                                        <option>Staff</option>

                                                                                                    </Input>
                                                                                                    {validation.touched.role && validation.errors.role ? (
                                                                                                        <FormFeedback type="invalid">{validation.errors.role}</FormFeedback>
                                                                                                    ) : null}
                                                                                                </div>

                                                                                                <div className="mb-3">
                                                                                                    <MultiSelectAll options={permissionToSendMultiSelectAll} handleClickMultiSelect={handleClick} />
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <div className="text-end">
                                                                                                    <button
                                                                                                        type="submit"
                                                                                                        className="btn btn-success save-user"
                                                                                                    >
                                                                                                        Save
                                                                                                    </button>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Form>
                                                                                </ModalBody>
                                                                            </Modal>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="align-items-md-center mt-30">
                                                                        <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            )}
                                                        </ToolkitProvider>
                                                    )}
                                            </PaginationProvider>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
            }
        </React.Fragment >
    )
}

RoleandPermission.propTypes = {
}

export default RoleandPermission