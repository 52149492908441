import React, { useEffect, useState, useRef } from "react";
import { withRouter, Link } from "react-router-dom";

import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import * as moment from "moment";
import * as Yup from "yup";
import MetaTags from 'react-meta-tags';

import { CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";

import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "store/actions";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider, SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";

import { useSelector, useDispatch } from "react-redux"
import { GETALLINSPECTIONCENTER, GETALLSTATES } from "../../../queries/userQueries"
import { DELETEINSPECTION, UPDATEINSPECTION, CREATEINSPECTION } from "../../../mutations/useMutations"
import { useQuery, useMutation } from "@apollo/react-hooks"
import DeleteModal from "../../../components/Common/DeleteModal";
import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "store/e-commerce/actions";

import EcommerceInspectionListModal from "./EcommerceInspectionListModal";


const Inspectioncenter = props => {
  const myArray = localStorage.getItem("permission");
  const permitList = myArray?.split(",")

  const [modal, setModal] = useState(false);
  const [order, setOrder] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [modal1, setModal1] = useState(false);
  const [inspectionId, setInspectionId] = useState("");
  const [inspectionToDelete, setInspectionToDelete] = useState(null);
  const [inspectionToEdit, setInspectionToEdit] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [inspectionList, setInspectionList] = useState([]);

  // Edit Inspection
  const [editInspection] = useMutation(UPDATEINSPECTION, {
    update(_, { data: { updateInspection: inspectionData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);

    },
    onError(err) {
      console.log(err.message);
    },
  });

  // Add Inspection
  const [addInspection] = useMutation(CREATEINSPECTION, {
    update(_, { data: { createInspection: inspectionData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);

    },
    onError(err) {
      console.log(err.message);
    },
  });


  function handleUpdateInspection(updateInspection) {

    editInspection(
      {
        variables: {

          id: updateInspection?.id,
          stateId: updateInspection?.state,
          name: updateInspection?.name,
          address: updateInspection?.address,
          telephone: updateInspection?.telephone,
          email: updateInspection?.email,
          dealerType: updateInspection?.dealertype,
          dealcode: updateInspection?.dealcode,
          dealerNumber: updateInspection?.dealerNumber,
          channel: updateInspection?.channel,
          // mobile: updateInspection?.mobile,
          // fax: updateInspection?.fax,
          // area: updateInspection?.area,
        }

      }
    )
  }

  function handleAddInspection(addInspectionData) {

    console.log("newDealer", addInspectionData)

    addInspection(
      {
        variables: {
          stateId: addInspectionData?.stateId,
          name: addInspectionData?.name,
          address: addInspectionData?.address,
          telephone: addInspectionData?.telephone,
          email: addInspectionData?.email,
          dealerType: addInspectionData?.dealertype,
          dealcode: addInspectionData?.dealcode,
          dealerNumber: addInspectionData?.dealerNumber,
          fax: addInspectionData?.fax,
          channel: addInspectionData?.channel,

        }

      }
    )
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (inspectionToEdit && inspectionToEdit?.name) || '',
      email: (inspectionToEdit && inspectionToEdit?.email) || '',
      telephone: (inspectionToEdit && inspectionToEdit?.telephone) || '',
      dealcode: (inspectionToEdit && inspectionToEdit?.dealcode) || '',
      state: (inspectionToEdit && inspectionToEdit?.state) || '',
      dealernumber: (inspectionToEdit && inspectionToEdit?.dealernumber) || '',
      channel: (inspectionToEdit && inspectionToEdit?.channel) || '',
      dealertype: (inspectionToEdit && inspectionToEdit?.dealertype) || '',
      address: (inspectionToEdit && inspectionToEdit?.address) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      email: Yup.string().required("Please Enter Email"),
      telephone: Yup.string().required("Please Enter Telephone Number"),
      state: Yup.string().required("Please Select State"),
    }),
    onSubmit: (values) => {
      console.log("values", values)
      if (isEdit) {
        const updateInspection = {
          id: inspectionToEdit?.id,
          name: values?.name,
          email: values?.email,
          telephone: values?.telephone,
          dealcode: values?.dealcode,
          state: values?.state,
          dealerNumber: values?.dealernumber,
          channel: values?.channel,
          dealertype: values?.dealertype,
          address: values?.address,
        };
        // update order
        // dispatch(onUpdateOrder(updateOrder));
        handleUpdateInspection(updateInspection);
        validation.resetForm();
      }
      else {

        const newInspection = {
          stateId: values["state"],
          name: values["name"],
          email: values["email"],
          telephone: values["telephone"],
          dealcode: values["dealcode"],
          state: values["state"],
          dealerNumber: values["dealernumber"],
          channel: values["channel"],
          dealertype: values["dealertype"],
          address: values["address"],
        };
        // save new order
        handleAddInspection(newInspection);
        validation.resetForm();
      }
      toggle();
    },
  });


  const [deleteInspection] = useMutation(DELETEINSPECTION, {
    update(_, { data: { deleteInspection: inspectionData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);
    },
    onError(err) {
      console.log(err.message);
    },
  });


  useEffect(() => {
    if (data) {
      const orderRes = data?.getAllInspection?.allInspectionRes
      setOrder(orderRes)
    }
    if (dataState) {
      const stateRes = dataState?.getAllState?.allStateRes
      setStates(stateRes)
    }
  })

  const { loading, data } = useQuery(GETALLINSPECTIONCENTER)
  const { loading: loadingState, data: dataState } = useQuery(GETALLSTATES)

  if (loading) {
    return "loading..."
  }
  if (loadingState) {
    return "loading..."
  }

  const orders = order
  const selectRow = {
    mode: "checkbox",
  };






  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: orders.length, // replace later with size(orders),
    custom: true,
  };
  const { SearchBar } = Search;



  const toggleViewModal = () => setModal1(!modal1)

  const sendInspectionId = (inspectionId) => {
    let inspectionToView = orders?.filter(item => {
      if (item.id === inspectionId) {
        return item
      }
    })
    setInspectionId(inspectionToView)
  }


  const toggle = () => {
    if (modal) {
      setModal(false);
      // setUser(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {

    const inspectionData = arg;

    console.log("inspectionData", inspectionData)

    let statesName = states?.filter((stateItem) => {
      if (stateItem.id === inspectionData?.stateId) {
        return stateItem;
      }
    });

    console.log("statesName", statesName)

    setInspectionToEdit({
      id: inspectionData?.id,
      name: inspectionData?.name,
      email: inspectionData?.email,
      telephone: inspectionData?.telephone,
      dealcode: inspectionData?.dealcode,
      state: statesName[0].id,
      dealernumber: inspectionData?.dealerNumber,
      dealertype: inspectionData?.dealerType,
      address: inspectionData?.address,
      channel: inspectionData?.channel,

    });

    setIsEdit(true);

    toggle();
  };

  //delete inspection

  const onClickDelete = (inspection) => {

    setInspectionToDelete(inspection)
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (inspectionToDelete) {
      deleteInspection({
        variables: {
          inspectionId: inspectionToDelete.id,
        },
      });
      // onPaginationPageChange(1);
      setDeleteModal(false);
    }
  };

  const EcommerceOrderColumns = [{
    dataField: 'id',
    text: 'Inspection Id',
    sort: true,
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true
  }, {
    dataField: 'address',
    text: 'Address',
    sort: true
  }, {
    dataField: 'telephone',
    text: 'Telephone',
    sort: true
  }, {
    dataField: 'email',
    text: 'Email',
    sort: true
  }, {
    dataField: 'dealerType',
    text: 'Dealer Type',
    sort: true
  }, {
    dataField: 'stateName',
    text: 'State',
    sort: true
  }, {
    dataField: 'dealcode',
    text: 'Dealer Code',
    sort: true

  }, {
    dataField: 'dealerNumber',
    text: 'Dealer Number',
    sort: true
  }, {
    dataField: 'channel',
    text: 'Channel',
    sort: true
  },
  {
    dataField: "view",
    isDummyField: true,
    text: "View Details",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (
      <>
        {
          permitList?.find((task) => task == "View") == "View" ? (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                sendInspectionId(row.id);
                toggleViewModal()
              }

              }
            >
              View Details
            </Button >
          ) : " -"
        }
      </>
    ),
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, inspection) => (
      <>
        <div className="d-flex gap-3">
          {
            permitList?.find((task) => task === "Edit") == "Edit" && (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(inspection)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            )}
          {
            permitList?.find((task) => task === "Delete") == "Delete" && (
              <Link
                to="#"
                className="text-danger"
                onClick={() => onClickDelete(inspection)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            )}
        </div>
      </>
    ),
  },
  ];


  //add inspection
  const handleCustomerClicks = () => {
    setInspectionToEdit("")
    setInspectionList("");
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];


  return (
    <React.Fragment>
      <EcommerceInspectionListModal isOpen={modal1} toggle={toggleViewModal} inspectionId={inspectionId} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      {
        loading ?
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div> :
          <div className="page-content">
            {/* <MetaTags>
              <title>Orders | Skote - React Admin & Dashboard Template</title>
            </MetaTags> */}
            <Container fluid>
              {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
              <h2>Inpection Center List</h2>
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={EcommerceOrderColumns}
                        data={orders}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={orders}
                            columns={EcommerceOrderColumns}
                            bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  {
                                    permitList?.find((task) => task == "Create") == "Create" && (
                                      <Col sm="8">
                                        <div className="text-sm-end">
                                          <Button
                                            type="button"
                                            color="success"
                                            className="btn-rounded  mb-2 me-2"
                                            onClick={handleCustomerClicks}
                                          >
                                            <i className="mdi mdi-plus me-1" />
                                            Add New Inspection Center
                                          </Button>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap table-check"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      // ref={node}
                                      />
                                    </div>
                                    <Modal isOpen={modal} toggle={toggle}>
                                      <ModalHeader toggle={toggle} tag="h4">
                                        {!!isEdit ? "Edit Inspection" : "Add Inspection"}
                                      </ModalHeader>
                                      <ModalBody>
                                        <Form
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                          }}
                                        >
                                          <Row form>
                                            <Col className="col-12">
                                              <div className="mb-3">
                                                <Label className="form-label">Name</Label>
                                                <Input
                                                  name="name"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.name || ""}
                                                  invalid={
                                                    validation.touched.name && validation.errors.name ? true : false
                                                  }
                                                />
                                                {validation.touched.name && validation.errors.name ? (
                                                  <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Email</Label>
                                                <Input
                                                  name="email"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.email || ""}
                                                  invalid={
                                                    validation.touched.email && validation.errors.email ? true : false
                                                  }
                                                />
                                                {validation.touched.email && validation.errors.email ? (
                                                  <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Telephone</Label>
                                                <Input
                                                  name="telephone"
                                                  type="text"
                                                  // value={orderList.orderdate || ""}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.telephone || ""}
                                                  invalid={
                                                    validation.touched.telephone && validation.errors.telephone ? true : false
                                                  }
                                                />
                                                {validation.touched.telephone && validation.errors.telephone ? (
                                                  <FormFeedback type="invalid">{validation.errors.telephone}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Dealertype</Label>
                                                <Input
                                                  name="dealertype"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.dealertype || ""}
                                                  invalid={
                                                    validation.touched.dealertype && validation.errors.dealertype ? true : false
                                                  }
                                                />
                                                {validation.touched.dealertype && validation.errors.dealertype ? (
                                                  <FormFeedback type="invalid">{validation.errors.dealertype}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Address</Label>
                                                <Input
                                                  name="address"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.address || ""}
                                                  invalid={
                                                    validation.touched.address && validation.errors.address ? true : false
                                                  }
                                                />
                                                {validation.touched.address && validation.errors.address ? (
                                                  <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                                ) : null}
                                              </div>

                                              <div className="mb-3">
                                                <Label className="form-label">State</Label>
                                                <Input
                                                  name="state"
                                                  type="select"
                                                  className="form-select"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.state || ""
                                                  }
                                                  invalid={
                                                    validation.touched.state && validation.errors.state ? true : false
                                                  }
                                                >
                                                  <option value="" disabled>Select State</option>
                                                  {
                                                    states?.map((statesItem, index) => (

                                                      <option key={index} value={statesItem.id}>{statesItem?.name}</option>
                                                    ))

                                                  }
                                                </Input>
                                                {validation.touched.state && validation.errors.state ? (
                                                  <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Dealer Code</Label>
                                                <Input
                                                  name="dealcode"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.dealcode || ""}
                                                  invalid={
                                                    validation.touched.dealcode && validation.errors.dealcode ? true : false
                                                  }
                                                />
                                                {validation.touched.dealcode && validation.errors.dealcode ? (
                                                  <FormFeedback type="invalid">{validation.errors.dealcode}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Dealer Number</Label>
                                                <Input
                                                  name="dealernumber"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.dealernumber || ""}
                                                  invalid={
                                                    validation.touched.dealernumber && validation.errors.dealernumber ? true : false
                                                  }
                                                />
                                                {validation.touched.dealernumber && validation.errors.dealernumber ? (
                                                  <FormFeedback type="invalid">{validation.errors.dealernumber}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Channel</Label>
                                                <Input
                                                  name="channel"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.channel || ""}
                                                  invalid={
                                                    validation.touched.channel && validation.errors.channel ? true : false
                                                  }
                                                />
                                                {validation.touched.channel && validation.errors.channel ? (
                                                  <FormFeedback type="invalid">{validation.errors.channel}</FormFeedback>
                                                ) : null}
                                              </div>

                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <div className="text-end">
                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Form>
                                      </ModalBody>
                                    </Modal>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
      }
    </React.Fragment>
  );
};


export default Inspectioncenter;
