import React, { useEffect, useState, useRef } from "react";

import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import * as moment from "moment";
import * as Yup from "yup";
import MetaTags from 'react-meta-tags';

import { CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";



import { useSelector, useDispatch } from "react-redux"


import { FETCHALLDEALERS } from "../../../queries/userQueries"; 
import { useQuery } from "@apollo/react-hooks"
import DeleteModal from "../../../components/Common/DeleteModal";
import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "store/e-commerce/actions";

//redux


const EcommerceInstructors = props => {
  const { loading, data } = useQuery(FETCHALLDEALERS)
  // console.log("data", data?.getAllUsers?.allUserRes[0])

  const dispatch = useDispatch()
  // console.logorder
  // const { orders} = useSelector(state => ({
  //   orders: state.ecommerce.orders,
  //   // orders:data.getAllUsers.allUserRes

  // }))

  const orders = data?.getAllDealer?.allUserRes
  console.log(orders)






  return (
    <React.Fragment>
      <div className="page-content">
       
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Dealers List" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                 

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">Id</Th>
                            <Th data-priority="2">Full Name</Th>
                            <Th data-priority="3">Mobile</Th>
                            <Th data-priority="4">Email</Th>
                            <Th data-priority="4">NRIC</Th>
                            <Th data-priority="5">State</Th>
                          
                          </Tr>
                        </Thead>
                        <Tbody>
                          {
                            orders?.map((p, ind) =>
                              <Tr key={ind}>
                                <Td >{p.id}</Td>
                                <Td >{p.name}</Td>
                                <Td >{p.mobile}</Td>
                                <Td >{p.email}</Td>
                                <Td >{p.nric}</Td>
                                <Td >{p.state}</Td>
                              </Tr>
                            )
                          }
                          {/* <Tr>
                            <Th>
                              GOOG <span className="co-name">Google Inc.</span>
                            </Th>
                            <Td>597.74</Td>
                            <Td>12:12PM</Td>
                            <Td>14.81 (2.54%)</Td>
                            <Td>582.93</Td>
                            <Td>597.95</Td>
                            <Td>597.73 x 100</Td>
                            <Td>597.91 x 300</Td>
                            <Td>731.10</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              AAPL <span className="co-name">Apple Inc.</span>
                            </Th>
                            <Td>378.94</Td>
                            <Td>12:22PM</Td>
                            <Td>5.74 (1.54%)</Td>
                            <Td>373.20</Td>
                            <Td>381.02</Td>
                            <Td>378.92 x 300</Td>
                            <Td>378.99 x 100</Td>
                            <Td>505.94</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              AMZN{" "}
                              <span className="co-name">Amazon.com Inc.</span>
                            </Th>
                            <Td>191.55</Td>
                            <Td>12:23PM</Td>
                            <Td>3.16 (1.68%)</Td>
                            <Td>188.39</Td>
                            <Td>194.99</Td>
                            <Td>191.52 x 300</Td>
                            <Td>191.58 x 100</Td>
                            <Td>240.32</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              ORCL{" "}
                              <span className="co-name">
                                Oracle Corporation
                              </span>
                            </Th>
                            <Td>31.15</Td>
                            <Td>12:44PM</Td>
                            <Td>1.41 (4.72%)</Td>
                            <Td>29.74</Td>
                            <Td>30.67</Td>
                            <Td>31.14 x 6500</Td>
                            <Td>31.15 x 3200</Td>
                            <Td>36.11</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              MSFT{" "}
                              <span className="co-name">
                                Microsoft Corporation
                              </span>
                            </Th>
                            <Td>25.50</Td>
                            <Td>12:27PM</Td>
                            <Td>0.66 (2.67%)</Td>
                            <Td>24.84</Td>
                            <Td>25.37</Td>
                            <Td>25.50 x 71100</Td>
                            <Td>25.51 x 17800</Td>
                            <Td>31.50</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              CSCO{" "}
                              <span className="co-name">
                                Cisco Systems, Inc.
                              </span>
                            </Th>
                            <Td>18.65</Td>
                            <Td>12:45PM</Td>
                            <Td>0.97 (5.49%)</Td>
                            <Td>17.68</Td>
                            <Td>18.23</Td>
                            <Td>18.65 x 10300</Td>
                            <Td>18.66 x 24000</Td>
                            <Td>21.12</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              YHOO <span className="co-name">Yahoo! Inc.</span>
                            </Th>
                            <Td>15.81</Td>
                            <Td>12:25PM</Td>
                            <Td>0.11 (0.67%)</Td>
                            <Td>15.70</Td>
                            <Td>15.94</Td>
                            <Td>15.79 x 6100</Td>
                            <Td>15.80 x 17000</Td>
                            <Td>18.16</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              GOOG <span className="co-name">Google Inc.</span>
                            </Th>
                            <Td>597.74</Td>
                            <Td>12:12PM</Td>
                            <Td>14.81 (2.54%)</Td>
                            <Td>582.93</Td>
                            <Td>597.95</Td>
                            <Td>597.73 x 100</Td>
                            <Td>597.91 x 300</Td>
                            <Td>731.10</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              AAPL <span className="co-name">Apple Inc.</span>
                            </Th>
                            <Td>378.94</Td>
                            <Td>12:22PM</Td>
                            <Td>5.74 (1.54%)</Td>
                            <Td>373.20</Td>
                            <Td>381.02</Td>
                            <Td>378.92 x 300</Td>
                            <Td>378.99 x 100</Td>
                            <Td>505.94</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              AMZN{" "}
                              <span className="co-name">Amazon.com Inc.</span>
                            </Th>
                            <Td>191.55</Td>
                            <Td>12:23PM</Td>
                            <Td>3.16 (1.68%)</Td>
                            <Td>188.39</Td>
                            <Td>194.99</Td>
                            <Td>191.52 x 300</Td>
                            <Td>191.58 x 100</Td>
                            <Td>240.32</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              ORCL{" "}
                              <span className="co-name">
                                Oracle Corporation
                              </span>
                            </Th>
                            <Td>31.15</Td>
                            <Td>12:44PM</Td>
                            <Td>1.41 (4.72%)</Td>
                            <Td>29.74</Td>
                            <Td>30.67</Td>
                            <Td>31.14 x 6500</Td>
                            <Td>31.15 x 3200</Td>
                            <Td>36.11</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              MSFT{" "}
                              <span className="co-name">
                                Microsoft Corporation
                              </span>
                            </Th>
                            <Td>25.50</Td>
                            <Td>12:27PM</Td>
                            <Td>0.66 (2.67%)</Td>
                            <Td>24.84</Td>
                            <Td>25.37</Td>
                            <Td>25.50 x 71100</Td>
                            <Td>25.51 x 17800</Td>
                            <Td>31.50</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              CSCO{" "}
                              <span className="co-name">
                                Cisco Systems, Inc.
                              </span>
                            </Th>
                            <Td>18.65</Td>
                            <Td>12:45PM</Td>
                            <Td>0.97 (5.49%)</Td>
                            <Td>17.68</Td>
                            <Td>18.23</Td>
                            <Td>18.65 x 10300</Td>
                            <Td>18.66 x 24000</Td>
                            <Td>21.12</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              YHOO <span className="co-name">Yahoo! Inc.</span>
                            </Th>
                            <Td>15.81</Td>
                            <Td>12:25PM</Td>
                            <Td>0.11 (0.67%)</Td>
                            <Td>15.70</Td>
                            <Td>15.94</Td>
                            <Td>15.79 x 6100</Td>
                            <Td>15.80 x 17000</Td>
                            <Td>18.16</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              GOOG <span className="co-name">Google Inc.</span>
                            </Th>
                            <Td>597.74</Td>
                            <Td>12:12PM</Td>
                            <Td>14.81 (2.54%)</Td>
                            <Td>582.93</Td>
                            <Td>597.95</Td>
                            <Td>597.73 x 100</Td>
                            <Td>597.91 x 300</Td>
                            <Td>731.10</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              AAPL <span className="co-name">Apple Inc.</span>
                            </Th>
                            <Td>378.94</Td>
                            <Td>12:22PM</Td>
                            <Td>5.74 (1.54%)</Td>
                            <Td>373.20</Td>
                            <Td>381.02</Td>
                            <Td>378.92 x 300</Td>
                            <Td>378.99 x 100</Td>
                            <Td>505.94</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              AMZN{" "}
                              <span className="co-name">Amazon.com Inc.</span>
                            </Th>
                            <Td>191.55</Td>
                            <Td>12:23PM</Td>
                            <Td>3.16 (1.68%)</Td>
                            <Td>188.39</Td>
                            <Td>194.99</Td>
                            <Td>191.52 x 300</Td>
                            <Td>191.58 x 100</Td>
                            <Td>240.32</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              ORCL{" "}
                              <span className="co-name">
                                Oracle Corporation
                              </span>
                            </Th>
                            <Td>31.15</Td>
                            <Td>12:44PM</Td>
                            <Td>1.41 (4.72%)</Td>
                            <Td>29.74</Td>
                            <Td>30.67</Td>
                            <Td>31.14 x 6500</Td>
                            <Td>31.15 x 3200</Td>
                            <Td>36.11</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              MSFT{" "}
                              <span className="co-name">
                                Microsoft Corporation
                              </span>
                            </Th>
                            <Td>25.50</Td>
                            <Td>12:27PM</Td>
                            <Td>0.66 (2.67%)</Td>
                            <Td>24.84</Td>
                            <Td>25.37</Td>
                            <Td>25.50 x 71100</Td>
                            <Td>25.51 x 17800</Td>
                            <Td>31.50</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              CSCO{" "}
                              <span className="co-name">
                                Cisco Systems, Inc.
                              </span>
                            </Th>
                            <Td>18.65</Td>
                            <Td>12:45PM</Td>
                            <Td>0.97 (5.49%)</Td>
                            <Td>17.68</Td>
                            <Td>18.23</Td>
                            <Td>18.65 x 10300</Td>
                            <Td>18.66 x 24000</Td>
                            <Td>21.12</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              YHOO <span className="co-name">Yahoo! Inc.</span>
                            </Th>
                            <Td>15.81</Td>
                            <Td>12:25PM</Td>
                            <Td>0.11 (0.67%)</Td>
                            <Td>15.70</Td>
                            <Td>15.94</Td>
                            <Td>15.79 x 6100</Td>
                            <Td>15.80 x 17000</Td>
                            <Td>18.16</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              GOOG <span className="co-name">Google Inc.</span>
                            </Th>
                            <Td>597.74</Td>
                            <Td>12:12PM</Td>
                            <Td>14.81 (2.54%)</Td>
                            <Td>582.93</Td>
                            <Td>597.95</Td>
                            <Td>597.73 x 100</Td>
                            <Td>597.91 x 300</Td>
                            <Td>731.10</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              AAPL <span className="co-name">Apple Inc.</span>
                            </Th>
                            <Td>378.94</Td>
                            <Td>12:22PM</Td>
                            <Td>5.74 (1.54%)</Td>
                            <Td>373.20</Td>
                            <Td>381.02</Td>
                            <Td>378.92 x 300</Td>
                            <Td>378.99 x 100</Td>
                            <Td>505.94</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              AMZN{" "}
                              <span className="co-name">Amazon.com Inc.</span>
                            </Th>
                            <Td>191.55</Td>
                            <Td>12:23PM</Td>
                            <Td>3.16 (1.68%)</Td>
                            <Td>188.39</Td>
                            <Td>194.99</Td>
                            <Td>191.52 x 300</Td>
                            <Td>191.58 x 100</Td>
                            <Td>240.32</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              ORCL{" "}
                              <span className="co-name">
                                Oracle Corporation
                              </span>
                            </Th>
                            <Td>31.15</Td>
                            <Td>12:44PM</Td>
                            <Td>1.41 (4.72%)</Td>
                            <Td>29.74</Td>
                            <Td>30.67</Td>
                            <Td>31.14 x 6500</Td>
                            <Td>31.15 x 3200</Td>
                            <Td>36.11</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              MSFT{" "}
                              <span className="co-name">
                                Microsoft Corporation
                              </span>
                            </Th>
                            <Td>25.50</Td>
                            <Td>12:27PM</Td>
                            <Td>0.66 (2.67%)</Td>
                            <Td>24.84</Td>
                            <Td>25.37</Td>
                            <Td>25.50 x 71100</Td>
                            <Td>25.51 x 17800</Td>
                            <Td>31.50</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              CSCO{" "}
                              <span className="co-name">
                                Cisco Systems, Inc.
                              </span>
                            </Th>
                            <Td>18.65</Td>
                            <Td>12:45PM</Td>
                            <Td>0.97 (5.49%)</Td>
                            <Td>17.68</Td>
                            <Td>18.23</Td>
                            <Td>18.65 x 10300</Td>
                            <Td>18.66 x 24000</Td>
                            <Td>21.12</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              YHOO <span className="co-name">Yahoo! Inc.</span>
                            </Th>
                            <Td>15.81</Td>
                            <Td>12:25PM</Td>
                            <Td>0.11 (0.67%)</Td>
                            <Td>15.70</Td>
                            <Td>15.94</Td>
                            <Td>15.79 x 6100</Td>
                            <Td>15.80 x 17000</Td>
                            <Td>18.16</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              GOOG <span className="co-name">Google Inc.</span>
                            </Th>
                            <Td>597.74</Td>
                            <Td>12:12PM</Td>
                            <Td>14.81 (2.54%)</Td>
                            <Td>582.93</Td>
                            <Td>597.95</Td>
                            <Td>597.73 x 100</Td>
                            <Td>597.91 x 300</Td>
                            <Td>731.10</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              AAPL <span className="co-name">Apple Inc.</span>
                            </Th>
                            <Td>378.94</Td>
                            <Td>12:22PM</Td>
                            <Td>5.74 (1.54%)</Td>
                            <Td>373.20</Td>
                            <Td>381.02</Td>
                            <Td>378.92 x 300</Td>
                            <Td>378.99 x 100</Td>
                            <Td>505.94</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              AMZN{" "}
                              <span className="co-name">Amazon.com Inc.</span>
                            </Th>
                            <Td>191.55</Td>
                            <Td>12:23PM</Td>
                            <Td>3.16 (1.68%)</Td>
                            <Td>188.39</Td>
                            <Td>194.99</Td>
                            <Td>191.52 x 300</Td>
                            <Td>191.58 x 100</Td>
                            <Td>240.32</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              ORCL{" "}
                              <span className="co-name">
                                Oracle Corporation
                              </span>
                            </Th>
                            <Td>31.15</Td>
                            <Td>12:44PM</Td>
                            <Td>1.41 (4.72%)</Td>
                            <Td>29.74</Td>
                            <Td>30.67</Td>
                            <Td>31.14 x 6500</Td>
                            <Td>31.15 x 3200</Td>
                            <Td>36.11</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              MSFT{" "}
                              <span className="co-name">
                                Microsoft Corporation
                              </span>
                            </Th>
                            <Td>25.50</Td>
                            <Td>12:27PM</Td>
                            <Td>0.66 (2.67%)</Td>
                            <Td>24.84</Td>
                            <Td>25.37</Td>
                            <Td>25.50 x 71100</Td>
                            <Td>25.51 x 17800</Td>
                            <Td>31.50</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              CSCO{" "}
                              <span className="co-name">
                                Cisco Systems, Inc.
                              </span>
                            </Th>
                            <Td>18.65</Td>
                            <Td>12:45PM</Td>
                            <Td>0.97 (5.49%)</Td>
                            <Td>17.68</Td>
                            <Td>18.23</Td>
                            <Td>18.65 x 10300</Td>
                            <Td>18.66 x 24000</Td>
                            <Td>21.12</Td>
                          </Tr>
                          <Tr>
                            <Th>
                              YHOO <span className="co-name">Yahoo! Inc.</span>
                            </Th>
                            <Td>15.81</Td>
                            <Td>12:25PM</Td>
                            <Td>0.11 (0.67%)</Td>
                            <Td>15.70</Td>
                            <Td>15.94</Td>
                            <Td>15.79 x 6100</Td>
                            <Td>15.80 x 17000</Td>
                            <Td>18.16</Td>
                          </Tr> */}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Customers | EAutoGen - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Customers" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={EcommerceCustomerColumns}
                    data={customers}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={customers || []}
                        columns={EcommerceCustomerColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleCustomerClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    New customer
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    keyField="id"
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit
                                      ? "Edit Customer"
                                      : "Add Customer"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                      }}
                                    >
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">UserName</Label>
                                            <Input
                                              name="username"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.username || ""}
                                              invalid={
                                                validation.touched.username && validation.errors.username ? true : false
                                              }
                                            />
                                            {validation.touched.username && validation.errors.username ? (
                                              <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Label className="form-label">Phone No</Label>
                                            <Input
                                              name="phone"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.phone || ""}
                                              invalid={
                                                validation.touched.phone && validation.errors.phone ? true : false
                                              }
                                            />
                                            {validation.touched.phone && validation.errors.phone ? (
                                              <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Label className="form-label">Email Id</Label>
                                            <Input
                                              name="email"
                                              type="email"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.email || ""}
                                              invalid={
                                                validation.touched.email && validation.errors.email ? true : false
                                              }
                                            />
                                            {validation.touched.email && validation.errors.email ? (
                                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Label className="form-label">Address</Label>
                                            <Input
                                              name="address"
                                              type="textarea"
                                              rows="3"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.address || ""}
                                              invalid={
                                                validation.touched.address && validation.errors.address ? true : false
                                              }
                                            />
                                            {validation.touched.address && validation.errors.address ? (
                                              <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Label className="form-label">Rating</Label>
                                            <Input
                                              name="rating"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.rating || ""}
                                              invalid={
                                                validation.touched.rating && validation.errors.rating ? true : false
                                              }
                                            />
                                            {validation.touched.rating && validation.errors.rating ? (
                                              <FormFeedback type="invalid">{validation.errors.rating}</FormFeedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Label className="form-label">Wallet Balance</Label>
                                            <Input
                                              name="walletBalance"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.walletBalance || ""}
                                              invalid={
                                                validation.touched.walletBalance && validation.errors.walletBalance ? true : false
                                              }
                                            />
                                            {validation.touched.walletBalance && validation.errors.walletBalance ? (
                                              <FormFeedback type="invalid">{validation.errors.walletBalance}</FormFeedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Label className="form-label">Joining Date</Label>
                                            <Input
                                              name="joiningDate"
                                              type="date"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.joiningDate || ""}
                                              invalid={
                                                validation.touched.joiningDate && validation.errors.joiningDate ? true : false
                                              }
                                            />
                                            {validation.touched.joiningDate && validation.errors.joiningDate ? (
                                              <FormFeedback type="invalid">{validation.errors.joiningDate}</FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-customer"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div> */}


    </React.Fragment>
  );
};

// EcommerceCustomers.propTypes = {
//   customers: PropTypes.array,
//   onGetCustomers: PropTypes.func,
//   onAddNewCustomer: PropTypes.func,
//   onDeleteCustomer: PropTypes.func,
//   onUpdateCustomer: PropTypes.func,
// };

export default EcommerceInstructors;
