import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'
import { Card, Container, CardBody, Col, Row } from 'reactstrap'
import './CreateOrder.css'
import DeleteModal from "../../../components/Common/DeleteModal"
import Select from 'react-select'
import { GETALLSEARCHDEALER, GETALLSTATES, GETALLBRANDS, GETALLMODELBYBRANDID, GETPLANSBYCRITERIA } from "../../../queries/userQueries"
import { CREATBOOKINGBYADMIN } from "../../../mutations/useMutations"
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks"
import { useForm } from "../../../utils/hooks";
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import { useRef } from 'react';
import DatePicker from 'react-flatpickr';

export default function CreateOrder(props) {
    const history = useHistory();
    const planRef = useRef();
    const [deleteModal, setDeleteModal] = useState(false);
    const [dealer, setDealer] = useState([]);
    const [states, setStates] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({});
    const [brandvalue, setBrandvalue] = useState("");
    const [modelvalue, setModelvalue] = useState("");
    const [yearvalue, setYearvalue] = useState("");
    const [mileagevalue, setMileagevalue] = useState("");
    const [Plans, setPlans] = useState([]);
    // const [yeaVoucherValuervalue, setVouchervalue] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [dealerId, setDealerId] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [loadPlans, { loading:loading1, data:data1 }] = useLazyQuery(GETPLANSBYCRITERIA);

    // Add Booking
    const [addBookingByAdmin] = useMutation(CREATBOOKINGBYADMIN, {
        update(_, { data: { createBookingByAdmin: bookingData } }) {

            console.log("bookingData", bookingData)
            if (bookingData?.id) {
                
                setIsLoading(false)
                history.push("/orders");
            }


        },
        onError(err) {
            console.log(err.message);
        },
    });

    localStorage.setItem("brandId", brandvalue);
    localStorage.setItem("modelId", modelvalue);
    localStorage.setItem("owner_year", yearvalue);

    const { onChange, onChangeMulti, onSubmit, values } = useForm(handleSubmit, {
        email: "",
        mobile: "",
        userName: "",
        nric: "",
        stateId: "",
        year: "",
        mileage: "",
        userId: "",
        planId:"",
        planName:"",
    });


    
    useEffect(() => {
      if(brandvalue!=='' && yearvalue!=='' && mileagevalue!==''){
        loadPlans({ variables: { brandId:brandvalue, year:yearvalue, odometer:mileagevalue } })
      }
    }, [brandvalue, yearvalue, mileagevalue]);

    useEffect(() => {
      if(data1){
        const allPlans = data1?.getQuotePlanByCriteria?.allQuotePlanRes?.map((p)=>{
          return {value:p?.id, label:`${p?.EWPCategory} (${p?.DealersRate})`};
        });
        setPlans([...[{label:"select", value:""}], ...allPlans]);
        setSelectedPlan({label:"select", value:""});
        onChangeMulti("planId", "");
      }
    }, [data1])
    
    const brandId = localStorage.getItem("brandId");
    const modelId = localStorage.getItem("modelId");

    function handleAddUser() {
        setIsLoading(true);
        addBookingByAdmin(
            {
                variables: {
                    userName: values.userName,
                    nric: values.nric,
                    stateId: values.stateId,
                    year: yearvalue,
                    email: values.email,
                    mobile: values.mobile,
                    mileage: values.mileage,
                    userId: dealerId,
                    brandId: brandId,
                    modelId: modelId,
                    isVoucher: isChecked,
                    planId: selectedPlan?.value,
                    planName: selectedPlan?.label
                }

            }
        )
    }

    const onChangeDealer = (value) => {
        setDealerId(value.value)
    }

    const handleOnChange = () => {

        setIsChecked(!isChecked);

    };


    function handleSubmit() {
        //  console.log("hello")


    }


    const { data: dataBrand } = useQuery(GETALLBRANDS);
    const brand = dataBrand?.getAllBrands?.allVehicleRes;


    // console.log("brandId", brandId);
    const { data: dataModel } = useQuery(GETALLMODELBYBRANDID, { variables: { brandId } });
    const model = dataModel?.getBrandById?.allVehicleModelRes;

    useEffect(() => {
        if (data) {
            const userRes = data?.searchDealer?.searchResults
            setDealer(userRes)
        }
        if (dataState) {
            const stateRes = dataState?.getAllState?.allStateRes
            setStates(stateRes)
        }

    })

    const { loading, data } = useQuery(GETALLSEARCHDEALER)
    const { loading: loadingState, data: dataState } = useQuery(GETALLSTATES)


    if (loading) {
        return "loading..."
    }

    if (loadingState) {
        return "loading..."
    }

    const handleDeleteOrder = () => {
        setDeleteModal(true)
    };
    const onPlanChange = (e)=>{
      setSelectedPlan(e);
      onChangeMulti("planName", e?.label);
      setTimeout(() => {
        onChangeMulti("planId", e?.value);
      }, 300);
    }
    const handleChangeStartDate = (e)=>{
      var d = new Date(e);
      setYearvalue(d);
    }


    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    
    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <h2>Create Order</h2>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={4}>
                                    <form>
                                        <div className="form-group">
                                            <label >Name</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Name" name="userName" onChange={onChange} />
                                        </div>
                                    </form>
                                </Col>
                                <Col md={4}>
                                    <form>
                                        <div className="form-group">
                                            <label >Email</label>
                                            <input type="Email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" name="email" onChange={onChange} />
                                        </div>
                                    </form>
                                </Col>
                                <Col md={4}>
                                    <form>
                                        <div className="form-group">
                                            <label >Mobile</label>
                                            <input type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Mobile" name="mobile" onChange={onChange} />
                                        </div>
                                    </form>
                                </Col>
                                <Col md={4}>
                                    <form>
                                        <div className="form-group">
                                            <label >NRIC</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="NRIC" name="nric" onChange={onChange} />
                                        </div>
                                    </form>
                                </Col>
                                <Col md={4}>
                                    <div className="form-group">
                                        <label>State</label>
                                        <select className="form-control" name="stateId" onChange={onChange}>
                                            <option value="">Select State</option>
                                            {
                                                states?.map((statesItem, index) => (

                                                    <option key={index} value={statesItem.id}>{statesItem?.name}</option>
                                                ))

                                            }
                                        </select>

                                    </div>
                                </Col>
                                {/* <Col md={4}>
                                    <div className="form-group">
                                        <label>Role</label>
                                        <select className="form-control" id="exampleFormControlSelect1" onChange={onChange} name="role">
                                            <option value="">Select Role</option>
                                            <option value="user">User</option>
                                            <option value="dealer">Dealer</option>
                                            <option value="admin">Admin</option>
                                        </select>

                                    </div>
                                </Col> */}
                                <Col md={4}>
                                    <div className="form-group">
                                        <label>Dealer</label>
                                        <Select options={dealer} name="userId" onChange={value => onChangeDealer(value)} />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <form>
                                        <div className="form-group">
                                            <label >Brand</label>
                                            <select className="form-control" name="brandId" onChange={(e) => {
                                                setBrandvalue(e.target.value);
                                            }}>
                                                <option value="">Select Brand</option>
                                                {brand?.map((option, index) => (
                                                    <option key={index} value={option.id}>
                                                        {option.brand}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </form>
                                </Col>
                                <Col md={4}>
                                    <form>
                                        <div className="form-group">
                                            <label >Model</label>
                                            <select className="form-control" name="modelId" onChange={(e) => {
                                                setModelvalue(e.target.value);
                                            }}>
                                                <option value="">Select Model</option>
                                                {model?.map((option, index) => (
                                                    <option key={index} value={option.id}>
                                                        {option.modelName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </form>
                                </Col>
                                {/* <Col md={4}>
                                    <form>
                                        <div className="form-group">
                                            <label >Year</label>
                                            <select className="form-control" name="year" onChange={(e) => {
                                                setYearvalue(e.target.value);
                                            }}>
                                              <option value="">Select Year</option>
                                              {[...Array(16).keys()].map(foo => new Date().getFullYear() - foo).map((yr)=>(
                                                <option value={yr} key={yr}>{yr}</option>
                                              ))}
                                            </select>
                                        </div>
                                    </form>
                                </Col> */}
                                <Col md={4}>
                                    <form>
                                        <div className="form-group">
                                            <label >Date</label>
                                            <DatePicker
                                                // selected={startDate}
                                                onChange={handleChangeStartDate}
                                                className="form-control"
                                                placeholderText="Select date"
                                            />
                                        </div>
                                    </form>
                                </Col>
                                <Col md={4}>
                                    <form>
                                        <div className="form-group">
                                            <label >Mileage</label>
                                            <input type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                                name="mileage"
                                                required="required"
                                                placeholder="0 To 200,000 kilometers."
                                                onChange={(e)=>{onChange(e); setMileagevalue(e.target.value)}}
                                            />
                                        </div>
                                    </form>
                                </Col>
                                <Col md={4}  >

                                    <div className="mt-4 mx-4" style={{ fontSize: "14px", color: "black" }}>
                                        <Form.Check.Input
                                            id="topping"
                                            name="voucher"
                                            type="checkbox"
                                            onChange={handleOnChange}


                                        />
                                        Create Voucher Code
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="form-group">
                                        <label>Plan</label>
                                        <Select
                                        options={Plans}
                                        name="planId"
                                        ref={planRef}
                                        value={selectedPlan}
                                        onChange={onPlanChange} />
                                    </div>
                                </Col>
                                <div className='text-end'>
                                 { isLoading ? <button className="btn create-btn justify-content-center" onClick={handleAddUser} style={{ width:"111px", height:"40px"}}>                                 
                                                 <Spinner animation="border" variant="light" style={{ width: "25px", height: "25px", marginLeft: "10px" }} />
                                                </button>
                                :   <button className="btn create-btn" onClick={handleAddUser}>
                                        Create Order
                                    </button>
                                }
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    )
}

CreateOrder.propTypes = {}

// export default CreateOrder;