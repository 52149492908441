import React, { useEffect, useState, useRef } from "react"
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as moment from "moment"
import * as Yup from "yup"
import MetaTags from "react-meta-tags"

import { CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form,
  UncontrolledTooltip,
} from "reactstrap"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

import { useSelector, useDispatch } from "react-redux"

import { GETALLWORKSHOPS, GETALLSTATES } from "../../../queries/userQueries"
import { DELETEWORKSHOP, UPDATEWORKSHOP, CREATEWORKSHOP } from "../../../mutations/useMutations"
import { useQuery, useMutation } from "@apollo/react-hooks"
import DeleteModal from "../../../components/Common/DeleteModal"
import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "store/e-commerce/actions"

import EcommerceWorkshopListModal from "./EcommerceWorkshopListModal";

const Workshop = props => {
  const myArray = localStorage.getItem("permission");
  const permitList = myArray?.split(",")

  const [modal, setModal] = useState(false);
  const [workshop, setWorkshop] = React.useState([]);
  const [modal1, setModal1] = useState(false);
  const [workshopId, setWorkshopId] = useState("");
  const [worskhopToDelete, setWorkshopToDelete] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [workshopToEdit, setWorkshopToEdit] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [states, setStates] = React.useState([]);

  const [workshopList, setWorkshopList] = useState([]);

  // Edit Workshop
  const [editWorkshop] = useMutation(UPDATEWORKSHOP, {
    update(_, { data: { updateWorkshop: workshopData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);

    },
    onError(err) {
      console.log(err.message);
    },
  });

  // Add Dealer
  const [addWorkshop] = useMutation(CREATEWORKSHOP, {
    update(_, { data: { createWorkshop: workshopData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);

    },
    onError(err) {
      console.log(err.message);
    },
  });


  function handleUpdateWorkshop(updateWorkshop) {

    editWorkshop(
      {
        variables: {

          id: updateWorkshop?.id,
          stateId: updateWorkshop?.stateId,
          name: updateWorkshop?.name,
          address: updateWorkshop?.address,
          telephone: updateWorkshop?.telephone,
          email: updateWorkshop?.email,
          dealerType: updateWorkshop?.dealertype,
          dealcode: updateWorkshop?.dealcode,
          dealerNumber: updateWorkshop?.dealerNumber,
          stateCode: updateWorkshop?.stateCode,
          mobile: updateWorkshop?.mobile,
          fax: updateWorkshop?.fax,
          area: updateWorkshop?.area,
          personInCharge: updateWorkshop?.personInCharge,
          category: updateWorkshop?.category,
          workshopType: updateWorkshop?.workshopType,
          state: updateWorkshop?.state,
          distribution: updateWorkshop?.distribution,

        }

      }
    )
  }

  function handleAddDealer(addWorkshopData) {

    addWorkshop(
      {
        variables: {
          stateId: addWorkshopData?.stateId,
          name: addWorkshopData?.name,
          address: addWorkshopData?.address,
          telephone: addWorkshopData?.telephone,
          email: addWorkshopData?.email,
          dealerType: addWorkshopData?.dealertype,
          dealcode: addWorkshopData?.dealcode,
          dealerNumber: addWorkshopData?.dealerNumber,
          stateCode: addWorkshopData?.stateCode,
          mobile: addWorkshopData?.mobile,
          fax: addWorkshopData?.fax,
          area: addWorkshopData?.area,
          personInCharge: addWorkshopData?.personInCharge,
          category: addWorkshopData?.category,
          workshopType: addWorkshopData?.workshopType,
          state: addWorkshopData?.state,
          distribution: addWorkshopData?.distribution,

        }

      }
    )
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (workshopToEdit && workshopToEdit?.name) || '',
      email: (workshopToEdit && workshopToEdit?.email) || '',
      telephone: (workshopToEdit && workshopToEdit?.telephone) || '',
      dealcode: (workshopToEdit && workshopToEdit?.dealcode) || '',
      state: (workshopToEdit && workshopToEdit?.state) || '',
      dealernumber: (workshopToEdit && workshopToEdit?.dealernumber) || '',
      dealertype: (workshopToEdit && workshopToEdit?.dealertype) || '',
      address: (workshopToEdit && workshopToEdit?.address) || '',
      mobile: (workshopToEdit && workshopToEdit?.mobile) || '',
      statecode: (workshopToEdit && workshopToEdit?.statecode) || '',
      category: (workshopToEdit && workshopToEdit?.category) || '',
      workshoptype: (workshopToEdit && workshopToEdit?.workshoptype) || '',
      fax: (workshopToEdit && workshopToEdit?.fax) || '',
      area: (workshopToEdit && workshopToEdit?.area) || '',
      personincharge: (workshopToEdit && workshopToEdit?.personincharge) || '',
      distribution: (workshopToEdit && workshopToEdit?.distribution) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      email: Yup.string().required("Please Enter Email"),
      telephone: Yup.string().required("Please Enter Telephone Number"),
      state: Yup.string().required("Please Select State"),
    }),
    onSubmit: (values) => {

      if (isEdit) {
        let statesName = states?.filter((stateItem) => {
          if (stateItem.id === values["state"]) {
            return stateItem;
          }
        });

        const updateWorkshop = {
          id: workshopToEdit?.id,
          name: values?.name,
          email: values?.email,
          telephone: values?.telephone,
          dealcode: values?.dealcode,
          state: statesName[0]?.name,
          stateId: values?.state,
          dealerNumber: values?.dealernumber,
          dealertype: values?.dealertype,
          address: values?.address,
          mobile: values?.mobile,
          workshopType: values?.workshoptype,
          stateCode: values?.statecode,
          fax: values?.fax,
          area: values?.area,
          personInCharge: values?.personincharge,
          category: values?.category,
          distribution: values?.distribution,
        };

        // update order
        // dispatch(onUpdateOrder(updateOrder));
        handleUpdateWorkshop(updateWorkshop);
        validation.resetForm();
      }
      else {
        let statesName = states?.filter((stateItem) => {
          if (stateItem.id === values["state"]) {
            return stateItem;
          }
        });

        const newWorkshop = {
          name: values["name"],
          email: values["email"],
          dealcode: values["dealcode"],
          state: statesName[0]?.name,
          dealerNumber: values["dealernumber"],
          dealertype: values["dealertype"],
          mobile: values["mobile"],
          stateCode: values["statecode"],
          stateId: values["state"],
          address: values["address"],
          telephone: values["telephone"],
          fax: values["fax"],
          area: values["area"],
          personInCharge: values["personincharge"],
          category: values["category"],
          workshopType: values["workshoptype"],
          distribution: values["distribution"],
        };
        // save new order

        handleAddDealer(newWorkshop)
        validation.resetForm();
      }
      toggle();
    },
  });


  const [deleteWorkshop] = useMutation(DELETEWORKSHOP, {
    update(_, { data: { deleteWorkshop: workshopData } }) {

      setTimeout(() => {
        window.location.reload();
      }, 0);
    },
    onError(err) {
      console.log(err.message);
    },
  });


  useEffect(() => {
    if (data) {
      const workshopRes = data?.getAllWorkshop?.allWorkshopRes
      setWorkshop(workshopRes)
    }
    if (dataState) {
      const stateRes = dataState?.getAllState?.allStateRes
      setStates(stateRes)
    }
  })

  const { loading, data } = useQuery(GETALLWORKSHOPS)
  const { loading: loadingState, data: dataState } = useQuery(GETALLSTATES)

  if (loading) {
    return "loading..."
  }
  if (loadingState) {
    return "loading..."
  }

  const workshops = workshop
  const selectRow = {
    mode: "checkbox",
  };

  const pageOptions = {
    sizePerPage: 10,
    totalSize: workshops.length, // replace later with size(orders),
    custom: true,
  };
  const { SearchBar } = Search;

  const toggleViewModal = () => setModal1(!modal1)

  const sendWorkshopId = (workshopId) => {
    let workshopToView = workshops?.filter(item => {
      if (item.id === workshopId) {
        return item
      }
    })
    setWorkshopId(workshopToView)
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      // setUser(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {

    const workshopData = arg;

    let statesName = states?.filter((stateItem) => {
      if (stateItem.id === workshopData?.stateId) {
        return stateItem;
      }
    });

    setWorkshopToEdit({
      id: workshopData?.id,
      name: workshopData?.name,
      email: workshopData?.email,
      telephone: workshopData?.telephone,
      dealcode: workshopData?.dealcode,
      state: statesName[0].id,
      dealernumber: workshopData?.dealerNumber,
      dealertype: workshopData?.dealerType,
      address: workshopData?.address,
      statecode: workshopData?.stateCode,
      mobile: workshopData?.mobile,
      fax: workshopData?.fax,
      area: workshopData?.area,
      personincharge: workshopData?.personInCharge,
      category: workshopData?.category,
      workshoptype: workshopData?.workshopType,
      distribution: workshopData?.distribution,

    });

    setIsEdit(true);

    toggle();
  };

  //delete workshop

  const onClickDelete = (workshop) => {

    setWorkshopToDelete(workshop)
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {

    if (worskhopToDelete) {
      deleteWorkshop({
        variables: {
          workshopId: worskhopToDelete.id,
        },
      });
      // onPaginationPageChange(1);
      setDeleteModal(false);
    }
  };

  const EcommerceWorkshopColumns = [{
    dataField: 'id',
    text: 'Workshop Id',
    sort: true,
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true
  }, {
    dataField: 'state',
    text: 'State',
    sort: true
  }, {
    dataField: 'stateCode',
    text: 'State Code',
    sort: true
  }, {
    dataField: 'address',
    text: 'Address',
    sort: true
  }, {
    dataField: 'telephone',
    text: 'Telephone',
    sort: true
  }, {
    dataField: 'mobile',
    text: 'Mobile',
    sort: true

  }, {
    dataField: 'email',
    text: 'Email',
    sort: true
  }, {
    dataField: 'area',
    text: 'Area',
    sort: true
  },
  {
    dataField: 'personInCharge',
    text: 'Person in Charge',
    sort: true
  },
  {
    dataField: 'dealerType',
    text: 'Dealer Type',
    sort: true
  },
  {
    dataField: 'distribution',
    text: 'Distribution',
    sort: true
  },
  {
    dataField: 'dealcode',
    text: 'Dealer Code',
    sort: true
  },
  {
    dataField: 'dealerNumber',
    text: 'Dealer Number',
    sort: true
  },
  {
    dataField: 'category',
    text: 'Category',
    sort: true
  },
  {
    dataField: 'workshopType',
    text: 'Workshop type',
    sort: true
  },
  {
    dataField: "view",
    isDummyField: true,
    text: "View Details",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (
      <>
        {
          permitList?.find((task) => task == "View") == "View" ? (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                sendWorkshopId(row.id);
                toggleViewModal()
              }

              }
            >
              View Details
            </Button >
          ) : " -"
        }
      </>
    ),
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, workshop) => (
      <>
        <div className="d-flex gap-3">
          {
            permitList?.find((task) => task === "Edit") == "Edit" && (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(workshop)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            )}

          {
            permitList?.find((task) => task === "Delete") == "Delete" && (
              <Link
                to="#"
                className="text-danger"
                onClick={() => onClickDelete(workshop)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            )}
        </div>
      </>
    ),
  },
  ];

  //add workshop
  const handleCustomerClicks = () => {
    setWorkshopToEdit("")
    setWorkshopList("");
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <EcommerceWorkshopListModal isOpen={modal1} toggle={toggleViewModal} workshopId={workshopId} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      {
        loading ?
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div> :
          <div className="page-content">

            <Container fluid>
              {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
              <h2>Workshop List</h2>
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={EcommerceWorkshopColumns}
                        data={workshops}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={workshops}
                            columns={EcommerceWorkshopColumns}
                            bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  {
                                    permitList?.find((task) => task == "Create") == "Create" && (
                                      <Col sm="8">
                                        <div className="text-sm-end">
                                          <Button
                                            type="button"
                                            color="success"
                                            className="btn-rounded  mb-2 me-2"
                                            onClick={handleCustomerClicks}
                                          >
                                            <i className="mdi mdi-plus me-1" />
                                            Add New Workshop
                                          </Button>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap table-check"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      // ref={node}
                                      />
                                    </div>
                                    <Modal isOpen={modal} toggle={toggle}>
                                      <ModalHeader toggle={toggle} tag="h4">
                                        {!!isEdit ? "Edit Workshop" : "Add Workshop"}
                                      </ModalHeader>
                                      <ModalBody>
                                        <Form
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                          }}
                                        >
                                          <Row form>
                                            <Col className="col-12">
                                              <div className="mb-3">
                                                <Label className="form-label">Name</Label>
                                                <Input
                                                  name="name"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.name || ""}
                                                  invalid={
                                                    validation.touched.name && validation.errors.name ? true : false
                                                  }
                                                />
                                                {validation.touched.name && validation.errors.name ? (
                                                  <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Email</Label>
                                                <Input
                                                  name="email"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.email || ""}
                                                  invalid={
                                                    validation.touched.email && validation.errors.email ? true : false
                                                  }
                                                />
                                                {validation.touched.email && validation.errors.email ? (
                                                  <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Telephone</Label>
                                                <Input
                                                  name="telephone"
                                                  type="text"
                                                  // value={orderList.orderdate || ""}
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.telephone || ""}
                                                  invalid={
                                                    validation.touched.telephone && validation.errors.telephone ? true : false
                                                  }
                                                />
                                                {validation.touched.telephone && validation.errors.telephone ? (
                                                  <FormFeedback type="invalid">{validation.errors.telephone}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Dealertype</Label>
                                                <Input
                                                  name="dealertype"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.dealertype || ""}
                                                  invalid={
                                                    validation.touched.dealertype && validation.errors.dealertype ? true : false
                                                  }
                                                />
                                                {validation.touched.dealertype && validation.errors.dealertype ? (
                                                  <FormFeedback type="invalid">{validation.errors.dealertype}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Address</Label>
                                                <Input
                                                  name="address"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.address || ""}
                                                  invalid={
                                                    validation.touched.address && validation.errors.address ? true : false
                                                  }
                                                />
                                                {validation.touched.address && validation.errors.address ? (
                                                  <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                                ) : null}
                                              </div>

                                              <div className="mb-3">
                                                <Label className="form-label">State</Label>
                                                <Input
                                                  name="state"
                                                  type="select"
                                                  className="form-select"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.state || ""
                                                  }
                                                  invalid={
                                                    validation.touched.state && validation.errors.state ? true : false
                                                  }
                                                >
                                                  <option value="" disabled>Select State</option>
                                                  {
                                                    states?.map((statesItem, index) => (

                                                      <option key={index} value={statesItem.id}>{statesItem?.name}</option>
                                                    ))

                                                  }
                                                </Input>
                                                {validation.touched.state && validation.errors.state ? (
                                                  <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Dealer Code</Label>
                                                <Input
                                                  name="dealcode"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.dealcode || ""}
                                                  invalid={
                                                    validation.touched.dealcode && validation.errors.dealcode ? true : false
                                                  }
                                                />
                                                {validation.touched.dealcode && validation.errors.dealcode ? (
                                                  <FormFeedback type="invalid">{validation.errors.dealcode}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Dealer Number</Label>
                                                <Input
                                                  name="dealernumber"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.dealernumber || ""}
                                                  invalid={
                                                    validation.touched.dealernumber && validation.errors.dealernumber ? true : false
                                                  }
                                                />
                                                {validation.touched.dealernumber && validation.errors.dealernumber ? (
                                                  <FormFeedback type="invalid">{validation.errors.dealernumber}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Mobile</Label>
                                                <Input
                                                  name="mobile"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.mobile || ""}
                                                  invalid={
                                                    validation.touched.mobile && validation.errors.mobile ? true : false
                                                  }
                                                />
                                                {validation.touched.mobile && validation.errors.mobile ? (
                                                  <FormFeedback type="invalid">{validation.errors.mobile}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Fax</Label>
                                                <Input
                                                  name="fax"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.fax || ""}
                                                  invalid={
                                                    validation.touched.fax && validation.errors.fax ? true : false
                                                  }
                                                />
                                                {validation.touched.fax && validation.errors.fax ? (
                                                  <FormFeedback type="invalid">{validation.errors.fax}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">State Code</Label>
                                                <Input
                                                  name="statecode"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.statecode || ""}
                                                  invalid={
                                                    validation.touched.statecode && validation.errors.statecode ? true : false
                                                  }
                                                />
                                                {validation.touched.statecode && validation.errors.statecode ? (
                                                  <FormFeedback type="invalid">{validation.errors.statecode}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Area</Label>
                                                <Input
                                                  name="area"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.area || ""}
                                                  invalid={
                                                    validation.touched.area && validation.errors.area ? true : false
                                                  }
                                                />
                                                {validation.touched.area && validation.errors.area ? (
                                                  <FormFeedback type="invalid">{validation.errors.area}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Person In Charge</Label>
                                                <Input
                                                  name="personincharge"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.personincharge || ""}
                                                  invalid={
                                                    validation.touched.personincharge && validation.errors.personincharge ? true : false
                                                  }
                                                />
                                                {validation.touched.personincharge && validation.errors.personincharge ? (
                                                  <FormFeedback type="invalid">{validation.errors.personincharge}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Workshop Type</Label>
                                                <Input
                                                  name="workshoptype"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.workshoptype || ""}
                                                  invalid={
                                                    validation.touched.workshoptype && validation.errors.workshoptype ? true : false
                                                  }
                                                />
                                                {validation.touched.workshoptype && validation.errors.workshoptype ? (
                                                  <FormFeedback type="invalid">{validation.errors.workshoptype}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Distribution</Label>
                                                <Input
                                                  name="distribution"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.distribution || ""}
                                                  invalid={
                                                    validation.touched.distribution && validation.errors.distribution ? true : false
                                                  }
                                                />
                                                {validation.touched.distribution && validation.errors.distribution ? (
                                                  <FormFeedback type="invalid">{validation.errors.distribution}</FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">Category</Label>
                                                <Input
                                                  name="category"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.category || ""}
                                                  invalid={
                                                    validation.touched.category && validation.errors.category ? true : false
                                                  }
                                                />
                                                {validation.touched.category && validation.errors.category ? (
                                                  <FormFeedback type="invalid">{validation.errors.category}</FormFeedback>
                                                ) : null}
                                              </div>

                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <div className="text-end">
                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Form>
                                      </ModalBody>
                                    </Modal>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
      }
    </React.Fragment>

  )
}

export default Workshop
