import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

const PlanModal = props => {
  const { isOpen, toggle, orderId } = props

  return (
    <React.Fragment>
      {
        orderId ? (
          <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={toggle}
          >
            <div className="modal-content">
              <ModalHeader toggle={toggle}>Plan Details</ModalHeader>
              <ModalBody>
                <p className="mb-2">
                  Plan Id: <span className="text-primary">{orderId[0].id}</span>
                </p>
                <p className="mb-2">
                  EWP Category: <span className="text-primary">{orderId[0].EWPCategory}</span>
                </p>
                <p className="mb-2">
                  Covered Components: <span className="text-primary">{orderId[0].CoveredComponents}</span>
                </p>
                <p className="mb-2">
                  No of Covered Components: <span className="text-primary">{orderId[0].NoOfCoveredComponents}</span>
                </p>
                <p className="mb-2">
                  Car Type: <span className="text-primary">{orderId[0].CarType}</span>
                </p>
                <p className="mb-2">
                  Car Age: <span className="text-primary">{orderId[0].CarAge}</span>
                </p>
                <p className="mb-2">
                  Odometer Reading: <span className="text-primary">{orderId[0].OdometerReading}</span>
                </p>
                <p className="mb-2">
                  Car Brand By Country: <span className="text-primary">{orderId[0].CarBrandByCountry}</span>
                </p>
                <p className="mb-2">
                  Max. Per Visit: <span className="text-primary">{orderId[0].MaxPerVisit}</span>
                </p>
                <p className="mb-2">
                  Max. Per Year: <span className="text-primary">{orderId[0].MaxPerYear}</span>
                </p>

                <p className="mb-2">
                  Dealers Rate: <span className="text-primary">{orderId[0].DealersRate}</span>
                </p>
                <p className="mb-2">
                  Retail Rate: <span className="text-primary">{orderId[0].RetailRate}</span>
                </p>

              </ModalBody>
              <ModalFooter>
                <Button type="button" color="secondary" onClick={toggle}>
                  Close
                </Button>
              </ModalFooter>
            </div>
          </Modal >) : (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )

      }
    </React.Fragment>
  )
}

PlanModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  orderId: PropTypes.string,
}

export default PlanModal
